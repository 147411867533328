@charset "utf-8";
@import url('../fonts/font-awesome-4.6.2/css/font-awesome.min.css');

#key_access {
    position: fixed;
    top: 0;
    width: 100%;
    height: 57px;
    text-align: center;
    overflow: hidden;
    z-index: -1;
}

#key_access ul {
    position: relative;
}

#key_access ul li {
    position: absolute;
    width: 100%;
    height: 57px;
    left: 0;
    top: -9999px;
    background: #6d9bc0
}

#key_access ul li.select {
    top: 0;
}

#key_access a {
    display: block;
    width: 100%;
    height: 57px;
    line-height: 57px;
    color: #fff
}

#loading_page {
    position: fixed;
    top: 0px;
    bottom: 0px;
    width: 100%;
    height: 100%;
    background: #fff;
    z-index: 10000;
}

#loading_page p {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -30px 0 0 -30px;
    font-size: 48px;
    z-index: auto;
}

.pushable {
    height: auto;
}


#home_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1 1 auto;
    overflow: hidden;
    min-height: auto;
}

.tablet {
    display: block;
}

.mo_only {
    display: none;
}

.body__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1 1 auto;
    overflow-x: hidden;
}

/* section common */
.section {
    position: relative;
    width: 100%;
}

.section .inner, .section .inner-wrap {
    max-width: 1310px;
    margin: 0 auto;
    z-index: 3;
    padding: 0 1em;
}

.visual .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: -webkit-linear-gradient(-180deg, rgba(0, 0, 0, .5), rgba(200, 200, 200, .1));
    background: linear-gradient(-180deg, rgba(0, 0, 0, .5), rgba(200, 200, 200, .1));

}

.section.visual {
    height: calc(100vh - 95px);
    max-height: 100vh;
}

.section.visual .inner .v_logo {
    position: absolute;
    left: 0;
    bottom: -20%;
    width: 300px;
    height: 500px;
    background: url(../img/v_logo.svg) center no-repeat;
    z-index: 5;
    opacity: 20%;
}

.section.visual .inner {
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    z-index: 10;
    -webkit-transform: translate(-50%, -60%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
}


/* slide_wrap  */
.visual .slide_wrap {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;

}

.visual .slide_wrap img {
    width: 100%;
    height: 100vh;
    object-fit: cover;
}

.visual .slide_wrap .slick-dots {
    position: absolute;
    bottom: 25px !important;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
}

.visual .cont_box {
    display: grid;
    width: 100%;
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: 64% 35%;
    grid-template-areas:
        "visual login"
        "visual notice";
    gap: 0.8em;
}

.cont_box .visual_txt {
    grid-area: visual;
}

.cont_box .login_form {
    grid-area: login;
}

.cont_box .notice {
    grid-area: notice;
}

.visual .cont_box .cont_item {
    padding: 1.333em;
    overflow: hidden;
}

.visual .cont_box .visual_txt {
    padding: 0;
}

.cont_box .visual_txt {
    color: #fff;
}

.cont_box .visual_txt .en_txt {
    font-size: 3.2em;
    line-height: 1.3;
    font-family: 'Montserrat';
    font-weight: 200;
    text-shadow: 2px 7px 5px rgba(0, 0, 0, 0.2), 0px -4px 10px rgba(255, 255, 255, 0.2);
}

.cont_box .visual_txt .en_sub_txt {
    font-size: 1.7em;
    line-height: 1.3;
    font-family: 'Montserrat';
    font-weight: 200;
    text-shadow: 2px 7px 5px rgba(0, 0, 0, 0.2),
    0px -4px 10px rgba(255, 255, 255, 0.2);
}

.cont_box .visual_txt .kr_txt {
    font-size: 1.8em;
    font-weight: 500;
    line-height: 3.5;
    text-shadow: 2px 7px 5px rgba(0, 0, 0, 0.2),
        0px -4px 10px rgba(255, 255, 255, 0.2);
}

.cont_box .visual_txt .kr_txt span {
    display: inline-block;
    color: #ecce9e;
}

/* .login_form */
/* 231127수정 */
.login_form.cont_item {
    display: flex;
    padding: 0 !important;

}


.login_form .login_inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
    padding: 1.33em;
    width: 100%;
    /* background-color: rgba(226, 216, 205, 0.85); */
    background-color: rgba(240, 245, 250, .85);
    padding: 1.33em;
    /* margin-top: auto !important; */
}

/* //231127 수정 */

.login_form p {
    margin-bottom: 0.5em;
    font-size: 1.2em;
    color: #333;
    font-weight: 700;
}

.login_form .ui.form {
    width: 100%;
}

.login_form .ui.form .field {
    margin-bottom: 0.5em;
}

.login_form ul.link-box {
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    padding-top: 0.8em;
    padding-bottom: 1em;
}

.login_form ul.link-box li {
    font-size: .937em;
    font-weight: 500;
}

.login_form ul.link-box li a {
    color: rgba(0, 0, 0, 0.8);
}

.login_form ul.link-box li .ui.checkbox {
    vertical-align: inherit;
}

.login_form .lonin_btn {
    width: 100%;
    border-radius: 2px;
    background-color: #23408E;
    color: #fff;
    padding: 0.785em 1em 0.785em;
    text-transform: none;
    text-shadow: none;
    line-height: 1em;
    font-style: normal;
    text-align: center;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;
    font-size: 1em;
    transition: all .2s ease;
    -webkit-transition: all .2s ease;
    -moz-transition: all .2s ease;
    -ms-transition: all .2s ease;
    -o-transition: all .2s ease;
}

.login_form .lonin_btn:hover, .login_form .lonin_btn:focus {
    background-color: #152655;
}

.login_form.log_in .profile {
    display: flex;
    align-items: center;
    margin: 1em 0;
}

.login_form.log_in .profile .initial-img {
    margin-right: 1em;
    object-fit: cover;
}

.login_form.log_in .profile p span {
    display: block;

}

.login_form.log_in .shortcut_btn {
    margin-top: auto;
    width: 100%;
    margin-bottom: 0.5em;
}

.login_form.log_in .shortcut_btn li {
    margin-bottom: .5em;
    background-color: rgba(116, 99, 91, .75);
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
    transition: all 0.2s ease;
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    -ms-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
}

.login_form.log_in .shortcut_btn li:hover {
    background-color: rgba(116, 99, 91, 1);
    box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
}

.login_form.log_in .shortcut_btn li a {
    padding: 0.8em 1.5em;
    width: 100%;
    height: 100%;
    text-align: center;
    color: #fff;
    font-weight: 500;
    font-size: 1.067em;
}

.login_form.log_in .shortcut_btn li a i {
    display: inline-block;
    margin-right: 1em;
}

/* notice */
.notice {
    color: #fff;
    /* background-color: rgba(56, 0, 0, .75); */
    background-color: rgba(35, 64, 142, .85);
}

.notice .notice_tit {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 0.5em;
    border-bottom: 1px solid #fff;
    margin-bottom: 5px;
}

.notice .notice_tit p {
    font-size: 1.2em;
    font-weight: 500;
}

.notice .notice_tit .more {
    display: inline-block;
    width: 20px;
}

.notice .notice_tit .more img {
    width: 100%;
}

.notice ul.notice_list {
    margin-top: 10px;
}

.notice ul.notice_list a {
    display: inline-block;
    text-decoration: none;
    color: #fff;
    transition: all 0.2s ease-in-out;
}

.notice ul.notice_list a:focus, .notice ul.notice_list a:hover {
    outline: 0;
    color: rgba(255, 255, 255, 0.75);
    text-shadow: 0px 0px 5px rgba(0, 3, 51, .1);
}

.notice ul.notice_list li {
    padding: 0.667em 0;
}

.notice ul.notice_list li a {
    display: flex;
    align-items: center;
}

.notice ul.notice_list li a::before {
    content: '';
    width: 3px;
    height: 3px;
    background-color: #fff;
    border-radius: 25%;
    -webkit-border-radius: 25%;
    -moz-border-radius: 25%;
    -ms-border-radius: 25%;
    -o-border-radius: 25%;
    margin-right: 10px;
}

.notice ul.notice_list li p {
    flex: 1;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;

}

.notice ul.notice_list li small {
    display: inline-block;
    width: 90px;
    text-align: right;
}

@media all and (max-width:1200px) {
    body {
        /* height: 100% !important; */
        font-size: 14px;
    }

    #container {
        min-height: 100%;
    }

    /* .section.visual {
        height: 100vh;
    } */

    .cont_box .visual_txt .en_txt {
        font-size: 2.8em;
        line-height: 1.2;
        font-weight: 200;
    }

    .cont_box .visual_txt .en_sub_txt {
        font-size: 1.4em;
        line-height: 1.2;
        font-weight: 200;
    }

    /* .login_form .login_inner {
        margin-top: initial;
    } */
}

@media all and (max-width:1024px) {
    .section.visual .inner {
        width: 100%;
    }

    .section.visual .inner {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        -o-transform: translate(-50%, -50%);
    }


    .visual .cont_box {
        display: grid;
        margin: 0 auto;
        grid-template-rows: minmax(auto, 150px) minmax(auto, 250px);
        grid-template-columns: minmax(auto, 70%);
        grid-template-areas:
            "visual"
            "login"
            "notice";
        gap: 0.8em;
        justify-content: center;
        max-height: none;
    }

    .visual .cont_box .visual_txt {
        text-align: center;
        align-items: center;
    }

    .cont_box .visual_txt .en_txt {
        font-size: 2em;
        line-height: 1.3;
    }

    .cont_box .visual_txt .en_sub_txt {
        font-size: 1.1em;
        line-height: 1.2;
    }

    .cont_box .visual_txt .kr_txt {
        font-size: 1.1em;
        font-weight: 500;
        line-height: 1;
        margin-top: 1.5em;
    }

}

@media all and (max-width: 600px) {
    body {
        font-size: 13px;
    }

    .tablet {
        display: none;
    }

    .mo_only {
        display: block;
    }

    .visual .cont_box {
        display: grid;
        margin: 0 auto;
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: repeat(1, 1fr);
        grid-template-areas:
            "visual"
            "login"
            "notice"
            "icon_btn"
            "service_cnt";
        gap: 0.8em;
        justify-content: center;
    }

    .cont_box .visual_txt .en_txt {
        font-size: 1.5em;
        line-height: 1.3;
    }

    .cont_box .visual_txt .en_sub_txt {
        font-size: 0.8em;
        line-height: 1.2;
    }

    .cont_box .visual_txt .kr_txt {
        font-size: 0.9em;
        font-weight: 500;
        line-height: 1;
        margin-top: 1em;
    }

    .icon_btn ul li a img {
        width: 20px;
    }

    .visual .cont_box .visual_txt {
        margin-bottom: 2em;
    }

    .section.visual .inner .v_logo {
        display: none;
    }
}
