@charset "utf-8";
/* CSS Document */

/* Link Styles */
a.link {
    display: inline-block;
    position: relative;
    z-index: 0;
    -webkit-transition: color 0.2s;
    transition: color 0.2s;
    outline: none;
    color: #4183c4 !important;
    text-decoration: none;
}

a.link:before {
    position: absolute;
    top: auto;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #4183C4;
    content: '';
    -webkit-transition: all 0.2s;
    -webkit-backface-visibility: hidden;
    transition: all 0.2s;
    backface-visibility: hidden;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
}

a.link:hover:before,
a.link:focus:before {
    background-color: #2C76BF;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
}

a.link-dots {
    position: relative;
    display: inline-block;
    color: #333;
    cursor: pointer;
    border-bottom: 1px dotted #333;
}


code {
    background-color: rgba(0, 0, 0, 0.08);
    border-radius: 3px;
    display: inline-block;
    font-size: 0.875em;
    font-weight: 500;
    color: #000;
    padding: 1px 6px;
    vertical-align: baseline;
}
.grid_tit {
    font-size: 1.333em;
    padding: 0.5em 0;
}
.seg_tit {
    font-size: 1.133em;
    font-weight: 500;
    margin-bottom: 0.667em;
}

.ui.icon.header .icon {
    float: none;
    display: block;
    width: auto;
    height: auto;
    line-height: 1;
    padding: 0;
    font-size: 3em;
    margin: 0 auto .5rem;
    opacity: 1;
}

.ui.header:not(h1):not(h2):not(h3):not(h4):not(h5):not(h6) {
    font-size: 1.28571429em;
    color: #333;
}

.ui.icon.header {
    text-align: center;
    margin: 2rem 0 1rem;
}

.cbp_tmtimeline {
    width: 16rem;
    margin-right: 2em;
    padding-top: 1em;
    padding-right: 2em;
    list-style: none;
    position: relative;
    border-right: 1px solid #ebebeb;
    box-shadow: 3px 0 4px -4px #f1f1f1;
}

.cbp_tmtimeline:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 2px;
    height: 100%;
    background: #d7e4ed;
    left: 10px;
}

/* Multi LayerPopup Styles */
.layer-group {
    justify-content: center;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
}

.layer-group .layerPopup {
    position: absolute;
    top: 80px;
    z-index: 99999;
    background-color: #fff;
    width: 800px;
    height: 400px;
    box-shadow: 0px 1px 3px 0px #d4d4d5, 0px 0px 0px 1px #d4d4d5 inset;
    display: none;
    overflow: hidden;
}

.layer-group [id$="header"] {
    padding: 10px;
    z-index: 10;
    background-color: #222;
    color: #fff;
    min-height: 40px;
    justify-content: space-between;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    touch-action: none;
    cursor: move;
}

.layer-group [id^="closeButton"] {
    color: white;
    cursor: pointer;
    position: relative;
    top: 8px;
    font-size: 24px;
    line-height: 0;
}

.layer-group .windowActive {
    z-index: 100000;
}

.layer-group .body {
    padding: 20px;
    padding-bottom: 40px;
}

.layer-group .footer {
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    justify-content: flex-end;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 10px 20px;
    box-shadow: 0px 0px 0px 1px #d4d4d5 inset;
    background: #f9f9f9;
}

.layer-group .footer .ui.checkbox {
    vertical-align: inherit;
    font-size: .938em;
}

/* Modal bootstrap Styles */
.fade {
    opacity: 0;
    -webkit-transition: opacity 0.15s linear;
    -o-transition: opacity 0.15s linear;
    transition: opacity 0.15s linear;
    background-color: rgba(0, 0, 0, 0.4);
}

.fade.in {
    opacity: 1;
}

.modal-page {
    display: block;
    min-height: auto;
    padding: 1px;
    /* font-size: 0.9em; */
}

.modal-page #wrap {
    display: block;
    min-height: auto;
}

.modal-open {
    overflow: hidden;
}

.modal {
    display: none;
    overflow: hidden;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1040;
    -webkit-overflow-scrolling: touch;
    outline: 0;
}

.modal.fade .modal-dialog {
    -webkit-transform: translate(0, -25%);
    -ms-transform: translate(0, -25%);
    -o-transform: translate(0, -25%);
    transform: translate(0, -25%);
    -webkit-transition: -webkit-transform 0.3s ease-out;
    -o-transition: -o-transform 0.3s ease-out;
    transition: transform 0.3s ease-out;
}

.modal.in .modal-dialog {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
}

.modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto;
}

.modal-dialog {
    position: relative;
    width: auto;
    margin: 10px;
}

.modal-content {
    position: relative;
    background-color: #ffffff;
    border: 1px solid #999999;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    -webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
    box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    outline: 0;
}

.bottom-content {
    margin-top: 2em;
    text-align: right;
}

.bottom-content.transition.visible {
    display: none !important;
}

.bottom-content button,
.bottom-content a {
    min-width: 80px;
}

.layer-popup .modal-content {
    box-shadow: none;
    border: 1px solid #333;
    border-radius: 0;
}

.layer-popup .modal-body {
    padding: 0;
}

.layer-popup .modal-footer {
    padding: 0;
}

.layer-popup .modal-footer .ui.segment {
    padding: .5em;
    border-radius: 0;
}

.modal-backdrop {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    background-color: #000000;
}

.modal-backdrop.fade {
    opacity: 0;
    filter: alpha(opacity=0);
}

.modal-backdrop.in {
    opacity: 0.5;
    filter: alpha(opacity=50);
}

.modal-header {
    padding: 15px;
    border-bottom: 1px solid #e5e5e5;
    min-height: 16.42857143px;
}

.modal-header .close {
    float: right;
    font-size: 21px;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    filter: alpha(opacity=20);
    opacity: .2;
    background: none;
}

.modal-title {
    margin: 0;
    line-height: 1.42857143;
    font-size: 1.25em;
    color: #73879C;
}

.modal-body {
    position: relative;
    padding: 15px;
}

.modal-footer {
    padding: 15px;
    text-align: right;
    border-top: 1px solid #e5e5e5;
}

.modal-footer .btn+.btn {
    margin-left: 5px;
    margin-bottom: 0;
}

.modal-footer .btn-group .btn+.btn {
    margin-left: -1px;
}

.modal-footer .btn-block+.btn-block {
    margin-left: 0;
}

.modal-scrollbar-measure {
    position: absolute;
    top: -9999px;
    width: 50px;
    height: 50px;
    overflow: scroll;
}

.modal.in .modal-fullscreen {
    position: fixed;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
}

.modal.in .modal-fullscreen .modal-content {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border: none;
    border-radius: 0;
    box-shadow: none;
}

.modal.in .modal-fullscreen .modal-body {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    overflow: auto;
    overflow-x: hidden;
}

.modal.in .modal-fullscreen.video .modal-header {
    position: fixed;
    left: 0;
    right: 0;
    border: none;
    z-index: 1;
}

.modal.in .modal-fullscreen.video .modal-header .modal-title {
    color: #fff;
}

.modal.in .modal-fullscreen.video .modal-header .close {
    font-size: 2em;
    font-weight: normal;
    color: #fff;
    opacity: 1;
}

.modal.in .modal-fullscreen.video .modal-body {
    padding: 0;
    overflow: hidden;
}

.modal.in .modal-fullscreen.video .modal-body iframe {
    height: 100%;
}

.video-wrapper .plyr__video-wrapper {
    height: 100vh !important;
}

/* option-content .msg_box */
.option-content .msg_box {
    display: flex;
    align-items: center;
}

.option-content .msg_box .ui.message {
    padding: 10px 15px;
    margin: 2px 0;
    margin-right: 5px;
}

/* cbp_tmtimeline */
.cbp_tmtimeline > li {
    position: relative;
}

.cbp_tmtimeline > li:last-child:before {
    display: none;
}

.cbp_tmtimeline > li.on .cbp_box .ui.segment {
    display: block;
}

/* The date/time */
.cbp_tmtimeline > li .cbp_tmtime {
    display: flex;
    align-items: center;
}

.cbp_tmtimeline > li .cbp_tmtime span {
    font-size: 1.125em;
    font-weight: 500;
}

.cbp_tmtimeline > li .cbp_tmtime small {
    flex: 1;
    text-align: right;
    justify-content: flex-end;
    color: #6b758d;
    font-size: .813em;
}

/* Right content */
.cbp_tmtimeline > li .cbp_tmlabel {
    padding: .5em;
    border-radius: 5px;
    cursor: pointer;
}

.cbp_tmtimeline > li.active .cbp_tmlabel {
    background: #d7e4ed;
}

/*.cbp_tmtimeline > li.active .cbp_box .ui.segment {display: block;}*/

/* The icons */
.cbp_tmtimeline > li .cbp_tmicon {
    position: absolute;
    top: 13px;
    left: 3px;
    width: 16px;
    height: 16px;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    -webkit-font-smoothing: antialiased;
    background: #fff;
    border-radius: 50%;
    box-shadow: 0 0 0 2px #d7e4ed;
    text-align: center;
}

.cbp_tmtimeline > li .cbp_box {
    padding: 0 0 10px 24px;
}

.cbp_tmtimeline > li .cbp_box .ui.segment {
    display: none;
    margin-top: 5px;
    padding: .75em;
}

/*.cbp_tmtimeline > li .cbp_tmlabel .ui.segment:nth-child(2) {margin-top: 0;}*/
.cbp_tmtimeline > li .cbp_tmicon span {
    display: block;
    color: #6b758d;
}

.cbp_tmtimeline > li.on .cbp_tmicon {
    background: #6b758d;
}

.cbp_tmtimeline > li.on .cbp_tmicon span {
    color: #fff;
}

.cbp_tmtimeline .line-clip {
    display: inline-block;
    width: calc(100% - 100px);
    vertical-align: middle;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.cbp_tmtimeline .ui.ribbon.label {
    width: 100px;
}

.cbp_tmtimeline .ui.segment li {
    display: block;
    margin-bottom: 5px;
    font-size: .938em;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.cbp_tmtimeline .ui.segment li:last-child {
    margin-bottom: 0;
}

.cbp_tmtimeline .ui.segment li.mo {
    display: none;
}

.cbp_tmtimeline .ui.segment li i {
    margin-right: 5px;
    font-size: 1.5em;
    line-height: 0;
    vertical-align: middle;
}

.cbp_tmtimeline .ui.segment .ui.basic.buttons {
    width: 94px;
}

.open-class:before,
.open-class > li .cbp_tmicon {
    left: 5%;
}

.open-class > li .cbp_tmicon span {
    font-size: 24px;
    font-weight: bold;
    color: #6b758d;
}

.open-class > li .cbp_box {
    margin: 0 0 25px 8%;
}

.open-class > li .cbp_box .ui.segment {
    display: block;
}

.open-class > li .cbp_box .ui.segment:after {
    content: '';
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
}

.open-class > li .cbp_tmlabel .header {
    font-size: 18px;
    font-weight: 500;
    color: #333;
}

.open-class .line-clip {
    width: calc(100% - 180px);
    font-size: 14px;
}

.open-class .ui.segment .ui.basic.buttons {
    width: 160px;
}

/* horizontal-timeline */
.cd-horizontal-timeline {
    opacity: 0;
    margin: 2em auto;
    -webkit-transition: opacity 0.2s;
    -moz-transition: opacity 0.2s;
    transition: opacity 0.2s;
}

.cd-horizontal-timeline::before {
    /* never visible - this is used in jQuery to check the current MQ */
    content: 'mobile';
    display: none;
}

.cd-horizontal-timeline.loaded {
    /* show the timeline after events position has been set (using JavaScript) */
    opacity: 1;
}

.cd-horizontal-timeline .timeline {
    position: relative;
    height: 80px;
    width: 90%;
    max-width: 452px;
    margin: 0 auto;
}

.cd-horizontal-timeline .events-wrapper {
    position: relative;
    height: 100%;
    margin: 0 40px;
    overflow: hidden;
}

.cd-horizontal-timeline .events {
    /* this is the grey line/timeline */
    position: absolute;
    z-index: 1;
    left: 0;
    top: 49px;
    height: 2px;
    /* width will be set using JavaScript */
    background: #dfdfdf;
    -webkit-transition: -webkit-transform 0.4s;
    -moz-transition: -moz-transform 0.4s;
    transition: transform 0.4s;
}

.cd-horizontal-timeline .filling-line {
    /* this is used to create the green line filling the timeline */
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background-color: #526bbe;
    -webkit-transform: scaleX(0);
    -moz-transform: scaleX(0);
    -ms-transform: scaleX(0);
    -o-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -ms-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
    -webkit-transition: -webkit-transform 0.3s;
    -moz-transition: -moz-transform 0.3s;
    transition: transform 0.3s;
}

.cd-horizontal-timeline .events a {
    position: absolute;
    bottom: 0;
    z-index: 2;
    text-align: center;
    font-size: 1em;
    padding-bottom: 15px;
    /* fix bug on Safari - text flickering while timeline translates */
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    -ms-transform: translateZ(0);
    -o-transform: translateZ(0);
    transform: translateZ(0);
}

.cd-horizontal-timeline .events a::after {
    /* this is used to create the event spot */
    content: '';
    position: absolute;
    left: 50%;
    right: auto;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
    bottom: -5px;
    height: 12px;
    width: 12px;
    border-radius: 50%;
    border: 2px solid #dfdfdf;
    background-color: #f8f8f8;
    -webkit-transition: background-color 0.3s, border-color 0.3s;
    -moz-transition: background-color 0.3s, border-color 0.3s;
    transition: background-color 0.3s, border-color 0.3s;
}

.no-touch .cd-horizontal-timeline .events a:hover::after {
    background-color: #526bbe;
    border-color: #526bbe;
}

.cd-horizontal-timeline .events a.selected {
    pointer-events: none;
}

.cd-horizontal-timeline .events a.selected::after {
    background-color: #526bbe;
    border-color: #526bbe;
}

.cd-horizontal-timeline .events a.older-event::after {
    border-color: #526bbe;
}

/* sms_box */
.sms_box .button-area {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 1em;
}
.sms_box .search-box {
    margin-bottom: 1em;
}
.sms_box p.total_num {
    text-align: right;
    font-weight: 500;
}
.sms_box .tag-box .tags.sms {
    display: flex;
    flex-direction: column;
}

/* codeList_add */
.codeList_add {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 15px;
}
.codeList_add .field_box {
    padding-right: 10px;
}

.under_dvelp {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80vh;
}



/******************************* MEDIA QUERY *********************************/
@media only screen and (min-width: 1100px) {
    .cd-horizontal-timeline {
        margin: 0 auto;
    }

    .cd-horizontal-timeline::before {
        /* never visible - this is used in jQuery to check the current MQ */
        content: 'desktop';
    }

    #info-item-box .button-area {
        display: flex;
        flex-wrap: wrap;
        margin-left: auto;
        text-align: right;
    }

}

.cd-timeline-navigation a {
    /* these are the left/right arrows to navigate the timeline */
    position: absolute;
    z-index: 1;
    top: 62%;
    bottom: auto;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    height: 34px;
    width: 34px;
    border-radius: 50%;
    border: 2px solid #dfdfdf;
    /* replace text with an icon */
    overflow: hidden;
    color: transparent;
    text-indent: 100%;
    white-space: nowrap;
    -webkit-transition: border-color 0.3s;
    -moz-transition: border-color 0.3s;
    transition: border-color 0.3s;
}

.cd-timeline-navigation a::after {
    /* arrow icon */
    content: '';
    position: absolute;
    height: 16px;
    width: 16px;
    left: 50%;
    top: 50%;
    bottom: auto;
    right: auto;
    -webkit-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    -o-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    background: url(../img/cd-arrow.svg) no-repeat 0 0;
}

.cd-timeline-navigation a.prev {
    left: 0;
    -webkit-transform: translateY(-50%) rotate(180deg);
    -moz-transform: translateY(-50%) rotate(180deg);
    -ms-transform: translateY(-50%) rotate(180deg);
    -o-transform: translateY(-50%) rotate(180deg);
    transform: translateY(-50%) rotate(180deg);
}

.cd-timeline-navigation a.next {
    right: 0;
}

.no-touch .cd-timeline-navigation a:hover {
    border-color: #526bbe;
}

.cd-timeline-navigation a.inactive {
    cursor: not-allowed;
}

.cd-timeline-navigation a.inactive::after {
    background-position: 0 -16px;
}

.no-touch .cd-timeline-navigation a.inactive:hover {
    border-color: #dfdfdf;
}

.cd-horizontal-timeline .events-content {
    position: relative;
    width: 100%;
    overflow: hidden;
    -webkit-transition: height 0.4s;
    -moz-transition: height 0.4s;
    transition: height 0.4s;
}

.cd-horizontal-timeline .events-content li {
    position: absolute;
    z-index: 1;
    width: 100%;
    left: 0;
    top: 0;
    -webkit-transform: translateX(-100%);
    -moz-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    -o-transform: translateX(-100%);
    transform: translateX(-100%);
    padding: 0 5%;
    opacity: 0;
    -webkit-animation-duration: 0.4s;
    -moz-animation-duration: 0.4s;
    animation-duration: 0.4s;
    -webkit-animation-timing-function: ease-in-out;
    -moz-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
}

.cd-horizontal-timeline .events-content li.selected {
    /* visible event content */
    position: relative;
    z-index: 2;
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
}

.cd-horizontal-timeline .events-content li.enter-right, .cd-horizontal-timeline .events-content li.leave-right {
    -webkit-animation-name: cd-enter-right;
    -moz-animation-name: cd-enter-right;
    animation-name: cd-enter-right;
}

.cd-horizontal-timeline .events-content li.enter-left, .cd-horizontal-timeline .events-content li.leave-left {
    -webkit-animation-name: cd-enter-left;
    -moz-animation-name: cd-enter-left;
    animation-name: cd-enter-left;
}

.cd-horizontal-timeline .events-content li.leave-right, .cd-horizontal-timeline .events-content li.leave-left {
    -webkit-animation-direction: reverse;
    -moz-animation-direction: reverse;
    animation-direction: reverse;
}

.cd-horizontal-timeline .events-content li > * {
    max-width: 800px;
    margin: 0 auto;
}

.cd-horizontal-timeline .events-content .date {
    margin: 10px 0;
    padding-bottom: 10px;
    border-bottom: 1px dashed #d7d7d7;
}

.cd-horizontal-timeline .events-content p {
    display: inline-block;
    width: calc(100% - 120px);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    vertical-align: middle;
    font-size: 1em;
    line-height: 1.2;
}

.cd-horizontal-timeline .events-content em {
    display: block;
    font-style: italic;
    margin: 10px auto;
}

.cd-horizontal-timeline .events-content em::before {
    content: '- ';
}

.cd-horizontal-timeline .events-content span {
    display: inline-block;
    margin-right: 10px;
    font-size: .938em;
    color: #959595;
}

.cd-horizontal-timeline .events-content em, .cd-horizontal-timeline .events-content p {
    line-height: 1.6;
}

@-webkit-keyframes cd-enter-right {
    0% {
        opacity: 0;
        -webkit-transform: translateX(100%);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateX(0%);
    }
}

@-moz-keyframes cd-enter-right {
    0% {
        opacity: 0;
        -moz-transform: translateX(100%);
    }

    100% {
        opacity: 1;
        -moz-transform: translateX(0%);
    }
}

@keyframes cd-enter-right {
    0% {
        opacity: 0;
        -webkit-transform: translateX(100%);
        -moz-transform: translateX(100%);
        -ms-transform: translateX(100%);
        -o-transform: translateX(100%);
        transform: translateX(100%);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateX(0%);
        -moz-transform: translateX(0%);
        -ms-transform: translateX(0%);
        -o-transform: translateX(0%);
        transform: translateX(0%);
    }
}

@-webkit-keyframes cd-enter-left {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-100%);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateX(0%);
    }
}

@-moz-keyframes cd-enter-left {
    0% {
        opacity: 0;
        -moz-transform: translateX(-100%);
    }

    100% {
        opacity: 1;
        -moz-transform: translateX(0%);
    }
}

@keyframes cd-enter-left {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-100%);
        -moz-transform: translateX(-100%);
        -ms-transform: translateX(-100%);
        -o-transform: translateX(-100%);
        transform: translateX(-100%);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateX(0%);
        -moz-transform: translateX(0%);
        -ms-transform: translateX(0%);
        -o-transform: translateX(0%);
        transform: translateX(0%);
    }
}


/* upload-form 요소 */
.upload {
    background-color: #fff;
    width: 100%;
}

.drop {
    padding: 7em 1em 1em 1em;
    border: 1px dashed rgba(34, 36, 38, 0.15);
    border-radius: 3px;
    color: #bdbdbd;
    text-align: center;
    background: url(../img/upload_icon.png) 50% 1em no-repeat;
}

.drop button {
    border: 1px solid rgba(0, 0, 0, .3);
    margin-right: 10px;
    padding: 8px 16px;
    color: #000;
    border-radius: 2px;
    cursor: pointer;
    display: inline-block;
    line-height: 1;
}

.drop input {
    display: none;
}

.upload ul {
    margin-top: 1em;
    list-style: none;
}

.upload ul:after {
    content: '';
    display: block;
    height: 0;
    clear: both;
}

.upload ul li {
    position: relative;
    padding: 4px 12px;
    border-left: 1px solid #333;
    float: left;
    width: 33.33%;
    box-sizing: border-box;
}

.upload ul li input {
    display: none;
}

.upload ul li p {
    width: calc(100% - 20px);
    line-height: 140%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: #333;
}

.upload ul li small {
    color: #ccc;
    display: block;
}

.upload ul li button {
    width: 12px;
    height: 12px;
    margin-top: -6px;
    position: absolute;
    top: 50%;
    right: 12px;
    text-indent: -9999px;
    cursor: pointer;
    background: url(../img/btn_icon_cancel_b.png) no-repeat;
    background-size: cover;
}

.simple-uploader input[type="text"] {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.simple-uploader input[type="file"] {
    display: none;
}

.simple-uploader i:before {
    margin: 0;
}

/* thumbnail Box */
ul.thumb-extra {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -5px;
}

ul.thumb-extra li {
    position: relative;
    flex: 1;
    margin: 5px;
    border: 1px dashed #d7d7d7;
}

ul.thumb-extra li label {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    cursor: pointer;
    background: #f9f9f9;
}

ul.thumb-extra li input[type="file"] {
    display: none;
}

ul.thumb-extra li i {
    font-size: 2em;
}

ul.thumb-extra li .icon-btn {
    position: absolute;
    right: 0;
    display: none;
    color: #fff;
    z-index: 1;
}

ul.thumb-extra li.select .icon-btn {
    display: block;
}

ul.thumb-extra li.select:before {
    content: '\f383';
    position: absolute;
    top: 50%;
    left: 50%;
    font-family: 'Ionicons';
    font-size: 2em;
    color: #fff;
    transform: translate(-50%, -50%);
    z-index: 1;
}

ul.thumb-extra li.select .wide-img:after {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    width: calc(100% + 2px);
    height: 100%;
    transform: translateX(-50%);
    background: rgba(0, 0, 0, .7);
}

.preview-box {
    margin: -1em -1em 2em;
    border-top-left-radius: 0.28571429rem;
    border-top-right-radius: 0.28571429rem;
    overflow: hidden;
}

.preview-box span {
    display: inline-block;
    width: 100%;
    padding: 5em 0;
    font-size: 1.125em;
    color: #ccc;
    text-align: center;
    border-bottom: 1px solid #d7d7d7;
}

.preview-box .info-sec {
    display: flex;
    align-items: center;
    padding: 10px 1em;
}

.preview-box .info-sec label {
    font-weight: 700;
    color: #fff;
}

.preview-box .info-sec label.last {
    margin-left: auto;
}

/* tag Box */
.tag-box {
    display: flex;
    padding: .5em !important;
}

.tag-box ul.tags {
    display: flex;
    /* align-items: center; */
    flex-wrap: wrap;
    /* flex: 1; */
}

.tag-box ul.tags li {
    margin: 5px;
}

.tag-box ul.tags li.addedTag {
    display: flex;
    justify-content: space-between;
    padding: 5px 10px;
    border-radius: 30px;
    background: #ebebeb;
    font-weight: 500;
}
.tag-box ul.tags li.noTag {
    padding: 5px 10px;
    border-radius: 30px;
    background: #fff;
    font-size: 15px;
    color: #ccc;
}
.tag-box ul.tags li.addedTag span {
    display: inline-block;
    width: 24px;
    height: 24px;
    margin-left: 5px;
    margin-right: -5px;
    line-height: 24px;
    text-align: center;
    border-radius: 100%;
    cursor: pointer;
    background: #ccc;
}

.tag-box ul.tags li.tagAdd {
    flex: 1;
}

.tag-box ul.tags li.tagAdd input[type="text"] {
    border: none;
}

.tag-box .icon-btn {
    margin-left: auto;
}

.tag-box .icon-btn button {
    margin-left: 5px;
    background: transparent;
}

.tag-box .icon-btn i {
    font-size: 1.5em;
    color: #777;
}

/* paging  */
.paging {
    display: block;
    position: relative;
    margin: 0;
    padding: 1em 0;
    text-align: center;
}

.paging a {
    display: inline-block;
    width: 38px;
    height: 38px;
    border: 1px solid #e5e5e5;
    color: #666;
    font-size: .875em;
    line-height: 38px;
    vertical-align: top;
}

.paging a:hover {
    background: #f2f2f2;
}

.paging a.current {
    background: #999;
    color: #fff;
    border: 1px solid #999;
}

.paging button {
    display: inline-block;
    width: 38px;
    height: 38px;
    border: 1px solid #ccc;
    vertical-align: top;
    background: url("../img/ico_paging.png") no-repeat;
    text-indent: -9999px;
}

.paging button.pg_first {
    background-position: -107px -5px;
}

.paging button.pg_prev {
    background-position: -158px -5px;
}

.paging button.pg_next {
    background-position: -5px -5px;
}

.paging button.pg_last {
    background-position: -55px -5px;
}

.paging button.pg_first.disable {
    background-position: -107px -55px;
    cursor: default;
}

.paging button.pg_prev.disable {
    background-position: -158px -55px;
    cursor: default;
}

.paging button.pg_next.disable {
    background-position: -5px -55px;
    cursor: default;
}

.paging button.pg_last.disable {
    background-position: -55px -55px;
    cursor: default;
}

ul.review {
    margin-bottom: 20px;
    font-size: 13px;
}

ul.review > li {
    padding: 10px 0;
    border-bottom: 1px solid #ebebeb;
}

ul.review > li:after {
    content: "";
    display: block;
    height: 0;
    clear: both;
}

ul.review > li .view-img {
    float: left;
    width: 80px;
    height: 80px;
}

ul.review li p {
    float: left;
    margin-right: 5px;
}

ul.review li dl {
    float: right;
    width: calc(100% - 100px);
}

ul.review li dl dt {
    margin-bottom: 10px;
    padding: 5px;
    border: 1px solid #d7d7d7;
    border-radius: 2px;
    background: #f7f7f7;
}

ul.review li dl dt:after {
    content: "";
    display: block;
    height: 0;
    clear: both;
}

ul.review li dl dt span {
    padding: 0 5px;
    color: #333;
}

ul.review li dl dt span.date {
    font-size: 12px;
    color: #959595;
}

.grid-content {}

.grid-content:after {
    content: "";
    display: block;
    height: 0;
    clear: both;
}

.grid-content-box {
    position: relative;
    float: left;
    margin: .66%;
    padding: 10px;
    border-radius: 3px;
    border: 1px solid #d9d9d9;
    box-sizing: border-box;
    vertical-align: top;
    box-shadow: #f1f1f1 1px 1px 5px;
    background: #fff;
}

.grid-content-box .header {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.grid-content-box .header > * {
    vertical-align: middle;
}

.grid-content-box .header:after {
    content: "";
    display: block;
    height: 0;
    clear: both;
}

.grid-content-box .header p,
.grid-content-box .header div {
    display: inline-block;
    width: calc(100% - 60px);
    font-size: 1em;
    color: #333;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    vertical-align: middle;
}

.grid-content-box .header .ui.buttons {
    display: flex;
    width: auto;
}

.grid-content-box .header p span {
    display: block;
}

/*
.grid-content-box .header p em,
.grid-content-box .header > div em {display: inline-block; margin-right: 10px; font-style: normal;}
*/
.grid-content-box .header p span.date {
    margin-left: 26px;
    font-size: 12px;
    font-weight: normal;
    color: #00a5a6;
}

.grid-content-box .header .more-btn {
    cursor: pointer;
}

.grid-content-box .header > span {
    display: inline-block;
    min-width: 80px;
    text-align: right;
}

.grid-content-box .header .title {
    font-size: 1.125em;
}

.grid-content-box .header .ui.dropdown.button {
    float: right;
}

.grid-content-box .header a {
    color: #333;
}

.grid-content-box .header .sort-ico {
    font-size: 18px;
    color: #333;
    vertical-align: middle;
}

.grid-content-box .body-content {
    position: absolute;
    width: calc(100% + 2px);
    margin-left: -11px;
    padding: 10px;
    display: none;
    border: 1px solid #d9d9d9;
    border-top: none;
    background: #fff;
    z-index: 3;
}

.grid-content-box .body-content.on {
    display: block;
}

.grid-content-box .body-content.off {
    display: none;
}

.grid-content-box .footer {
    margin-top: 10px;
    text-align: center;
}

.grid-content-box .footer a {
    min-width: 100px;
}

.grid-content-box .select {
    color: #db2828 !important;
}

.portlet-placeholder {
    float: left;
    width: 100%;
    height: 44px;
    margin-top: 8px;
    border: 1px dashed #ccc;
    border-radius: 4px;
    background-color: #f7f7f7;
}

.ui-sortable-handle {
    cursor: move;
}

.tbl-list {
    border-top: none;
    border-top: 1px solid #dcdcdc;
}

.tbl-list dt ul {
    color: #1a345e;
    background: #f7f9fa !important;
}

.tbl-list dt ul li {
    border-left: 1px solid #dcdcdc;
}

.tbl-list dd {}

.tbl-list ul {
    overflow: hidden;
    border-bottom: 1px solid #ebebeb;
    background: #fff;
}

.tbl-list ul li {
    float: left;
    width: 20%;
    padding: 8px 0;
    text-align: center;
    border-left: 1px solid #ebebeb;
}

.tbl-list ul li:first-child {
    border-left: none;
    width: 35%;
}

.tbl-list ul li:first-child .ui.button {
    width: 74px;
}

.tbl-list ul li:nth-child(4) {
    width: 25%;
}

.tbl-list ul li.setup {
    width: 100%;
    border-top: 1px solid #ebebeb;
    border-left: none;
}


.modal-type .grid-content-box .header p,
.modal-type .grid-content-box .header > div {
    flex: 1;
    line-height: 30px;
}

.modal-type .grid-content-box .body-content {
    position: relative;
    width: 100%;
    margin-left: 0;
    padding: 10px 0 0 0;
    display: none;
    border: none;
}

.modal-type .grid-content-box .body-content.on {
    display: block;
}

.modal-type .grid-content-box .body-content.sub-depth {
    display: block;
}

.modal-type .grid-content-box .body-content.sub-depth.off {
    display: none;
}

ol.tbl-list {
    padding: 5px 0;
    padding-left: 20px;
}

ol.tbl-list li {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 10px;
    font-size: 1em;
    color: #666;
}

ol.tbl-list li ol {
    flex-basis: 100%;
    padding-left: 20px;
}

ol.tbl-list li a,
ol.tbl-list li .flex-cont {
    flex: 1;
}

ol.tbl-list li a {
    display: inline-block;
    white-space: nowrap;
    text-overflow: ellipsis;
    vertical-align: middle;
    overflow: hidden;
}

ol.tbl-list li .text {
    flex: 1;
    display: inline-block;
    color: #333;
    text-align: left;
    white-space: nowrap;
    text-overflow: ellipsis;
    vertical-align: middle;
    overflow: hidden;
}

ol.tbl-list li .viewInfo {
    float: right;
    display: inline-block;
}

ol.tbl-list li .viewInfo span {
    display: inline-block;
    margin-right: 10px;
    color: #999;
}

.grid-content-box .header .number,
ol.tbl-list li .number {
    display: inline-block;
}

ol.tbl-list li > span,
.menu-nth-wrap > span {
    min-width: 80px;
    text-align: right;
}

.menu-nth-wrap {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.menu-nth-wrap .more-btn {
    width: 280px;
    cursor: pointer;
}

.menu-nth-wrap p {
    flex: 1;
    margin-left: 20px;
    word-break: break-all;
}

.menu-nth-wrap .controll-box {
    display: flex;
    align-items: center;
}

.menu-nth-wrap .controll-box > * {
    margin-left: 20px;
}

.menu-nth-wrap ol.tbl-list li .title {
    width: 220px;
    flex: none;
}

.menu-nth-wrap ol.tbl-list li > ol > li .title {
    width: 200px;
    flex: none;
}

ul.sort-header {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: .66%;
    padding: 10px;
    border: 1px solid #d7d7d7;
    border-radius: 3px;
    background: #f9f9f9;
}

ul.sort-header li {
    font-weight: 500;
    text-align: center;
}

ul.sort-header li.title {
    width: 340px;
}

ul.sort-header li.link {
    flex: 1;
}

ul.sort-header li.controll-box > * {
    margin-left: 20px;
}

.modal-placeholder {
    width: 100%;
    height: 37px;
    margin-top: 8px;
    border: 1px dashed #ccc;
    border-radius: 4px;
    background-color: #f7f7f7;
}

.line-sortable-box {
    display: flex;
    width: 100%;
}

.line-sortable-box .account-list {
    flex: 1;
    padding: 1em 0;
}

.line-sortable-box .account-list .line-box {
    display: flex;
    margin-bottom: 1em;
}

.line-sortable-box .account-list .line-box:last-child {
    margin-bottom: 0;
}

.line-sortable-box .account-list .line-box .question {
    display: flex;
    align-items: center;
    flex: 1;
    position: relative;
    margin-right: 80px;
    padding: 1em 1em 1em 3em;
    border: 1px solid #d9d9d9;
    box-shadow: #f1f1f1 1px 1px 5px;
}

.line-sortable-box .account-list .line-box .question:before {
    position: absolute;
    left: .5em;
    width: 32px;
    height: 32px;
    text-align: center;
    line-height: 28px;
    border: 2px solid #D4D4D5;
    border-radius: 100%;
}

.line-sortable-box .account-list .line-box.num01 .question:before {
    content: 'A';
}

.line-sortable-box .account-list .line-box.num02 .question:before {
    content: 'B';
}

.line-sortable-box .account-list .line-box.num03 .question:before {
    content: 'C';
}

.line-sortable-box .account-list .line-box.num04 .question:before {
    content: 'D';
}

.line-sortable-box .account-list .line-box.num05 .question:before {
    content: 'E';
}

.line-sortable-box .account-list .line-box.num06 .question:before {
    content: 'F';
}

.line-sortable-box .account-list .line-box.num07 .question:before {
    content: 'G';
}

.line-sortable-box .account-list .line-box.num08 .question:before {
    content: 'H';
}

.line-sortable-box .account-list .line-box.num09 .question:before {
    content: 'I';
}

.line-sortable-box .account-list .line-box.num010 .question:before {
    content: 'J';
}

.line-sortable-box .account-list .line-box .question:after {
    content: '';
    position: absolute;
    top: 50%;
    right: -80px;
    width: 80px;
    height: 2px;
    background: #d7d7d7;
}

.line-sortable-box .account-list .line-box .slot {
    display: flex;
    align-items: center;
    width: 40%;
    padding: 0 1em;
    border: 1px dashed #d9d9d9;
    border-radius: 4px;
    background: #f9f9f9;
}

.line-sortable-box .account-list .line-box .slot span {
    position: relative;
    display: block;
    width: calc(100% + 2em);
    margin: 0 -1em;
    padding: 1em 1em 1em 2em;
}

.line-sortable-box .inventory-list {
    width: 320px;
    margin-left: 20px;
    padding: 1em;
    border: 1px solid #dcdcdc;
    border-radius: 4px;
    background: #f7f9fa;
}

.line-sortable-box .inventory-list .slot {
    display: flex;
    min-height: 3.45em;
    margin-bottom: 1em;
    border: 1px dashed #d9d9d9;
    border-radius: 4px;
    background: #f1f1f1;
    overflow: hidden;
}

.line-sortable-box .inventory-list .slot.item-disabled {
    background: #fff;
}

.line-sortable-box .inventory-list .slot:last-child {
    margin-bottom: 0;
}

.line-sortable-box .inventory-list .slot span {
    flex: 1;
    position: relative;
    display: block;
    padding: 1em 1em 1em 2em;
    background: #fff;
}

.line-sortable-box .account-list .line-box .slot .ui-sortable-placeholder,
.line-sortable-box .inventory-list .slot .ui-sortable-placeholder {
    display: none;
}

.line-sortable-box .account-list .line-box .slot span i,
.line-sortable-box .inventory-list .slot span i {
    position: absolute;
    top: 50%;
    left: .25em;
    font-size: 1.25em;
    transform: translateY(-50%);
}

.listTab {
    box-shadow: inset 0px -1px 0px 0px rgba(0, 0, 0, 0.75);
}

.listTab:after {
    display: block;
    clear: both;
    content: '';
}

.listTab ul {
    width: 100%;
    overflow: hidden;
}

.listTab ul li {
    float: left;
    min-width: 120px;
    margin-right: -1px;
    border: 1px solid #d9d9d9;
    border-bottom: 1px solid #333;
    background: #f6f6f6;
}

.listTab ul li a {
    display: block;
    padding: 12px 10px;
    font-size: 0.933em;
    text-align: center;
    color: #acacac;
    cursor: pointer;
}
.listTab.login_tab ul li a {
    padding: 7px 10px;
    font-size: 1em;
}

.listTab ul li.select {
    position: relative;
    border: 1px solid #333;
    border-bottom: 1px solid #fff;
    background: #fff;
}

.listTab ul li.select a {
    color: #333;
    cursor: default;
}

.tab_content {
    display: none;
}
.login-box .tab_content {
    display: none;
    min-height: 215px;
}

.element {
    margin-bottom: 1.3em;
}

.element:last-child {
    margin-bottom: 0;
}

.average > .ui.stackable.grid > .column {
    padding: 0 5px !important;
    text-align: center;
}

.global_tab {
    margin: 0 auto 20px auto;
    text-align: center;
    overflow: hidden;
}

.global_tab a {
    position: relative;
    float: left;
    display: block;
    padding: 12.5px 0;
    font-size: 1em;
    font-weight: 500;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    border-bottom: 1px solid rgba(34, 36, 38, 0.15);
}

.global_tab a.on {
    color: #333;
    border-bottom: 2px solid #4f545a;
}

.tab_content_view {
    display: none;
}

#info-item-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    min-height: 78px;
    margin: 0 -2em;
    padding: 1em 2em;
    transition: background-color .3s ease;
}

#info-item-box .button-area {
    display: flex;
    flex-wrap: wrap;
    margin-left: auto;
    text-align: right;
}

#info-item-box .button-area .listType {
    display: inline-block;
    min-width: auto;
    margin-right: .375em;
    font-size: 2.875em;
    color: #333;
    line-height: 1;
    vertical-align: middle;
}

#info-item-box .button-area .search-box {
    margin-right: 5px;
}

#info-item-box .button-area a,
#info-item-box .button-area button {
    min-width: 80px;
}

#info-item-box .button-area .ui.action.input > .button {
    min-width: auto;
    flex: none;
}

#info-item-box.ui.sticky.fixed {
    margin: 0;
    box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, .2);
    background: #fff;
    z-index: 100;
}

/* .content #info-item-box.ui.sticky.fixed {
    left: 60px !important;
}
.content.gn_open #info-item-box.ui.sticky.fixed {
    left: 240px !important;
} */
#info-item-box.ui.sticky.fixed + .ui.form {
    padding-top: 70px !important;
}

ul.cbp_tmtimeline + #context #info-item-box.ui.sticky.fixed {
    margin: 0;
}

aside.content-add-box + #context #info-item-box.ui.sticky.fixed {
    margin: 0;
}

.option-content {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 1em;
}

.option-content .ui.message {
    font-weight: 500;
}

.option-content:after {
    content: '';
    display: block;
    clear: both;
}

.option-content .page-title {
    display: inline-block;
    margin-bottom: 0;
    padding: .175em 0;
}

.option-content .head-txt {
    font-size: 1.125em;
    font-weight: 500;
    color: #333;
    vertical-align: middle;
}

.option-content .head-txt-sm {
    font-weight: 500;
    color: #333;
    line-height: 36px;
    vertical-align: middle;
}

.option-content .totalNum {
    display: inline-block;
    margin-right: 1em;
    font-size: 1.25em;
    color: #333;
    vertical-align: middle;
}

.option-content .listType {
    display: inline-block;
    margin-right: .375em;
    font-size: 2.875em;
    color: #333;
    vertical-align: middle;
}

.option-content .button-area {
    margin-left: auto;
}

.option-content .button-area span.user {
    margin-right: 5px;
    font-size: 14px;
}

.option-content .search-box.ui.action.input > input[type='text'] {
    width: 200px;
}

.option-content .list-num {
    min-width: 60px !important;
}

.option-content .mo-show {
    display: none !important;
}

.flex-box {
    display: flex !important;
    align-items: center;
}

.flex-box .button-area {
    justify-content: flex-end;
}

.result-content {
    width: calc(100% - 2px);
    margin: 0 auto;
}

.result-content .question-box {
    position: relative;
    margin-bottom: 20px;
}

.result-content .question-box .ui.segment {
    background: transparent;
}

.result-content .question-box .ui.corner.label {
    border-color: #4b5f7f !important;
    color: #FFFFFF !important;
}

.result-content .question-box .result-view {
    margin-top: 5px;
}

.request-type.ui.message .header {
    margin-bottom: 0.75em;
    width: 78%;
    word-break: break-all;
}

.request-type .ui.dropdown {
    position: absolute;
    top: .78575em;
    right: 1.5em;
}

.request-type .bottom-flex {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.request-type .bottom-flex .box {
    display: flex;
    align-items: center;
    flex: 1;
}

.request-type .bottom-flex .box:last-child {
    text-align: right;
    justify-content: flex-end;
    flex: auto;
}

.request-type .info-box {
    color: #999;
    overflow: hidden;
}

.request-type .info-box span:last-child {
    float: right;
}

.request-type .info-box li {
    display: inline-block;
    padding-left: 5px;
}

.request-type .info-box li:before {
    content: "";
    display: inline-block;
    width: 1px;
    height: 12px;
    margin-right: 5px;
    vertical-align: middle;
    background-color: #c3c3c3;
}

.request-type .info-box li:first-child {
    padding-left: 0;
}

.request-type .info-box li:first-child:before {
    display: none;
}

.inquiry-button {
    cursor: pointer;
}

.ui.right.sidebar.inquiry-inbox {
    width: 740px;
    padding: 50px 20px 20px 20px;
    background: #fff;
}

.ui.right.sidebar.inquiry-inbox .close {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 26px;
    height: 26px;
    text-indent: -9999px;
    background: url(../img/wide_inbox_close.png) no-repeat;
}

.sidebar-button {
    cursor: pointer;
}

.ui.right.sidebar.wide-inbox,
.ui.right.sidebar.wide-inbox2 {
    width: 740px;
    padding: 50px 20px;
    background: #fff;
}

.ui.right.sidebar.wide-inbox .close,
.ui.right.sidebar.wide-inbox2 .close {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 26px;
    height: 26px;
    text-indent: -9999px;
    background: url(../img/wide_inbox_close.png) no-repeat;
}

.ui.right.sidebar.wide-inbox2 .ui.header {
    font-size: 1.25em;
    font-weight: 700;
}

.ui.right.sidebar.wide-inbox2 .field-list {
    margin-bottom: 3em;
}

.ui.right.sidebar.wide-inbox .page_title_box {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.ui.right.sidebar .field-list .inline.field {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.scrollbox {
    height: 100%;
    overflow: hidden;
}

.scrollbox .mCSB_container {
    width: calc(100% - 2px);
    margin-left: 1px;
    overflow: visible;
}

.ui.right.sidebar.counsel-inbox {
    width: 340px;
    padding: 20px;
    font-size: .875em;
    color: #ccc;
    background: #545454;
}

i.icon.counsel-button {
    display: inline-block;
    margin-left: 5px;
    color: #999;
    cursor: pointer;
}

.ui.button.img-button {
    padding: 0 !important;
    box-shadow: none !important;
    border: 1px solid rgba(34, 36, 38, 0.15) !important;
    border-left: none !important;
}

.ui.button.img-button > button {
    width: 16px;
    height: 16px;
    margin: 0 3px !important;
}

.img-button {
    display: inline-block;
    vertical-align: middle;
}

.img-button button {
    width: 24px;
    height: 24px;
    margin: .5em !important;
}

.img-button button.icon_check {
    background: url(../img/btn_icon_check_b.png) no-repeat;
    background-size: cover;
}

.img-button button.icon_cancel {
    background: url(../img/btn_icon_cancel_b.png) no-repeat;
    background-size: cover;
}

.img-button button.icon_trash {
    background: url(../img/btn_icon_trash_b.png) no-repeat;
    background-size: cover;
}

.designImg input[type="radio"] {
    position: absolute;
    left: -9999px;
    width: 16px;
    height: 16px;
    opacity: 1;
    filter: alpha(opacity=0);
}

.designImg label {
    position: relative;
    display: inline-block;
    margin: 0 5px;
    cursor: pointer;
}

.designImg label img {
    margin: 0 !important;
}

.designImg label.on {
    background: #000 url(../img/circle_choice.png) 50% 50% no-repeat;
}

.designImg label.on img {
    opacity: 0.4;
    filter: alpha(opacity=40);
}

/* --------------------------------

Basic Style

-------------------------------- */
.cd-breadcrumb, .cd-multi-steps {
    padding: 0.5em 1em;
    margin: 0 auto 2em;
    background-color: #3f51b5;
    border-radius: .25em;
}

.cd-breadcrumb:after, .cd-multi-steps:after {
    content: "";
    display: table;
    clear: both;
}

.cd-breadcrumb li, .cd-multi-steps li {
    display: inline-block;
    float: left;
    margin: 0.5em 0;
}

.cd-breadcrumb li::after, .cd-multi-steps li::after {
    /* this is the separator between items */
    font-family: Icons;
    font-size: 1.25em;
    display: inline-block;
    content: '\f105';
    margin: 0 .6em;
    color: rgba(255, 255, 255, .7);
}

.cd-breadcrumb li:last-of-type::after, .cd-multi-steps li:last-of-type::after {
    /* hide separator after the last item */
    display: none;
}

.cd-breadcrumb li > *, .cd-multi-steps li > * {
    /* single step */
    display: inline-block;
    font-size: 1.25em;
    color: rgba(255, 255, 255, .7);
}

.cd-breadcrumb li.current > *, .cd-multi-steps li.current > * {
    /* selected step */
    color: #fff;
}

.cd-breadcrumb.custom-separator li::after, .cd-multi-steps.custom-separator li::after {
    /* replace the default arrow separator with a custom icon */
    content: '';
    height: 16px;
    width: 16px;
    /*background: url(../img/cd-custom-separator.svg) no-repeat center center;*/
    vertical-align: middle;
}

.cd-breadcrumb.custom-icons li > *::before, .cd-multi-steps.custom-icons li > *::before {
    /* add a custom icon before each item */
    content: '';
    display: inline-block;
    height: 20px;
    width: 20px;
    margin-right: .4em;
    margin-top: -2px;
    /*background: url(../img/cd-custom-icons-01.svg) no-repeat 0 0;*/
    vertical-align: middle;
}

.cd-breadcrumb.custom-icons li:not(.current):nth-of-type(2) > *::before, .cd-multi-steps.custom-icons li:not(.current):nth-of-type(2) > *::before {
    /* change custom icon using image sprites */
    background-position: -20px 0;
}

.cd-breadcrumb.custom-icons li:not(.current):nth-of-type(3) > *::before, .cd-multi-steps.custom-icons li:not(.current):nth-of-type(3) > *::before {
    background-position: -40px 0;
}

.cd-breadcrumb.custom-icons li:not(.current):nth-of-type(4) > *::before, .cd-multi-steps.custom-icons li:not(.current):nth-of-type(4) > *::before {
    background-position: -60px 0;
}

.cd-breadcrumb.custom-icons li.current:first-of-type > *::before, .cd-multi-steps.custom-icons li.current:first-of-type > *::before {
    /* change custom icon for the current item */
    background-position: 0 -20px;
}

.cd-breadcrumb.custom-icons li.current:nth-of-type(2) > *::before, .cd-multi-steps.custom-icons li.current:nth-of-type(2) > *::before {
    background-position: -20px -20px;
}

.cd-breadcrumb.custom-icons li.current:nth-of-type(3) > *::before, .cd-multi-steps.custom-icons li.current:nth-of-type(3) > *::before {
    background-position: -40px -20px;
}

.cd-breadcrumb.custom-icons li.current:nth-of-type(4) > *::before, .cd-multi-steps.custom-icons li.current:nth-of-type(4) > *::before {
    background-position: -60px -20px;
}

@media only screen and (min-width: 768px) {
    .cd-breadcrumb, .cd-multi-steps {
        padding: 0 1.2em;
    }

    .cd-breadcrumb li, .cd-multi-steps li {
        margin: 1.2em 0;
    }

    .cd-breadcrumb li::after, .cd-multi-steps li::after {
        margin: 0 1em;
    }

    .cd-breadcrumb li > *, .cd-multi-steps li > * {
        font-size: 1.25em;
    }
}

/* --------------------------------

Triangle breadcrumb

-------------------------------- */
@media only screen and (min-width: 768px) {
    .cd-breadcrumb.triangle {
        /* reset basic style */
        background-color: transparent;
        padding: 0;
    }

    .cd-breadcrumb.triangle li {
        position: relative;
        padding: 0;
        margin: 4px 4px 4px 0;
    }

    .cd-breadcrumb.triangle li:last-of-type {
        margin-right: 0;
    }

    .cd-breadcrumb.triangle li > * {
        position: relative;
        padding: 1em .8em 1em 2.5em;
        color: #2c3f4c;
        background-color: #edeff0;
        /* the border color is used to style its ::after pseudo-element */
        border-color: #edeff0;
    }

    .cd-breadcrumb.triangle li.current > * {
        /* selected step */
        color: #ffffff;
        background-color: #96c03d;
        border-color: #96c03d;
    }

    .cd-breadcrumb.triangle li:first-of-type > * {
        padding-left: 1.6em;
        border-radius: .25em 0 0 .25em;
    }

    .cd-breadcrumb.triangle li:last-of-type > * {
        padding-right: 1.6em;
        border-radius: 0 .25em .25em 0;
    }

    .no-touch .cd-breadcrumb.triangle a:hover {
        /* steps already visited */
        color: #ffffff;
        background-color: #2c3f4c;
        border-color: #2c3f4c;
    }

    .cd-breadcrumb.triangle li::after, .cd-breadcrumb.triangle li > *::after {
        /*
    	li > *::after is the colored triangle after each item
    	li::after is the white separator between two items
    */
        content: '';
        position: absolute;
        top: 0;
        left: 100%;
        content: '';
        height: 0;
        width: 0;
        /* 48px is the height of the <a> element */
        border: 24px solid transparent;
        border-right-width: 0;
        border-left-width: 20px;
    }

    .cd-breadcrumb.triangle li::after {
        /* this is the white separator between two items */
        z-index: 1;
        -webkit-transform: translateX(4px);
        -moz-transform: translateX(4px);
        -ms-transform: translateX(4px);
        -o-transform: translateX(4px);
        transform: translateX(4px);
        border-left-color: #ffffff;
        /* reset style */
        margin: 0;
    }

    .cd-breadcrumb.triangle li > *::after {
        /* this is the colored triangle after each element */
        z-index: 2;
        border-left-color: inherit;
    }

    .cd-breadcrumb.triangle li:last-of-type::after, .cd-breadcrumb.triangle li:last-of-type > *::after {
        /* hide the triangle after the last step */
        display: none;
    }

    .cd-breadcrumb.triangle.custom-separator li::after {
        /* reset style */
        background-image: none;
    }

    .cd-breadcrumb.triangle.custom-icons li::after, .cd-breadcrumb.triangle.custom-icons li > *::after {
        /* 50px is the height of the <a> element */
        border-top-width: 25px;
        border-bottom-width: 25px;
    }

    @-moz-document url-prefix() {

        .cd-breadcrumb.triangle li::after,
        .cd-breadcrumb.triangle li > *::after {
            /* fix a bug on Firefix - tooth edge on css triangle */
            border-left-style: dashed;
        }
    }
}

/* --------------------------------

Custom icons hover effects - breadcrumb and multi-steps

-------------------------------- */
@media only screen and (min-width: 768px) {
    .no-touch .cd-breadcrumb.triangle.custom-icons li:first-of-type a:hover::before, .cd-breadcrumb.triangle.custom-icons li.current:first-of-type em::before, .no-touch .cd-multi-steps.text-center.custom-icons li:first-of-type a:hover::before, .cd-multi-steps.text-center.custom-icons li.current:first-of-type em::before {
        /* change custom icon using image sprites - hover effect or current item */
        background-position: 0 -40px;
    }

    .no-touch .cd-breadcrumb.triangle.custom-icons li:nth-of-type(2) a:hover::before, .cd-breadcrumb.triangle.custom-icons li.current:nth-of-type(2) em::before, .no-touch .cd-multi-steps.text-center.custom-icons li:nth-of-type(2) a:hover::before, .cd-multi-steps.text-center.custom-icons li.current:nth-of-type(2) em::before {
        background-position: -20px -40px;
    }

    .no-touch .cd-breadcrumb.triangle.custom-icons li:nth-of-type(3) a:hover::before, .cd-breadcrumb.triangle.custom-icons li.current:nth-of-type(3) em::before, .no-touch .cd-multi-steps.text-center.custom-icons li:nth-of-type(3) a:hover::before, .cd-multi-steps.text-center.custom-icons li.current:nth-of-type(3) em::before {
        background-position: -40px -40px;
    }

    .no-touch .cd-breadcrumb.triangle.custom-icons li:nth-of-type(4) a:hover::before, .cd-breadcrumb.triangle.custom-icons li.current:nth-of-type(4) em::before, .no-touch .cd-multi-steps.text-center.custom-icons li:nth-of-type(4) a:hover::before, .cd-multi-steps.text-center.custom-icons li.current:nth-of-type(4) em::before {
        background-position: -60px -40px;
    }
}

@media (min-width: 769px) {
    .modal-dialog {
        width: 600px;
        margin: 80px auto;
    }

    .modal-content {
        -webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
    }

    .modal-sm {
        width: 300px;
    }
}

@media (min-width: 992px) {
    .modal-lg {
        width: 900px;
    }
}


/* --------------------------------

Multi steps indicator

-------------------------------- */
@media only screen and (min-width: 768px) {
    .cd-multi-steps {
        /* reset style */
        background-color: transparent;
        padding: 0;
        text-align: center;
    }

    .cd-multi-steps li {
        position: relative;
        float: none;
        margin: 0.4em 200px 0.4em 0;
    }

    .cd-multi-steps li:last-of-type {
        margin-right: 0;
    }

    .cd-multi-steps li::after {
        /* this is the line connecting 2 adjacent items */
        position: absolute;
        content: '';
        height: 1px;
        background: #9e9e9e;
        /* reset style */
        margin: 0;
    }

    .cd-multi-steps li.visited::after {
        background-color: #96c03d;
    }

    .cd-multi-steps li > *, .cd-multi-steps li.current > * {
        position: relative;
        color: #2c3f4c;
    }

    .cd-multi-steps.custom-separator li::after {
        /* reset style */
        height: 4px;
        background: #edeff0;
    }

    .cd-multi-steps.text-center li::after {
        width: 100%;
        top: 50%;
        left: 100%;
        -webkit-transform: translateY(-50%) translateX(-1px);
        -moz-transform: translateY(-50%) translateX(-1px);
        -ms-transform: translateY(-50%) translateX(-1px);
        -o-transform: translateY(-50%) translateX(-1px);
        transform: translateY(-50%) translateX(-1px);
    }

    .cd-multi-steps.text-center li > * {
        z-index: 1;
        padding: .6em 1em;
        border-radius: .25em;
        background-color: #edeff0;
    }

    .no-touch .cd-multi-steps.text-center a:hover {
        background-color: #2c3f4c;
    }

    .cd-multi-steps.text-center li.current > *, .cd-multi-steps.text-center li.visited > * {
        color: #ffffff;
        background-color: #96c03d;
    }

    .cd-multi-steps.text-center.custom-icons li.visited a::before {
        /* change the custom icon for the visited item - check icon */
        background-position: 0 -60px;
    }

    .cd-multi-steps.text-top li, .cd-multi-steps.text-bottom li {
        text-align: center;
    }

    .cd-multi-steps.text-top li::after, .cd-multi-steps.text-bottom li::after {
        /* this is the line connecting 2 adjacent items */
        position: absolute;
        left: 50%;
        /* 40px is the <li> right margin value */
        width: calc(100% + 200px);
    }

    .cd-multi-steps.text-top li > *::before, .cd-multi-steps.text-bottom li > *::before {
        /* this is the spot indicator */
        content: '';
        position: absolute;
        z-index: 1;
        left: 50%;
        right: auto;
        -webkit-transform: translateX(-50%);
        -moz-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        -o-transform: translateX(-50%);
        transform: translateX(-50%);
        height: 12px;
        width: 12px;
        border-radius: 50%;
        background-color: #9e9e9e;
    }

    .cd-multi-steps.text-top li.visited > *::before,
    .cd-multi-steps.text-top li.current > *::before, .cd-multi-steps.text-bottom li.visited > *::before,
    .cd-multi-steps.text-bottom li.current > *::before {
        background-color: #3f51b5;
    }

    .no-touch .cd-multi-steps.text-top a:hover::before, .no-touch .cd-multi-steps.text-bottom a:hover::before {
        box-shadow: 0 0 0 7px rgba(158, 158, 158, 0.3);
    }

    .cd-multi-steps.text-top li::after {
        /* this is the line connecting 2 adjacent items */
        bottom: 4px;
    }

    .cd-multi-steps.text-top li > * {
        padding-bottom: 20px;
    }

    .cd-multi-steps.text-top li > *::before {
        /* this is the spot indicator */
        bottom: 0;
    }

    .cd-multi-steps.text-bottom li::after {
        /* this is the line connecting 2 adjacent items */
        top: 3px;
    }

    .cd-multi-steps.text-bottom li > * {
        padding-top: 20px;
    }

    .cd-multi-steps.text-bottom li > *::before {
        /* this is the spot indicator */
        top: 0;
    }
}

/* --------------------------------

Add a counter to the multi-steps indicator

-------------------------------- */
.cd-multi-steps.count li {
    counter-increment: steps;
}

.cd-multi-steps.count li > *::before {
    content: counter(steps) " - ";
}

@media only screen and (min-width: 768px) {

    .cd-multi-steps.text-top.count li > *::before,
    .cd-multi-steps.text-bottom.count li > *::before {
        /* this is the spot indicator */
        content: counter(steps);
        height: 26px;
        width: 26px;
        line-height: 26px;
        font-size: .625em;
        color: #fff;
    }

    .cd-multi-steps.text-top.count li:not(.current) span::before,
    .cd-multi-steps.text-bottom.count li:not(.current) span::before {
        /* steps not visited yet - counter color */
        color: rgba(255, 255, 255, .7);
    }

    .cd-multi-steps.text-top.count li::after {
        bottom: 11px;
    }

    .cd-multi-steps.text-top.count li > * {
        padding-bottom: 34px;
    }

    .cd-multi-steps.text-bottom.count li::after {
        top: 11px;
    }

    .cd-multi-steps.text-bottom.count li > * {
        padding-top: 34px;
    }
}


/********************************************************************************************************
 * 추가버튼
*********************************************************************************************************/
.btn,
.content .btn,
.ui.sidebar .btn {
    position: relative;
    display: inline-block;
    margin-right: 5px;
    padding: 0.785em 1em 0.785em;
    /* color: #333; */
    line-height: 1em;
    min-height: 1em;
    text-align: center;
    vertical-align: middle;
    border: 1px solid #666;
    border-radius: 2px;
    transition: background-color .2s, border-color .2s;
}
.btn.no_use,
.content .btn.no_use,
.ui.sidebar .btn.no_use {
    cursor: not-allowed;
}
.btn:hover.no_use,
.content .btn:hover.no_use,
.ui.sidebar .btn:hover.no_use {
    border: 1px solid #666;
    color: #333;
    background: none;
}
.btn:last-child,
.content .btn:last-child,
.ui.sidebar .btn:last-child {
    margin-right: 0;
}
.btn:hover,
.content .btn:hover {
    border-color: #039be5;
    background: #f8f9fa;
    color: #039be5;
    font-weight: 500;
}
.btn-primary,
.content .btn-primary {
    color: #fff;
    border-color: #777;
    background: #777;
}
.btn-primary:hover.no_use,
.content .btn-primary:hover.no_use {
    color: #fff;
    border-color: #777;
    background: #777;
}
.btn-primary:hover,
.content .btn-primary:hover {
    color: #fff;
    border-color: #039be5;
    background: #039be5;
}
.btn-negative:hover,
.content .btn-negative:hover {
    color: #fff;
    border-color: #f44336 !important;
    background: #f44336;
}
.btn-upload:hover,
.content .btn-upload:hover {
    color: #fff;
    border-color: #21ba45;
    background: #21ba45;
}
.btn.disabled,
.content .btn.disabled {
    color: #999;
    cursor: default;
    border-color: #ccc;
    background: #f9f9f9;
}
.btn.disabled:hover,
.content .btn.disabled:hover {
    color: #999;
}

.btn-primary.disabled,
.content .btn-primary.disabled {
    color: #fff;
    border-color: #acacac;
    background: #acacac;
}

.btn-primary.disabled:hover,
.content .btn-primary.disabled:hover {
    color: #fff;
}

.circle-btn {
    position: relative;
    display: inline-block;
    margin-right: 10px;
    color: #f1f1f1;
    vertical-align: middle;
    border: 2px solid #999;
    border-radius: 56px;
}

.circle-btn:last-child {
    margin-right: 0;
}

.circle-btn:hover {
    color: #fff;
}

.circle-btn span {
    line-height: 54px;
}

.circle-btn-primary {
    border-color: #fff;
}

.circle-btn-negative {
    border-color: #fff;
    background: #555;
}

.circle-btn i {
    opacity: 0.3;
}

.circle-btn.circle-btn-negative i {
    filter: invert(100%);
    -webkit-filter: invert(100%);
    opacity: 1;
}

.circle-btn.circle-btn-primary i {
    opacity: 1;
}

.btn-icon {
    width: 56px;
    height: 56px;
    line-height: 52px;
    font-size: 0;
    text-align: center;
}

.btn-icon i {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 24px;
    height: 24px;
    margin: -12px 0 0 -12px;
    display: inline-block;
}

.btn-icon-plus {
    background: url(../img/btn_icon_plus.png) no-repeat;
    background-size: cover;
}

.btn-icon-check {
    background: url(../img/btn_icon_check.png) no-repeat;
    background-size: cover;
}

.btn-icon-cancel {
    background: url(../img/btn_icon_cancel.png) no-repeat;
    background-size: cover;
}

.btn-icon-prev {
    background: url(../img/btn_icon_prev.png) no-repeat;
    background-size: cover;
}

.btn-icon-next {
    background: url(../img/btn_icon_next.png) no-repeat;
    background-size: cover;
}

.btn-icon-home {
    background: url(../img/btn_icon_home.png) no-repeat;
    background-size: cover;
}

.btn-icon-modify {
    background: url(../img/btn_icon_modify.png) no-repeat;
    background-size: cover;
}

.btn-icon-report {
    background: url(../img/btn_icon_report.png) no-repeat;
    background-size: cover;
}

.btn-icon-preview {
    background: url(../img/btn_icon_preview.png) no-repeat;
    background-size: cover;
}

.btn-icon-result {
    background: url(../img/btn_icon_result.png) no-repeat;
    background-size: cover;
}

.btn-icon-back {
    background: url(../img/btn_icon_back.png) no-repeat;
    background-size: cover;
}

.btn-icon-save {
    background: url(../img/btn_icon_save.png) no-repeat;
    background-size: cover;
}

.btn-icon-pause {
    background: url(../img/btn_icon_pause.png) no-repeat;
    background-size: cover;
}

.btn-icon:hover {
    font-size: 1em;
    letter-spacing: -1px;
    color: #999;
}

.btn-icon:hover.circle-btn-primary {
    color: #fff;
}

.btn-icon:hover i {
    opacity: 0;
}

.label-angel {
    position: relative;
    display: inline-block;
    margin-right: .25em;
    padding: 4px 20px 4px 12px;
    font-size: .875em;
    color: rgba(255, 255, 255, .8);
    vertical-align: middle;
    border-radius: 20px;
    background: #333;
}

.label-angel:after {
    position: absolute;
    content: '';
    top: 50%;
    right: .4em;
    margin-top: -.25em;
    background-color: #fff !important;
    width: .5em;
    height: .5em;
    -webkit-box-shadow: 0 -1px 1px 0 rgba(0, 0, 0, .3);
    box-shadow: 0 -1px 1px 0 rgba(0, 0, 0, .3);
    border-radius: 500rem;
}

.label-blue {
    background: #005891;
}

.label-lightblue {
    background: #15aad5;
}

.label-red {
    background: #dd4b39;
}

.label-orange {
    background: #f2711c;
}

.label-grey {
    background: #999;
}

.label-point {
    display: inline-block;
    margin-right: .25em;
    padding: 4px 10px;
    vertical-align: middle;
}

.label-point span {
    margin-right: 5px !important;
}

.btn-choice {}

.btn-choice.ui.basic.buttons .active.button {
    background: #1678c2 !important;
    color: #fff !important;
}

.btn-choice.ui.basic.buttons .active.button i {
    color: #fff !important;
}

.btn-choice input[type=radio] {
    display: none;
}

.btn-choice .ui.button {
    min-width: 80px;
}

.btn-choice .ui.button span {
    line-height: inherit;
}

.btn-choice .ui.button span i {
    margin-right: 5px !important;
    font-size: .75em;
    vertical-align: top;
}

/********************************************************************************************************
 * 게시판
*********************************************************************************************************/



/* tbl */
.tbl {
    width: 100%;
    border-top: 1px solid #d9e4eb;
    border-bottom: 1px solid #d9e4eb;
    font-size: 0.93em !important;
}

.tbl > thead > tr.odd-row {
    background: #f9f9f9;
}

.tbl.col-tl > tbody > tr > td {
    text-align: left !important;
}

.tbl.line-sm > tbody > tr > td {
    padding: 8px 5px;
}

.tbl > thead > tr > th, .tbl_list > thead > tr > th {
    padding: .675em 1em;
    text-align: center;
    color: #475a81;
    border-left: 1px solid #e1e4e8;
    border-bottom: 1px solid #e1e4e8;
    background: #f7f9fa;
}



.tbl > tfoot > tr > th {
    padding: .675em 1em;
    text-align: center;
    color: #f1f1f1;
    border-left: 1px solid #e1e4e8;
    border-bottom: 1px solid #e1e4e8;
    background: #666;
}

.tbl > tbody > tr > th {
    padding: .675em 1em;
    border-bottom: 1px solid #e1e4e8;
}

.tbl > tbody > tr > td {
    padding: .675em 1em;
    min-height: 43px;
    text-align: center;
    border-left: 1px solid #e1e4e8;
    border-bottom: 1px solid #e1e4e8;
    word-wrap: break-word;
    word-break: break-all;
}

.tbl > tbody > tr > td.p-0 {
    padding: 0;
}

.tbl > thead > tr > th:first-child, .tbl > tfoot > tr > th:first-child, .tbl > tbody > tr > td:first-child {
    border-left: none;
}

.tbl > tbody > tr > td.head {
    padding-left: 10px;
    text-align: left;
    color: #183f91;
    border-left: none;
    border-bottom: 1px solid #98d0ef;
    border-right: 1px solid #98d0ef;
    background: #f5fafc;
}

.tbl tbody td ul.bullet-list li {
    padding: 5px 5px 5px 20px;
    text-align: left;
    background: url(../img/icon_dot.gif) 10px 14px no-repeat;
}

.tbl tbody td ul.device-list {
    text-align: left;
}

.tbl tbody td ul.device-list li {
    overflow: hidden;
}

.tbl tbody td ul.device-list li > span {
    display: inline-block;
    width: calc(100% - 160px);
    line-height: 36px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.tbl tbody td ul.device-list.type2 li > span {
    width: calc(100% - 580px);
}

.tbl tbody td ul.device-list.type2 li > strong {
    width: 160px;
    display: inline-block;
    padding-left: 40px;
    line-height: 36px;
    vertical-align: top;
}

.tbl tbody td ul.device-list.type2 li > .ui.input {
    vertical-align: top;
}

td.lg-section {
    padding-left: 50px !important;
    background: url(../img/comment_inner_arrow.gif) 20px 4px no-repeat;
}

td.md-section {
    padding-left: 60px !important;
    background: url(../img/icon_arrow.gif) 50px 50% no-repeat;
}

/*td.md-section {padding-left: 80px !important; background: url(../img/comment_inner_arrow.gif) 50px 4px no-repeat;}*/
td.sm-section {
    padding-left: 110px !important;
    background: url(../img/icon_arrow.gif) 100px 50% no-repeat;
}

/**** .tbl.tbl_flex ****/
.tbl.tbl_flex {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    border-top: 1px solid #9ea0a3;
    border-bottom: 1px solid #e1e4e8;
}
.tbl.tbl_flex li {
    width: 50%;
}
.tbl.tbl_flex > li:first-child {
    border-right: none;
}
.tbl.tbl_flex > li dt {
    text-align: center;
}
.tbl.tbl_flex li dl:first-child dt {
    background: #FFF6F6;
}
.tbl.tbl_flex > li dt,
.tbl.tbl_flex > li dd {
    border-bottom: 1px solid #e1e4e8;
    vertical-align: middle;
}
.tbl.tbl_flex > li dl:last-child dt,
.tbl.tbl_flex > li dl:last-child dd {
    border-bottom: none;
}
.tbl.tbl_flex > li dd p {
    font-size: 0.9em;
    display: flex;
    align-items: center;
}
.tbl.tbl_flex > li dd > p > input {
    width: auto !important;
    vertical-align: middle !important;
}
.tbl.tbl_flex > li dl dd .cancle_box {
    display: flex;
    justify-content: flex-end;
    margin-top: 5px;
}
.tbl.tbl_flex > li:nth-child(even) {
    border-left: 1px solid #dcdcdc;
}
.tbl > li {
    border-top: 1px solid #dcdcdc;
}

.tbl > li:first-child {
    border-top: none;
}

.tbl > li > dl {
    display: table;
    table-layout: fixed;
    width: 100%;
    min-height: 46px;
}

.tbl > li > dl > dt {
    display: table-cell;
    vertical-align: middle;
    width: 22%;
    padding: .5em 1em;
    font-weight: 500;
    color: #1a345e;
    background-color: #f7f9fa;
}


.tbl .dt_add .dt_tit,
.tbl .dt_add .dt_cont {
    width: 50%;
}

.tbl .dt_add .dt_cont p:first-child,
.tbl .dd_add p:first-child {
    padding-bottom: 0.675em;
}
.tbl .dt_add .dt_cont p:last-child,
.tbl .dd_add p:last-child {
    padding-top: 0.675em;
}
.tbl > li > dl > dt:nth-child(2),
.tbl > li > dl > dt:nth-child(3),
.tbl > li > dl > dt:nth-child(5) {
    border-left: 1px solid #dcdcdc;
}

.tbl > li > dl > dd {
    display: table-cell;
    vertical-align: middle;
    padding: .675em 1em;
    border-left: 1px solid #dcdcdc;
}

.tbl > li > dl > dd span.sec {
    margin-left: 5px;
}

.tbl > li > dl > dd .help {
    margin-top: 10px;
    color: #999;
}

.cont_edit {
    padding: 1em 0;
}

.tbl.soft-border {
    border-top: 1px solid #dcdcdc;
    border-bottom: 1px solid #dcdcdc;
}

/*.tbl .field {line-height: 38px;}*/
.tbl.dt-sm > li > dl > dt {
    width: 15%;
}

.tbl.dt-md > li > dl > dt {
    width: 25%;
}

.tbl.dt-lg > li > dl > dt {
    width: 30%;
}

.tbl .field {
    margin-bottom: 0 !important;
}

.tbl_tit {
    margin-bottom: 3px;
}


.tbl.row_t {
    display: flex;
    text-align: center;
}
.tbl.row_t > li {
    border-top: none;
}
.tbl.row_t > li > dl {
    display: block;
    table-layout: fixed;
    width: 100%;
    min-height: 46px;
}
.tbl.row_t > li > dl > dt {
    display: block;
    width: 100%;
    padding: .5em 1em;
    font-weight: 500;
    color: #1a345e;
    background-color: #f7f9fa;
    border-left: 1px solid #dcdcdc;
    border-bottom: 1px solid #dcdcdc;
}
.tbl.row_t > li:first-child > dl > dt {
    border-left: none;
}
.tbl.row_t > li > dl > dd {
    display: block;
    padding: .675em 1em;
    border-left: 1px solid #dcdcdc;
}
.tbl.row_t > li:first-child > dl > dd {
    border-left: none;
}


/* tbl 성과등록table 20230613 추가 */
.tbl tbody.data_list > tr {
    background-color: #d7d7d7;
}
.tbl tbody.sch_data > tr.head > th {
    padding: .675em 1em;
    text-align: center;
    color: #333;
    border-left: 1px solid #e1e4e8;
    border-bottom: 1px solid #e1e4e8;
    background: #fff;
}
.tbl tbody.sch_data > tr.head > th:first-child {
    border-left: none;
}

/* .tbl tbody.sch_data > tr > th, .tbl tbody.sch_data > tr > td {
    width: 10%;
} */


.ui.message .tbl {
    border-top: 1px solid #cce7ec;
    border-bottom: 1px solid #cce7ec;
}

.ui.message .tbl > thead > tr > th {
    color: #3d6f87;
    border-left: 1px solid #cce7ec;
    border-bottom: 1px solid #A9D5DE;
    background: rgba(169, 213, 222, .3);
}

.ui.message .tbl > thead > tr > th:first-child, .ui.message .tbl > tbody > tr > td:first-child {
    border-left: none;
}

.ui.message .tbl > tbody > tr > td {
    border-left: 1px solid #cce7ec;
    border-bottom: 1px solid #cce7ec;
}

.tbl-simple {
    width: 100%;
}

.tbl-simple > li {
    padding-top: 1em;
}

.tbl-simple > li:first-child {
    padding-top: 0;
}

.tbl-simple > li > dl {
    display: table;
    table-layout: fixed;
    width: 100%;
    min-height: 40px;
}

.tbl-simple > li > dl > dt {
    display: table-cell;
    vertical-align: middle;
    width: 23%;
    color: #888;
    padding-right: 10px;
    font-size: 1em;
}

.tbl-simple > li > dl > dd {
    display: table-cell;
    vertical-align: middle;
}
.tbl-simple > li > dl > dd.w_full {
    display: table-row;
}

.tbl-simple > li > dl > dd span.sec {
    margin-left: 5px;
}

.tbl-simple > li > dl > dd .help {
    margin-top: 10px;
    color: #999;
}
.tbl-simple > li > dl.row > dt {
    padding: 0.5em 0;
}
.tbl-simple > li > dl.row > dt,
.tbl-simple > li > dl.row > dd {
    display: block;
    width: 100%;
}

.tbl-simple.dt-sm > li > dl > dt {
    width: 15%;
}

.tbl-simple.dt-md > li > dl > dt {
    width: 25%;
}

.tbl-simple.dt-lg > li > dl > dt {
    width: 30%;
}

/* tbl_simple 추가 */
.tbl-simple > li > dl > dd .tbl_cont_row {
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.req:after {
    margin: -0.2em 0em 0em 0.2em;
    content: '*';
    color: #f44336;
    display: inline-block;
    vertical-align: middle;
    top: 0%;
    left: 100%;
}

.ui.form .inline.fields.mo-flex1-row .field:last-child {
    flex: 1;
}

.toggle-board, .toggle-gallery {
    padding-bottom: 20px;
}

.post_list {
    margin-bottom: 20px;
}

.post_list > ul {
    border-top: 1px solid #323e51;
}

.post_list > ul > li {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    position: relative;
    padding: 15px;
    border-bottom: 1px solid #dcdcdc;
}

.post_list > ul > li.reply a {
    padding-left: 30px;
    background: url(../img/comment_inner_arrow.gif) 0 -5px no-repeat;
}

.post_list > ul > li > a {
    display: inline-flex;
    align-items: center;
    width: calc(100% - 400px);
    /* font-size: 1.125em; */
}

/* 220214수정 */
.main_content .post_list > ul > li > a {
    display: inline-flex;
    align-items: center;
    /* width: calc(100% - 160px); */
}

.post_list > ul > li > a > span {
    margin-right: 5px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.post_list > ul.thumb > li > a {
    flex: 1;
}

.post_list > ul.thumb > li.point {
    background: #e6f0f7;
}

.post_list > ul.thumb > li.point:after {
    position: absolute;
    top: calc(50% - 7px);
    right: .5em;
    content: '\f125';
    font-family: "Ionicons";
}

.post_list > ul > li.notice {
    background: #f7f9fa;
}

.post_list > ul > li.notice .post_label strong {
    margin-right: 7px;
    color: #333;
}

.post_list > ul > li.notice .post_label strong:first-child {
    color: #db2828;
}

.post_list > ul > li.notice > a {
    color: #333;
}

.post_list .post_label {
    margin-right: 10px;
    color: #000;
}

.post_list .post_label:nth-child(1) {
    margin-right: 10px;
}

.post_list .post_label span {
    display: inline-block;
    min-width: 25px;
}

.post_list .post_label strong {
    font-weight: 700;
}

.post_list .post_label em {
    padding-left: 10px;
    font-style: normal;
}

.post_list .post_label em:before {
    content: "";
    display: inline-block;
    width: 1px;
    height: 12px;
    margin-right: 10px;
    background-color: #c3c3c3;
}

.post_list .post_label em:first-child {
    padding-left: 0;
}

.post_list .post_label em:first-child:before {
    display: none;
}

.post_list .post_disc:after {
    content: "";
    display: table;
    clear: both;
}

.post_list .post_disc {
    margin-left: auto;
    color: #868889;
}

.post_list .post_disc li {
    position: relative;
    float: left;
    padding-left: 10px;
}

.post_list .post_disc li:before {
    content: "";
    display: inline-block;
    width: 1px;
    height: 13px;
    margin-right: 10px;
    background-color: #c3c3c3;
}

.post_list .post_disc li:first-child:before {
    display: none;
}

/*** cheathing_notify_board.jsp cheat_list 210811 ***/
.post_list.cheat_list > ul > li > a {
    display: inline-flex;
    align-items: center;
    width: 70%;
    font-size: 1.125em;
}

.post_list.cheat_list > ul > li {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    border-bottom: 1px solid #dcdcdc;
}

.post_list.cheat_list .post_label span {
    display: inline-block;
    min-width: 0px;
}

.post_list.cheat_list > ul.thumb > li > a {
    flex: 1;
}

.post_list.cheat_list > ul.thumb > li.point {
    background: #e6f0f7;
}

.post_list.cheat_list > ul.thumb > li.point:after {
    position: absolute;
    top: calc(50% - 7px);
    right: .5em;
    content: '\f125';
    font-family: "Ionicons";
}

.post_view {
    border-top: 1px solid #323e51;
    box-sizing: border-box;
}

.post_view .header {
    border-bottom: 1px solid #dcdcdc;
}

.post_view .header .inner-box {
    display: flex;
    align-items: center;
    padding: 10px;
    background: #f7f9fa;
}

.post_view .header .inner-box h4 {
    font-size: 1.25em;
    color: #333;
}

.post_view .header .inner-box h4 label:last-child {
    margin-right: 10px;
}

.path-btn {
    display: inline-flex;
    align-items: center;
    margin-left: auto;
}

.path-btn label {
    margin-left: 5px;
    color: #333;
}

.path-btn .hit {
    display: flex;
    align-items: center;
    margin-left: 15px;
}

.path-btn .hit i {
    font-size: 20px;
}

.path-btn .switch_fa {
    position: relative;
    cursor: pointer;
    display: inline-block;
    overflow: hidden;
    width: 20px;
    height: 20px;
    vertical-align: middle;
}

.path-btn .switch_fa .background {
    background: url(../img/switch-fa-background.png);
    background-size: contain;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 40px;
    height: 20px;
}

.path-btn .switch_fa .mask {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
}

.path-btn .switch_cms {
    position: relative;
    cursor: pointer;
    display: inline-block;
    overflow: hidden;
    width: 20px;
    height: 20px;
    vertical-align: middle;
}

.path-btn .switch_cms .background {
    background: url(../img/switch-fa-background.png);
    background-size: contain;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 40px;
    height: 20px;
}

.path-btn .switch_cms .mask {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
}

.path-btn .sns {
    position: relative;
    margin-left: 15px;
}

.path-btn .sns button {
    display: flex;
    align-items: center;
    background: transparent;
}

.path-btn .sns button i {
    font-size: 20px;
}

.path-btn .sns button label {
    cursor: pointer;
}

.path-btn .sns ul.sns-box {
    display: none;
    position: absolute;
    left: 50%;
    margin-top: 5px;
    padding: 10px;
    border-radius: 3px;
    box-shadow: 0px 1px 3px 0px #D4D4D5, 0px 0px 0px 1px #D4D4D5;
    background: #fff;
    transform: translateX(-50%);
    z-index: 1;
}

.path-btn .sns ul.sns-box.active {
    display: block;
}

.path-btn .sns ul.sns-box li {
    margin-top: 10px;
    cursor: pointer;
}

.path-btn .sns ul.sns-box li:first-child {
    margin-top: 0;
}

.path-btn .sns ul.sns-box li a {
    display: block;
    width: 36px;
    height: 36px;
    text-indent: -9999px;
    border-radius: 100%;
    overflow: hidden;
}

.path-btn .sns ul.sns-box li.k a {
    background: url(../img/sns_k.png) no-repeat;
    background-size: cover;
}

.path-btn .sns ul.sns-box li.b a {
    background: url(../img/sns_b.png) no-repeat;
    background-size: cover;
}

.path-btn .sns ul.sns-box li.f a {
    background: url(../img/sns_f.png) no-repeat;
    background-size: cover;
}

.path-btn .sns ul.sns-box li.t a {
    background: url(../img/sns_t.png) no-repeat;
    background-size: cover;
}

.path-btn .sns ul.sns-box li.c a {
    background: url(../img/url_copy.gif) no-repeat;
    background-size: cover;
}

.post_view .header ul.viewInfo {
    padding: 10px;
    border-top: 1px solid #dcdcdc;
    overflow: hidden;
}

.post_view .header ul.viewInfo li {
    display: inline-block;
    padding-left: 10px;
}

.post_view .header ul.viewInfo li:before {
    content: "";
    display: inline-block;
    width: 1px;
    height: 12px;
    margin-right: 10px;
    vertical-align: middle;
    background-color: #c3c3c3;
}

.post_view .header ul.viewInfo li:first-child {
    padding-left: 0;
}

.post_view .header ul.viewInfo li:first-child:before {
    display: none;
}

.post_view .header ul.viewInfo li button {
    color: #acacac;
}

.post_view .header ul.viewInfo li span {
    margin-right: 10px;
    color: #acacac;
}

.post_view .content-field {
    min-height: 280px;
    padding: 20px 10px;
}

.post_view .footer {}

.post_view .footer dl.post_link {
    overflow: hidden;
    border-bottom: 1px solid #dcdcdc;
}

.post_view .footer dl.post_link dt {
    float: left;
    width: 20%;
    padding: 15px 10px;
    text-align: center;
    color: #333;
    border-top: 1px solid #dcdcdc;
    background: #f8f8f8;
}

.post_view .footer dl.post_link dd {
    float: left;
    width: 80%;
    padding: 15px 10px;
    border-top: 1px solid #dcdcdc;
}

.post_view .footer dl.post_link dd a {
    display: block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

/* comment-form 요소 */
.comment .title {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.comment .title h2 {
    flex: auto;
    padding-bottom: 0;
}

.comment .title h2 i {
    margin-left: 5px;
}

.comment .title .toggle_view {
    font-size: .875em;
}

.comment h2 {
    padding-bottom: 10px;
    font-size: 1.125em;
    color: #333;
}

.comment h2 span {
    margin-left: 20px;
    font-size: .75em;
    color: #ff6c00;
}

ul.comment-write {
    padding: 10px 0;
    overflow: hidden;
}

ul.comment-write > li {
    float: left;
    width: 100%;
}

ul.comment-write > li:last-child {
    margin-top: .5em;
    text-align: right;
}

ul.comment-write > li:last-child a {
    min-width: 80px;
}

ul.comment-write > li.mbtn {
    display: none;
}

ul.comment-write > li.imgBox {
    position: relative;
    width: 70px;
    height: 70px;
    margin-right: 20px;
}

ul.comment-write > li > ul {}

ul.comment-write > li > ul > li {
    margin-top: 5px;
    font-size: 13px;
}

.comment .article {
    display: none;
}

.comment .article > ul {
    border-bottom: 1px dotted #d7d7d7;
    overflow: hidden;
}

.comment .article > ul:last-child {
    border-bottom: none;
}

.comment .article > ul.co_inner {
    padding-left: 50px;
    background: url(../img/comment_inner_arrow.gif) 20px 12px no-repeat;
}

.comment .article > ul > li {
    float: left;
    width: calc(100% - 60px);
    padding: 10px 0;
}

.comment .article > ul > li span {
    margin-right: 10px;
    padding: 2px 6px;
    color: #fff;
    font-size: .875em;
    text-align: center;
    border-radius: 12px;
    background: #5099b8;
}

.comment .article > ul > li.mbtn {
    display: none;
}

.comment .article > ul > li.imgBox {
    position: relative;
    width: auto;
    margin: 0 10px 0 0;
}

.comment .article > ul > li > ul {}

.comment .article > ul > li > ul > li em {
    display: inline-block;
    margin-top: 10px;
    padding-left: 10px;
    font-style: normal;
    color: #999;
}

.comment .article > ul > li > ul > li em:before {
    content: "";
    display: inline-block;
    width: 1px;
    height: 12px;
    margin-right: 10px;
    vertical-align: middle;
    background-color: #c3c3c3;
}

.comment .article > ul > li > ul > li em:first-child {
    padding-left: 0;
}

.comment .article > ul > li > ul > li em:first-child:before {
    display: none;
}

.comment .article > ul > li > ul > li em code {
    background-color: transparent;
    color: #999;
}

.comment .article > ul > li > ul > li em.toggle_btn,
.comment .article > ul > li > ul > li em a {
    color: #333;
}

.post_album {
    margin-bottom: 20px;
}

.post_album .view-list-img {
    display: block;
    position: relative;
    padding-top: 60%;
    background: #e2e2e2;
    overflow: hidden;
}

.post_album .view-list-img img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.post_album .title-box {
    display: block;
    font-size: 1.125em;
    font-weight: 500;
    color: #333;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.post_album .post_disc {
    float: right;
}

.post_album .post_disc li {
    position: relative;
    float: left;
    padding-left: 10px;
    color: #acacac;
}

.post_album .post_disc li:before {
    content: "";
    display: inline-block;
    width: 1px;
    height: 13px;
    margin-right: 10px;
    background-color: #c3c3c3;
}

.post_album .post_disc li:first-child {
    padding-left: 0;
}

.post_album .post_disc li:first-child:before {
    display: none;
}

.post_blog {
    margin-bottom: 20px;
}

.post_blog > ul {
    border-top: 1px solid #323e51;
}

.post_blog > ul > li {
    display: flex;
    padding: 10px;
    border-bottom: 1px solid #dcdcdc;
}

.post_blog > ul > li > p.view-list-img {
    width: 260px;
    margin-right: 20px;
}

.post_blog > ul > li > p.view-list-img a {
    display: block;
    position: relative;
    padding-top: 70%;
    border: 1px solid #ccc;
    background: #e2e2e2;
}

.post_blog > ul > li > p.view-list-img a img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.post_blog > ul > li > .content_txt {
    flex: 1;
    min-width: 0;
}

.post_blog > ul > li > .content_txt > a {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.post_blog > ul > li > .content_txt > a span {
    font-size: 1.5em;
    font-weight: 500;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.post_blog > ul > li.notice {
    background: #f7f9fa;
}

.post_blog > ul > li.notice > .content_txt > a {
    color: #333;
}

.post_blog > ul > li > .content_txt > .post_disc:after {
    content: "";
    display: table;
    clear: both;
}

.post_blog > ul > li > .content_txt > .post_disc {
    float: right;
    color: #acacac;
}

.post_blog > ul > li > .content_txt > .post_disc li {
    float: left;
    padding-left: 10px;
}

.post_blog > ul > li > .content_txt > .post_disc li:before {
    content: "";
    display: inline-block;
    width: 1px;
    height: 13px;
    margin-right: 10px;
    background-color: #c3c3c3;
}

.post_blog > ul > li > .content_txt > .post_disc li:first-child:before {
    display: none;
}

.post_blog > ul > li > .content_txt > p > a {
    max-height: 7em;
    margin-bottom: 10px;
    line-height: 1.4em;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
}

.question-list {
    margin-bottom: 20px;
}

.question-list .comment-write {
    padding: 0;
}

.question-list > ul > li {
    border: 1px solid #fff;
    border-bottom: 1px solid #d7d7d7;
    box-sizing: border-box;
    transition: all 0.2s ease;
}

.question-list > ul > li:first-child {
    border-top-color: #6b6b6b;
}

/*.question-list > ul > li:last-child {border-bottom-color: #6b6b6b;}*/
.question-list > ul > li.on {
    margin-top: -1px;
    border-color: #ff8601;
}

.question-list > ul > li.on dl dt {
    background: url(../img/faq_dot02.png) 5px 50% no-repeat, url(../img/faq_dot_on.png) calc(100% - 5px) 50% no-repeat;
}

.question-list dl {}

.question-list dl dt {
    display: flex;
    align-items: center;
    padding: 1em 2em 1em 3.5em;
    background: url(../img/faq_dot02.png) 5px 50% no-repeat, url(../img/faq_dot.png) calc(100% - 5px) 50% no-repeat;
    cursor: pointer;
}

.question-list dl dt a {
    flex: 1;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 1.067em;
    vertical-align: middle;
}

.question-list dl dd {
    display: none;
    padding: 1.125em 0;
}

.question-list dl dd .post_disc {
    margin: 0 1em;
    color: #acacac;
    vertical-align: middle;
    /* overflow: hidden; */
}

.question-list dl dd .post_disc li {
    position: relative;
    float: left;
    padding-left: 10px;
}

.question-list dl dd .post_disc li:first-child {
    padding-left: 0;
}

.question-list dl dd .post_disc li:before {
    content: "";
    display: inline-block;
    width: 1px;
    height: 13px;
    margin-right: 10px;
    vertical-align: middle;
    background-color: #c3c3c3;
}

.question-list dl dd .post_disc li:first-child:before {
    display: none;
}

.question-list dl dd .content {
    margin: 3em 1em 0 1em;
}

.question-list dl dd .answer {
    display: flex;
    flex-direction: column;
    margin-top: 1.125em;
    padding: 1.125em 1.125em 0 3em;
    font-size: 1em;
    line-height: 26px;
    background: url(../img/faq_dot03.png) 5px 16px no-repeat;
    color: #777;
    border-top: 1px solid #ddd;
}

.question-list dl dd .answer .post_disc {
    margin: 0;
}

/*** .question-list.faq-type ***/
.question-list.faq-type > ul > li.on {
    border: none;
}

.question-list.faq-type > ul > li.on dl dt {
    background: #f6f6f6;
    border-bottom: 1px solid #ddd;
}

.question-list.faq-type > ul > li.on dl dt:before {
    color: #33a0d8;
    background: #fff;
}

.question-list.faq-type > ul > li.on dl dt:after {
    content: '\f3d0';
    color: #333;
}

.question-list.faq-type > ul > li.on dl dt a {
    color: #222;
}

.question-list.faq-type dl dt {
    position: relative;
    background: none;
}

.question-list.faq-type dl dt:before {
    content: 'Q';
    position: absolute;
    top: 50%;
    left: 10px;
    width: 30px;
    height: 30px;
    line-height: 26px;
    font-size: 1.067em;
    font-weight: 700;
    color: #fff;
    text-align: center;
    transform: translateY(-50%);
    border-radius: 20px;
    background: #33a0d8;
    border: 1px solid #33a0d8;
}

.question-list.faq-type dl dt:after {
    content: '\f3d2';
    position: absolute;
    top: 50%;
    right: 10px;
    font-family: "Ionicons";
    font-size: 1.25em;
    transform: translateY(-50%);
}

.question-list.faq-type dl dd {
    position: relative;
    padding: 2em 2em 2em 3.5em;
    border-bottom: 1px solid #d7d7d7;
    background: #f6f6f6;
}

.question-list.faq-type {
    border-top: 1px solid #d7d7d7;
}
.question-list.faq-type > ul > li.on {
    margin-top: 0;
}
.question-list.faq-type > ul > li {
    border: none;
    border-bottom: 1px solid #d7d7d7;
}

.grid-table {
    width: 100%;
    border-top: 1px solid #d9e4eb;
}

.grid-table th {
    padding: 10px 5px;
    line-height: 120%;
    text-align: center;
    color: #333;
    word-break: break-all;
    border-left: 1px solid #d9e4eb;
    border-bottom: 1px solid #d9e4eb;
    background: #f5f9fb;
}

.grid-table td {
    padding: 10px 5px;
    text-align: center;
    border-left: 1px solid #dfdfdf;
    border-bottom: 1px solid #dfdfdf;
    word-wrap: break-word;
    word-break: break-all;
}

.grid-table td p.cell {
    display: table;
    min-height: 30px;
}

.grid-table td p.cell span {
    display: table-cell;
    vertical-align: middle;
}

.grid-table td p.elt {
    padding: 4px 0;
    text-align: left;
}

.grid-table td input[type="radio"] {
    width: 18px;
    height: 18px;
}

.grid-table .head {
    padding-left: 10px;
    text-align: left;
    border-left: none;
}
.grid-table .head.header_cnt {
    padding-left: 10px;
    text-align: center;
    border-left: none;
}

.grid-table tr.mo {
    display: none;
}

.wrap_btn {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 1em 2em;
    text-align: right;
    background: rgba(0, 0, 0, .7);
    z-index: 998;
}

.wrap_btn:after {
    content: "";
    display: table;
    clear: both;
}

.wrap_btn.btn_fixed {
    position: fixed;
    bottom: 0;
}

table.footable ul.list-align li {
    padding: 3px 0;
}

table.footable ul.list-align li .ui.label > .icon {
    margin-right: 2px;
}

.tooltip {
    position: relative;
    padding-right: 24px;
    vertical-align: middle;
}

.tooltip:after {
    position: absolute;
    right: 0;
    top: 50%;
    width: 18px;
    height: 18px;
    margin-top: -9px;
    content: "";
    cursor: help;
    background: url(../img/tooltip_icon.png) no-repeat;
    background-size: cover;
}

.time-sort {
    line-height: 2.5em;
}

.ui.form .field ~ .time-sort {
    padding-right: 1em;
}

.active-btn,
.toggle-btn {
    cursor: pointer;
}

.active-btn.select,
.toggle-btn.select {
    border-left-color: #d01919;
    background: rgba(235, 235, 235, .7);
}

.toggle-btn input[type=checkbox] {
    display: none;
}

.chk-box.on {
    border-left-color: #d01919;
    background: rgba(235, 235, 235, .7);
}

.classin {
    position: absolute !important;
    top: 7px;
    right: 0;
    width: 60px;
}

.ui.course-type .card .content {
    border-top: none !important;
}

.ui.course-type .card .content:first-child {
    padding: 2.5em 1em 0 1em !important;
}

.ui.course-type .card .content:last-child {
    padding: .5em 1em !important;
    border-top: 1px solid rgba(34, 36, 38, 0.1) !important;
    background: #f9f9f9;
    margin-top: auto;
}

.ui.course-type .card .content .day {
    position: absolute;
    top: 0;
    left: 1em;
    width: 60px;
    font-weight: 500;
    color: #222;
    line-height: 24px;
    text-align: center;
    border: 1px solid #d7d7d7;
    border-top: none;
    border-radius: 0 0 4px 4px;
}

.ui.course-type .card .content .coss-tag {
    position: absolute;
    top: 0;
    left: 5.5em;
    padding: 0px 5px;
    font-weight: 500;
    color: #222;
    line-height: 24px;
    text-align: center;
    border: 1px solid #d7d7d7;
    border-top: none;
    border-radius: 0 0 4px 4px;
}

.ui.course-type .card .content .day.range {
    color: #fff;
    border-color: #2185d0;
    background: #2185d0;
}

.ui.course-type .card .content .day.past {
    color: #fff;
    border-color: #767676;
    background: #767676;
}

.ui.course-type .card .content .initial-img {
    font-size: .875em;
}

/* .ui.course-type .card .title-box {
    flex: 1;
} */

.ui.course-type .card .title-box .description {
    color: #9dabbb;
    margin-right: 10px;
}

.ui.course-type .card .info-sec {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
.ui.course-type .card .info-sec > label {
    margin-right: 15px;
}
.ui.course-type .card .info-sec .tag-group {
    flex: 1;
    font-size: .938em;
}

.ui.course-type .card .info-sec .tag-group button {
    margin-right: 5px;
    font-size: 0.93em;
}

.ui.course-type .card .info-sec .tag-group button:last-child {
    margin-right: 0;
}

/*.ui.course-type .card .content:last-child.info-sec label {font-size: .875em;}*/
.ui.course-type .card .info-sec .bottom-label {
    /* margin-left: auto; */
    text-align: right;
    color: #666;
    display: flex;
    align-items: center;
}

.ui.course-type .card .info-sec .bottom-label span {
    display: block;
}

.ui.course-type .card .info-sec .bottom-label .submit-btn {
    display: inline-block;
    padding: .3em .5em;
    color: #fff;
    text-align: center;
    border-radius: 3px;
}

/* CI Card CSS - 210705 */
.ui.ci-type .card .bar {
    position: absolute;
    width: 5px;
    height: 100%;
    border-top-left-radius: 10px !important;
    border-bottom-left-radius: 10px !important;
}

.ui.ci-type .arrow-step {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    font-size: 3em;
    color: #999;
}

.ui.ci-type .arrow-step:before {
    content: '\f109';
    font-family: 'Ionicons';
}

.ui.ci-type .card .content {
    border-top: none !important;
}

.ui.ci-type .card .content .initial-img {
    font-size: .875em;
}

.ui.ci-type .card .title-box {
    flex: 1;
}

.ui.ci-type .card ul.category {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;
}

.ui.ci-type .card ul.category li {
    flex: 1 1 auto;
}

.ui.ci-type .card .content.bottom-button {
    display: flex;
    flex-direction: row;
    padding: 0 !important;
    border-top: 1px solid rgba(34, 36, 38, 0.1) !important;
}

.ui.ci-type .card .content.bottom-button button {
    display: block;
    padding: .75em 1em;
    flex: 1;
    flex-basis: auto;
    font-size: 1em;
    color: #039be5;
    text-align: center;
    border-right: 1px solid rgba(34, 36, 38, 0.1);
    background: transparent;
}

.ui.ci-type .card .content.bottom-button button:last-child {
    border-right: none !important;
}

.ui.ci-type .card .content.bottom-button button.disabled {
    color: #ccc;
    cursor: auto;
}

.ui.ci-type .card .content.bottom-button i {
    margin: 0;
}

.ui.ci-type .card .content.bottom-button .fn-normal {
    flex: none;
}

.ui.ci-type .card .content.bottom-button .fn-red {
    flex: none;
    color: #f1584c;
}

.ui.info-type .card {
    width: 100%;
}

.ui.info-type .card:hover,
.ui.info-type .card:focus {
    -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, .4) !important;
    -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, .4) !important;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, .4) !important;
}

.ui.info-type .card .content:first-child {
    padding: 0.7em 1em !important;
    background: #f9f9f9;
}

.ui.info-type .card .content .field {
    margin: -.5em 0;
    font-size: .937em;
}

.ui.info-type .card .content .field.no_fm {
    margin: 2px 0 !important;
    font-size: .937em;
}

.ui.info-type .card .content > .ui.ribbon.label {
    vertical-align: top;
}

.ui.info-type .card .content.bottom-button {
    display: flex;
    flex-direction: row;
    padding: 0 !important;
}

.ui.info-type .card .content.bottom-button button {
    display: block;
    padding: .75em 1em;
    flex: 1;
    flex-basis: auto;
    font-size: 1em;
    font-weight: 500;
    color: #039be5;
    text-align: center;
    border-right: 1px solid rgba(34, 36, 38, 0.1);
    background: transparent;
}

.ui.info-type .card .content.bottom-button button:last-child {
    border-right: none !important;
}

.ui.info-type .card .content.bottom-button button.disabled {
    color: #ccc;
    cursor: auto;
}

.ui.info-type .card .content.bottom-button i {
    margin: 0;
}

.ui.info-type .card .content.bottom-button .fn-normal {
    flex: none;
}

.ui.info-type .card .content.bottom-button .fn-red {
    flex: none;
    color: #f1584c;
}

.ui.info-type .card .ui.progress.attached {
    position: relative;
    border-radius: 0;
    overflow: inherit;
}

.ui.info-type .card .ui.progress.attached .bar {
    background-color: #2185D0 !important;
}

.ui.info-type .card .ui.progress.attached .label {
    width: auto;
    top: -1.75em;
    left: auto;
    right: 1em;
    font-size: .875em;
    font-weight: 500;
    color: #222 !important;
}

.ui.info-type .card.fab-field {
    display: flex !important;
    align-items: center;
    justify-content: center;
    padding: 0 1em !important;
    color: #999;
    border: 2px dashed #ccc;
    box-shadow: none;
    background: rgba(0, 0, 0, .05);
    height: 100%;
    min-height: 215px;
}

.ui.info-type .card.fab-field > i {
    font-size: 2.5em;
}

.ui.info-type .card.fab-field h3 {
    font-size: 1.25em;
}

.ui.info-type .card.fab-field .menu {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 20px;
}

.ui.info-type .card.fab-field .menu a {
    flex: 1;
    flex-basis: calc(33.33% - 4px);
    margin: 2px;
    padding: 10px;
    color: #000;
    text-align: center;
    border: 1px solid #d7d7d7;
    border-radius: 2px;
    background: rgba(255, 255, 255, .8);
}

.ui.info-type .card.fab-field .menu a:hover {
    background: rgba(255, 255, 255, 1);
}

.ui.info-type .card.fab-field .menu a i {
    margin-right: 5px;
    font-size: 1.25em;
    vertical-align: middle;
}

.ui.info-type .card.fab-field.type-red,
.ui.info-type .card.fab-field.type-red:hover {
    border-color: #DB2828;
}

.ui.info-type .card.fab-field.type-red i {
    color: #DB2828;
}

.ui.info-type .card.fab-field.type-orange,
.ui.info-type .card.fab-field.type-orange:hover {
    border-color: #F2711C;
}

.ui.info-type .card.fab-field.type-orange i {
    color: #F2711C;
}

.ui.info-type .card.fab-field.type-purple,
.ui.info-type .card.fab-field.type-purple:hover {
    border-color: #A333C8;
}

.ui.info-type .card.fab-field.type-purple i {
    color: #A333C8;
}

.ui.info-type .card.fab-field.type-violet,
.ui.info-type .card.fab-field.type-violet:hover {
    border-color: #6435C9;
}

.ui.info-type .card.fab-field.type-violet i {
    color: #6435C9;
}

.ui.info-type .card.fab-field.type-green,
.ui.info-type .card.fab-field.type-green:hover {
    border-color: #21BA45;
}

.ui.info-type .card.fab-field.type-green i {
    color: #21BA45;
}

.ui.info-type .card.fab-field.type-yellow,
.ui.info-type .card.fab-field.type-yellow:hover {
    border-color: #FBBD08;
}

.ui.info-type .card.fab-field.type-yellow i {
    color: #FBBD08;
}

.ui.header-ellipsis .card .header:not(.ui) {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.info-type-list {
    display: flex;
    margin-bottom: 1em;
    border-radius: 0.28571429rem;
    box-shadow: 0px 1px 3px 0px #d4d4d5, 0px 0px 0px 1px #d4d4d5;
    background: #fff;
    overflow: hidden;
}

.info-type-list .title-cover {
    position: relative;
    width: 280px;
}

.info-type-list .title-cover .over-btn {
    display: none;
    position: absolute;
    top: 5px;
    right: 5px;
    padding: 10px 20px;
    color: #fff;
    background: rgba(0, 0, 0, .7);
    z-index: 1;
}

.info-type-list .title-cover:hover .over-btn {
    display: block;
}

.info-type-list .title-cover .over-doctype {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 10px 20px;
    font-weight: 700;
    color: #fff;
    background: rgba(0, 0, 0, .2);
    z-index: 1;
}

.info-type-list .title-cover .over-doctype i {
    margin: 0;
    font-size: 3em;
    line-height: 100%;
}

.info-type-list .info-sec {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1 1 60%;
    min-width: 60%;
    margin: 0.5em 1em;
}

.info-type-list .info-sec .header {
    display: block;
    font-size: 1.25em;
    font-weight: 500;
    color: #222;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.info-type-list .info-sec .extra {
    margin: 5px 0;
}

/* .info-type-list .info-sec .lebels {flex-direction: row;} */
.info-type-list .info-sec .extra span {
    margin-right: 40px;
}

/* 210715 추가*/
.info-type-list .info-sec .label_box {
    margin-top: 5px;
}

.info-type-list .info-sec .label_box a {
    color: #686868;
}

.info-type-list .info-sec .label_box label:last-of-type {
    margin-right: 20px;
}

/** /210715 추가**/

.info-type-list .info-sec label {
    font-size: .75em;
    font-weight: 700;
}

.info-type-list .right-btn {
    width: 100px;
    font-size: 1em;
    font-weight: 500;
    color: #222;
    background: #fff;
}

.info-type-list .right-btn i {
    display: block;
    font-size: 2em;
}

.ui.grid-list .ui.card {
    box-shadow: none;
}

.ui.grid-list .ui.card .card-item-center label {
    margin-left: auto;
    font-weight: 500;
    color: #0086ec;
}

.ui.grid-list .ui.card .bottom-button {
    border-radius: 0 !important;
}

.ui.grid-list .ui.card .bottom-button button {
    width: 100%;
    padding: 10px 0;
    text-align: center;
    font-size: 1.063em;
    font-weight: 500;
    border: 1px solid #222;
}

.ui.grid-list .ui.card .bottom-button button.disabled {
    color: #ccc;
    border: 1px solid #ccc;
    cursor: auto;
}

aside.content-add-box {
    width: 200px;
    padding: 2em 2em 2em 0;
    margin-right: 2em;
    border-right: 1px solid #ebebeb;
    box-shadow: 3px 0 4px -4px #f1f1f1;
}

aside.content-add-box .ui.cards.info-type > .card.fab-field {
    margin-top: .2em;
    margin-bottom: .2em;
    padding: 1em !important;
}

aside.content-add-box .ui.cards.info-type > .card.fab-field > i {
    margin-bottom: 5px !important;
}

aside.content-add-box .ui.cards.info-type > .card.fab-field h3 {
    font-size: 1em;
}

aside.content-add-box .ui.cards.info-type > .card.active {
    background: #fff;
}

aside.content-add-box .ui.cards.info-type > .card:hover,
aside.content-add-box .ui.cards.info-type > .card:focus {
    box-shadow: none !important;
    background: #fff;
    transition: ease all .3s;
}

.content-add-box {
    box-shadow: none !important;
}

.content-add-box h1 {
    font-size: 2em;
    font-weight: 700;
    text-align: center;
}

.content-add-box .sub {
    font-size: 1.25em;
    margin: 1em 0 2em;
    text-align: center;
}
.content-add-box .ui.cards.info-type {
    height: 100%;
}

.content-add-box .ui.cards.info-type > .card.fab-field {
    padding: 2em !important;
}

.content-add-box .ui.cards.info-type > .card.fab-field h3 {
    padding-bottom: 0;
}

.content-add-box .ui.cards.info-type > .card.fab-field > i,
.content-add-box .ui.cards.info-type > .card.fab-field h3 {
    display: block;
}

.fix-box {
    height: 2.8em;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.fix-box + .more-btn {
    position: absolute;
    right: 1em;
    bottom: 1em;
    font-size: .875em;
    color: #999;
    text-decoration: underline;
}

.util-header a {
    margin-right: 10px;
    color: #97a6b7;
    vertical-align: middle;
}

.util-header a:last-child {
    margin-right: 0;
}

.util-header a.state {
    color: #1678c2;
}

.util-header a:before {
    content: "";
    display: inline-block;
    width: 1px;
    height: 12px;
    margin: 0 10px 0 0;
    background: #97a6b7;
}

.util-header a:first-child:before {
    display: none;
}

.sum-box {
    display: flex;
    align-items: center;
    padding: 0em 1em;
}

.sum-box:after {
    content: "";
    display: block;
    height: 0;
    clear: both;
}

.sum-box .process-bar {
    flex: 1;
    height: 26px !important;
    /* margin-right: 10px; */
    line-height: 26px !important;
}

.list-select a {
    display: block;
}

.list-select a.select {
    background: #1678c2 !important;
}

.list-select a.select .title-header section {
    color: #fff;
}

.list-select a.select .title-header .author {
    color: rgba(255, 255, 255, .7);
}

.list-select .title-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.list-select .title-header section {
    font-size: 1.125em;
    color: #333;
}

.list-select .title-header section span {
    display: inline-block;
    margin: 0 10px;
    font-size: .875em;
}

.list-select .title-header .author {
    display: flex;
    align-items: center;
    margin-top: 5px;
    color: #999;
}

.list-select .title-header .author ul.viewInfo li {
    display: inline-block;
    padding-left: 10px;
}

.list-select .title-header .author ul.viewInfo li:before {
    content: "";
    display: inline-block;
    width: 1px;
    height: 12px;
    margin-right: 10px;
    vertical-align: middle;
    background-color: #c3c3c3;
}

.list-select .title-header .author ul.viewInfo li:first-child {
    padding-left: 0;
}

.list-select .title-header .author ul.viewInfo li:first-child:before {
    display: none;
}

.list-select .title-header .author span {
    margin-left: auto;
}

.ratings-column {
    display: flex;
    flex-wrap: wrap;
}

.ratings-column .box {
    flex: 1;
    position: relative;
    padding: 5px;
    text-align: left;
}

.ratings-column .box:first-child {
    margin-left: 0;
}

.ratings-column .box:before {
    content: '';
    width: 1px;
    position: absolute;
    top: 0;
    right: 0;
    height: calc(100% + 1px);
    background: #d7d7d7;
}

.ratings-column .box:last-child:before {
    display: none;
}

.ratings-column .box .score {
    display: flex;
    align-items: center;
    font-weight: 700;
}

.ratings-column .box .score .ui.buttons {
    margin-left: auto;
    margin-right: 10px;
}

.ratings-column .box .description {
    font-size: .875em;
    color: #999;
}

.ratings-column .add-rating {
    position: relative;
    flex-basis: 20px;
    margin: -.75em 0 -.75em -10px;
    background: transparent;
}

/*.ratings-column .add-rating:before {position: absolute; top: 0; content: ''; width: 1px; height: 100%; background: #d7d7d7;}*/
.ratings-column .add-rating:after {
    position: absolute;
    top: calc(50% - 10px);
    left: calc(50% - 10px);
    content: '\f218';
    width: 20px;
    height: 20px;
    line-height: 20px;
    font-family: "Ionicons";
    color: #fff;
    border-radius: 100px;
    background: #1678c2;
}

.ratings-column a.box {}

.ratings-column a.box.select {
    box-shadow: 0 0 10px 0 rgba(34, 36, 38, .1) inset;
    background: #f9f9f9;
}

.disabled a.box {
    cursor: auto;
}

/*.disabled a.box.select .eval-num {display: flex; align-items: center; justify-content: flex-end; position: absolute; top: 0; bottom: 0; left: 0; right: 0; box-shadow: 0 0 10px 0 rgb(34 36 38 / 10%) inset; background: #f9f9f9; z-index: -1;}*/
.disabled a.box.select .eval-num span {
    position: absolute;
    top: 50%;
    right: 0;
    margin-right: 10px;
    font-size: 1.75em;
    font-weight: 700;
    color: rgba(0, 0, 0, .3);
    transform: translateY(-50%);
}

.ui.input.eval-input-box label {
    flex: 1 !important;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.ui.input.eval-input-box .ui.input {
    flex: none;
    width: 200px !important;
    margin-left: 10px !important;
}

.ui.input.eval-input-box .ui.input input {
    width: 100px !important;
    font-weight: 700;
}

.ui.icon.buttons .ui.button i:before,
.ui.icon.button i:before {
    min-width: 13px;
    margin: 0;
}


/********************************************************************************************************
 * slick-slider http://kenwheeler.github.io
*********************************************************************************************************/
/* Slider */
.slick-loading .slick-list {
    background: #fff url('../img/ajax-loader.gif') center center no-repeat;
}

.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.slick-list {
    position: relative;
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 0;
}

.slick-list:focus {
    outline: none;
}

.slick-list.dragging {
    cursor: pointer;
    cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.slick-track {
    position: relative;
    top: 0;
    left: 0;
    display: block;
}
#lessonTopicList .slick-track {
    position: relative;
    top: 0;
    left: 0;
    display: flex;
}
.slick-track:before,
.slick-track:after {
    display: table;
    content: '';
}

.slick-track:after {
    clear: both;
}

.slick-loading .slick-track {
    visibility: hidden;
}

.slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;
}

[dir='rtl'] .slick-slide {
    float: right;
}

.slick-slide img {
    display: block;
}

.slick-slide.slick-loading img {
    display: none;
}

.slick-slide.dragging img {
    pointer-events: none;
}

.slick-initialized .slick-slide {
    display: block;
}

.slick-loading .slick-slide {
    visibility: hidden;
}

.slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
    display: none;
}

/* Icons */
@font-face {
    font-family: 'slick';
    font-weight: normal;
    font-style: normal;
    src: url('../fonts/slick.eot');
    src: url('../fonts/slick.eot?#iefix') format('embedded-opentype'), url('../fonts/slick.woff') format('woff'), url('../fonts/slick.ttf') format('truetype'), url('../fonts/slick.svg#slick') format('svg');
}

/* Arrows */
.slick-prev,
.slick-next {
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 50%;
    display: block;
    width: 40px;
    height: 40px;
    padding: 0;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    cursor: pointer;
    color: transparent;
    border: 1px solid #d7d7d7;
    outline: none;
    background: #fff;
}

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
    color: transparent;
    outline: none;
    background: transparent;
}

.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
    opacity: 1;
}

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
    opacity: .25;
}

.slick-prev:before,
.slick-next:before {
    font-family: 'slick';
    font-size: 20px;
    line-height: 1;
    opacity: .75;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
    left: -38px;
}
[dir='rtl'] .slick-prev {
    right: -38px;
    left: auto;
}

.slick-prev:before {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 9px;
    height: 15px;
    margin: -7px 0 0 -6px;
    content: '';
    background: url(../img/page_arrow_left.png);
    background-size: cover;
}
.slick-next {
    right: -38px;
}
[dir='rtl'] .slick-next {
    right: auto;
    left: -38px;
}

.slick-next:before {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 9px;
    height: 15px;
    margin: -7px 0 0 -4px;
    content: '';
    background: url(../img/page_arrow_right.png);
    background-size: cover;
}

/* Dots */
.slick-dotted.slick-slider {
    margin-bottom: 30px;
}

.slick-dots {
    position: absolute;
    bottom: -25px;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
}

.slick-dots li {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer;
}

.slick-dots li button {
    font-size: 0;
    line-height: 0;
    display: block;
    width: 20px;
    height: 20px;
    padding: 5px;
    cursor: pointer;
    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
}

.slick-dots li button:hover,
.slick-dots li button:focus {
    outline: none;
}

.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
    opacity: 1;
}

.slick-dots li button:before {
    font-family: 'slick';
    font-size: 6px;
    line-height: 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    content: '•';
    text-align: center;
    opacity: .25;
    color: black;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.slick-dots li.slick-active button:before {
    opacity: .75;
    color: black;
}

ul.slider-list {
    width: calc(100% - 60px);
    margin: 0 auto !important;
}

ul.slider-list li {
    display: inline-block;
    margin: .75em;
}

.card-item-center {
    display: flex !important;
    align-items: center;
}

.card-item-center .title-box {
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.card-item-center .title-box label {
    display: inline-block;
    min-width: 60px;
    height: 6px;
    padding: 0 10px;
    line-height: 6px;
    font-size: 0;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    cursor: pointer;
    vertical-align: middle;
    transition: all ease .1s;
    background: #d0d0d0;
}

.card-item-center .title-box label.active {
    height: 20px;
    line-height: 20px;
    font-size: .9em;
    border-radius: 2px;
}

.card-item-center .title-box .header-icon {
    display: block !important;
    font-size: 1.125em;
    font-weight: 500;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.card-item-center .right-box {
    margin-left: auto;
}

.card-item-center .right-box.ui.dropdown .menu {
    margin: .5em 0 !important;
    overflow: hidden;
}

.card-item-center .right-box.ui.dropdown .menu > .item {
    padding: .5em 1em !important;
    font-size: 1em;
    background: #fff;
}

.card-item-center + .content {
    flex: 1;
}

.card-item-center + .content .fields > .inline.field {
    display: flex;
}

.bars {
    display: inline-block;
    width: 20px;
    height: 20px;
    text-indent: -9999px;
    vertical-align: middle;
    background: url(../img/dot_menu.png) no-repeat center;
    background-size: contain;
    padding: 0 10px;
}

.ui.cards > .card > .ui.dimmer {
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: linear-gradient(45deg, rgba(0, 0, 0, .1) 16.67%, transparent 16.67%, transparent 50%, rgba(0, 0, 0, .1) 50%, rgba(0, 0, 0, .1) 66.67%, transparent 66.67%, transparent 100%);
    background-size: 8.49px 8.49px;
}

.ui.cards > .card > .ui.dimmer > .dimmer-box {
    font-weight: 500;
}

.ui.cards > .card > .ui.dimmer > .dimmer-box > i {
    font-size: 2em;
    color: #333;
}


/* gridster customize CSS */
.gridster .gs-w {
    position: absolute;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #d9d9d9;
    box-sizing: border-box;
    vertical-align: top;
    background: #fff;
    box-shadow: #e1e1e1 1px 1px 5px;
}

.gridster .gs-w:after {
    content: '';
    display: block;
    clear: both;
}

.gridster .gs-header {
    position: relative;
    padding-bottom: 10px;
    border-bottom: 1px solid #e9e9e9;
}

.gridster .gs-header h3 {
    font-size: 1.125em;
    color: #000;
}

.gridster .gs-header .ui.buttons {
    position: absolute;
    top: -2px;
    right: 20px;
}

.gridster .gs-header .moreBtn {
    position: absolute;
    top: 5px;
    right: 0;
    width: 15px;
    height: 15px;
    text-indent: -9999px;
    background: url(../img/btn_more.png);
    z-index: auto;
}

.gridster .gs-header .moreBtn span {
    display: none;
}

.gridster .gs-content {
    min-height: 220px;
    padding: 15px 0;
}

/* grid-main common CSS */
.grid-main {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-left: -10px;
    margin-right: -10px;
}

.grid-main:after {
    content: "";
    display: block;
    height: 0;
    clear: both;
}

.grid-main-box {
    position: relative;
    float: left;
    margin: 10px;
    padding: 15px;
    vertical-align: top;
    background: #fff;
}

.border-type {
    border-radius: 3px;
    border: 1px solid #d9d9d9;
    box-sizing: border-box;
    box-shadow: #f1f1f1 1px 1px 5px;
}

.grid-main-box > .header {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid #e9e9e9;
}

.grid-main-box > .header:after {
    content: "";
    display: block;
    height: 0;
    clear: both;
}

.grid-main-box > .header h2 {
    flex: 1;
    font-size: 1.13em;
    color: #333;
}
.grid-main-box > .header h2.h_row {
    flex: 0 1 auto;
    font-size: 1.13em;
    color: #333;
    margin-right: 20px;
}
.grid-main-box > .header h2.week-list {
    display: inline-block;
    width: auto;
    margin-right: 10px;
    vertical-align: middle;
}

.grid-main-box > .header h2.inline-list {
    display: inline-block;
    width: auto;
    margin-right: 10px;
    vertical-align: middle;
}

.grid-main-box > .header .ui.labels {
    display: inline-block;
    vertical-align: middle;
}

.grid-main-box > .header .ui.labels > .label {
    margin: 0;
}

.grid-main-box > .header .addBtn {
    float: right;
    margin-right: 60px;
}

.grid-main-box > .header .moreBtn {
    width: 18px;
    height: 18px;
    margin-left: 10px;
    text-indent: -9999px;
    background: url(../img/btn_more.png) no-repeat center;
    background-size: cover;
}

.grid-main-box > .header .removeBtn {
    width: 15px;
    height: 15px;
    margin-left: 10px;
    text-indent: -9999px;
    background: url(../img/btn_remove.png);
}

.grid-main-box > .header .writeBtn {
    width: 15px;
    height: 15px;
    margin-left: 10px;
    text-indent: -9999px;
    background: url(../img/btn_pencil.png) no-repeat center;
}

.grid-main-box > .header .editBtn {
    width: 15px;
    height: 15px;
    margin-left: 10px;
    text-indent: -9999px;
    background: url(../img/btn_edit.png);
}

.grid-main-box > .header .right-area {
    display: flex;
    margin-left: auto;
}

/*.grid-main-box > .content {min-height: 190px;} */
.grid-main-box > .content.scrollbar {
    height: calc(100% - 48px);
    overflow: hidden;
}

.grid-main-box > .footer {
    margin-top: 10px;
}

.grid-main-box .search-tab {
    margin: -4.15em 0 20px 10px !important;
    border-bottom: none !important;
}

.grid-main-box .ui.tabular.menu.search-tab .item {
    padding: 0.62857143em 1.42857143em;
}

.grid-column {
    float: left;
    width: 50%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
}

.grid-column-lg {
    float: left;
    width: 66.64%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
}

.grid-column-md {
    float: left;
    width: 47%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
}

.grid-column-sm {
    float: left;
    width: 33.33%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
}

.grid_column_sm_noflex {
    float: left;
    width: 33.33%;
}

.grid-column-xs {
    float: left;
    width: 19.6%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
}

.size_1 {
    width: calc(16.66% - 20px);
}

.size_2 {
    width: calc(33.33% - 20px);
}

.size_3 {
    width: calc(50% - 20px);
}

.size_4 {
    width: calc(66.64% - 20px);
}

.size_5 {
    width: calc(83.3% - 20px);
}

.size_6 {
    width: calc(100% - 20px);
}

.ui.grid > .column .grid-main-box {
    margin-left: 0;
    margin-right: 0;
}

.ui.grid > .column .grid-main-box.size_1 {
    width: 16.66%;
}

.ui.grid > .column .grid-main-box.size_2 {
    width: 33.33%;
}

.ui.grid > .column .grid-main-box.size_3 {
    width: 50%;
}

.ui.grid > .column .grid-main-box.size_4 {
    width: 66.64%;
}

.ui.grid > .column .grid-main-box.size_5 {
    width: 83.3%;
}

.ui.grid > .column .grid-main-box.size_6 {
    width: 100%;
}

.knob-box {
    text-align: center;
}

.knob-box li {
    display: inline-block;
    width: 15%;
    padding: 20px 0;
}

.knob-box p {
    display: block;
    margin-top: 10px;
}

ul.list_line_S2 > li {
    border-top: 1px solid #e9e9e9;
}

/*ul.list_line_S2 > li:nth-child(odd) {background:#f9f9f9;}*/
ul.list_line_S2 > li:first-child {
    border-top: none;
}

ul.list_line_S2 > li a {
    display: flex;
    align-items: center;
    padding: 12px 0;
}

ul.list_line_S2 > li a strong {
    margin-right: 7px;
    font-weight: 700;
    color: #333;
}

ul.list_line_S2 > li a + .bottom-flex {
    margin: -6px 0 12px;
}

ul.list_line_S2 > li a span {
    flex: 1;
    display: inline-block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

ul.list_line_S2 > li a small {
    margin-left: auto;
    padding-left: 10px;
    display: inline-block;
    color: #999;
}

ul.list_line_S2 > li a small.period {
    float: none;
    display: block;
    margin-top: 5px;
    padding-left: 10px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

ul.list_line_S2 > li a small.period span {
    max-width: 100%;
    flex: 1;
}

ul.list_line_S2 > li a small.period span:last-child {
    text-align: right;
}

.two-column {
    display: flex;
    flex-flow: wrap;
}

.two-column blockquote {
    float: left;
    width: 50%;
}

.mypage blockquote {
    border-left-width: 3px;
}

blockquote {
    margin: 3px 0;
    padding: 10px;
    border-left: 3px solid #ccc;
}

blockquote > span {
    display: inline-block;
    padding-bottom: 5px;
    font-size: 1.063em;
}

/*blockquote a {display: block; white-space:nowrap; text-overflow:ellipsis; overflow:hidden; vertical-align: top;}*/
blockquote p.author {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

blockquote p.author small {
    display: inline-block;
    display: flex;
    align-items: center;
    flex: 1;
    font-size: .938em;
    color: rgba(0, 0, 0, .6);
}

blockquote p.author small:last-child {
    flex: none;
    text-align: right;
    justify-content: flex-end;
}

.day-red {
    border-left-color: #d01919;
}

.day-orange {
    border-left-color: #f2711c;
}

.before-read {
    border-left-color: #2185D0;
    background: #e6f0f7;
}

.hb_playList .play_video {
    border-left-color: #00B5AD;
    background: #effaf9;
}

.before-read span {
    color: #2185D0;
}

.class-active {
    border-left-color: #2185D0;
}

.class-online {
    border-left-color: #0d47a1;
}

.class-offline {
    border-left-color: #e91e63;
}

.class-mix {
    border-left-color: #9c27b0;
}

.normal-list blockquote {
    padding: 10px 0;
    border: none;
    border-bottom: 1px solid #d7d7d7;
}

.normal-list blockquote:last-child {
    border-bottom: none;
}

.flex-item-box {
    display: flex;
    align-items: center;
}

.flex-item-box blockquote {
    flex: 1;
}

.text-new {
    display: inline-block;
    width: 20px;
    min-width: 20px;
    height: 20px;
    margin-left: 5px;
    text-indent: -9999px;
    vertical-align: middle;
    background: url(../img/ico_new.png) 50% 50% no-repeat;
}

/*.ui.toggle.checkbox label {min-width: 100px;}*/
.ui.dimmer .close-btn {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 16px;
    height: 16px;
    text-indent: -9999px;
    cursor: pointer;
    background: url(../img/closeBtn.png) no-repeat;
    background-size: cover;
}

.ui.selection.dropdown.mobile {
    width: 80px !important;
    min-width: auto;
}

.fullcalendar-event0 {
    background-color: #1678c2 !important;
}

.fullcalendar-event1 {
    background-color: #00b5ad !important;
}

.fullcalendar-event2 {
    background-color: #21ba45 !important;
}

.fullcalendar-event3 {
    background-color: #b5cc18 !important;
}

.fullcalendar-event4 {
    background-color: #fbbd08 !important;
}

.fullcalendar-event5 {
    background-color: #f2711c !important;
}

.fullcalendar-event6 {
    background-color: #6435c9 !important;
}

.fullcalendar-event7 {
    background-color: #a333c8 !important;
}

.fullcalendar-event8 {
    background-color: #e03997 !important;
}

.fullcalendar-event9 {
    background-color: #a5673f !important;
}

.extras {
    height: 100vh;
}

.extras .flex-container .cont-none {
    width: 1200px;
    margin: 1em;
    padding: 2em;
    text-align: center;
    /* border: 1px solid #d7d7d7; */
    /* border-radius: 4px; */
    /* box-shadow: #f1f1f1 1px 1px 5px; */
    /* background: #fff; */
}

.flex-container {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    min-height: calc(100vh / 2);
    align-items: center;
    justify-content: center;
}

.flex-container .cont-none,
.flex-container .msg-none,
.flex-container .search-none {
    text-align: center;
}

.flex-container .cont-none > span {
    display: inline-block;
    width: 180px;
    height: 80px;
    text-indent: -9999px;
    background: url('../img/cont_none.png') no-repeat;
    background-size: contain;
}

.flex-container .msg-none > span {
    display: inline-block;
    width: 180px;
    height: 80px;
    text-indent: -9999px;
    background: url('../img/msg_none.png') no-repeat;
    background-size: contain;
}

.flex-container .search-none > span {
    display: inline-block;
    width: 180px;
    height: 80px;
    text-indent: -9999px;
    background: url('../img/search_none.png') no-repeat;
    background-size: contain;
}

.flex-container .no_content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    width: 180px;
    height: 80px;
}
.flex-container .no_content i {
    width: 100%;
    display: inline-block;
    font-size: 4em;
    color: #868e96;
    margin-bottom: 10px;
}
.flex-container .no_content p {
    font-weight: 400;
    color: #636363;
    font-size: 1.125em;
}

/* .flex-container .user-none > span {
    display: inline-block;
    width: 180px;
    height: 80px;
    text-indent: -9999px;
    background: url('../img/user_none.png') no-repeat;
    background-size: contain;
} */

.flex-container .cont-none .text {
    font-size: 2.5em;
    font-weight: bold;
}

.flex-container .cont-none img {
    display: inline-block;
    width: 45%;
    margin-bottom: 10px;
    object-fit: cover;
}

/* test-example number */
.ui.checkbox label.question {
    padding: 7px 0 7px 40px;
}

.ui.checkbox label.question:before {
    width: 32px;
    height: 32px;
    text-align: center;
    line-height: 28px;
    border-radius: 100%;
}

.ui.checkbox input:checked ~ label.question:after {
    width: 32px;
    height: 32px;
    line-height: 32px;
    content: inherit;
    color: #fff !important;
    border: 1px solid #333;
    border-radius: 100%;
    background: #333;
    content: "\f383";
}

.ui.checkbox label.question.num01:before {
    content: 'A';
}

.ui.checkbox label.question.num02:before {
    content: 'B';
}

.ui.checkbox label.question.num03:before {
    content: 'C';
}

.ui.checkbox label.question.num04:before {
    content: 'D';
}

.ui.checkbox label.question.num05:before {
    content: 'E';
}

.ui.checkbox label.question.num06:before {
    content: 'F';
}

.ui.checkbox label.question.num07:before {
    content: 'G';
}

.ui.checkbox label.question.num08:before {
    content: 'H';
}

.ui.checkbox label.question.num09:before {
    content: 'I';
}

.ui.checkbox label.question.num10:before {
    content: 'J';
}

.correct label {
    color: #FF5733 !important;
}

.correct .ui.checkbox label:before {
    border-color: #FF5733 !important;
    background: #fff1ee;
}

.checkImg {
    width: 100%;
    text-align: center;
}

.checkImg input {
    position: absolute;
    left: -9999px;
    width: 16px;
    height: 16px;
    opacity: 0;
    filter: alpha(opacity=0);
}

.checkImg .true {
    background-image: url(../img/quiz_true.gif);
}

.checkImg .false {
    background-image: url(../img/quiz_false.gif);
}

.checkImg input:active + .imgChk, .checkImg input:active + .imgChk,
.checkImg input:checked + .imgChk, .checkImg input:checked + .imgChk {
    background-position: 0 -80px;
}

.checkImg .imgChk {
    cursor: pointer;
    background-repeat: no-repeat;
    display: inline-block;
    width: 80px;
    height: 80px;
    margin: 5%;
    background-size: 80px auto;
}

.checkImg input:disabled {
    pointer-events: none;
    cursor: default;
}

.checkImg input[disabled] ~ label {
    user-select: none !important;
    cursor: default !important;
}

label.correct:before {
    position: absolute;
    left: 50%;
    bottom: -20px;
    margin-left: -18px;
    font-size: 18px;
    color: #000;
    content: "정답";
}

.sm-size {
    width: 100px !important;
}

.lg-size {
    width: 360px !important;
}

.auto-size {
    min-width: auto !important;
}

.tablet-show, .mobile-show {
    display: none !important;
}

.mobile-hide {
    display: block !important;
}

.toggle_btn {
    cursor: pointer;
}

.toggle_box {
    display: none;
}

.exclusive_box {
    display: none;
    margin-top: 10px;
}

.toggle_box.on,
.exclusive_box.on {
    display: block;
}

.result-view .tab-box1,
.result-view .tab-box2 {
    display: none;
}

.chk-set {
    padding: .75em 0;
}

.chk-set .toggle_box {
    margin-top: 1em;
}

.label-title {
    width: 20% !important;
    min-width: 80px;
    margin: .25em 0;
}
.label-title.mb_lg {
    width: 20% !important;
    min-width: 80px;
    margin: .5em 0 !important;
}

.label-title-lg {
    width: 30% !important;
    /*margin: .25em 0 !important;*/
}

.label-title-px {
    min-width: 80px !important;
    margin: .25em 0 !important;
}

.header.small {
    font-size: 1em !important;
}

.header.small ul.viewInfo li {
    display: flex;
    max-width: 900px;
    color: #333;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: middle;
    font-size: 1.15em;
    font-weight: 500;
}

/* .header.small ul.viewInfo li:before {
    content: "";
    display: inline-block;
    width: 1px;
    height: 12px;
    margin: 0 10px;
    vertical-align: middle;
    background-color: #c3c3c3;
} */

.header.small ul.viewInfo li:first-child {
    padding-left: 0;
}

.header.small ul.viewInfo li:first-child:before {
    display: none;
}

/*.header.small ul.viewInfo li a {color: #acacac;}*/
.header.small ul.viewInfo li span {
    margin-right: 10px;
    color: #006ee5;
    vertical-align: inherit;
}

.header.small .ui.rating {
    vertical-align: middle;
}

/* jq-stars rating */
.jq-stars {
    display: inline-block;
}

.jq-rating-label {
    font-size: 22px;
    display: inline-block;
    position: relative;
    vertical-align: top;
}

.jq-star {
    width: 100px;
    height: 100px;
    display: inline-block;
    cursor: pointer;
    vertical-align: sub;
}

.jq-star-svg {
    padding-left: 3px;
    width: 100%;
    height: 100%;
}

.jq-star-svg path {
    stroke-linejoin: round;
}

.jq-shadow {
    -webkit-filter: drop-shadow(-2px -2px 2px #888);
    filter: drop-shadow(-2px -2px 2px #888);
}

.rating-text {
    margin-left: 5px;
    vertical-align: text-top;
}

/* basic style of controller */
#qslider .qlabel {
    text-align: center;
}

#qslider .qlabel span {
    display: inline-block;
    width: 5em;
    color: #999;
}

#qslider .qlabel span:first-child {
    float: left;
}

#qslider .qlabel span:last-child {
    float: right;
}

#qslider span.controller {
    position: relative;
    width: 100%;
    float: left;
    margin: .5em 0;
    border-radius: 7px;
    background: #e9ecf8;
}

#qslider span.controller .ruler {
    position: absolute;
    display: inline-block;
    width: 3px;
    height: 3px;
    background: #fff;
}

#qslider .ui-widget-header {
    background: transparent;
}

#qslider .ui-slider-handle {
    cursor: pointer;
}

#qslider span.controller label {
    position: absolute;
    width: 20px;
    margin-left: -10px;
    text-align: center;
    margin-top: 20px;
}

/* effet-image */
.img-hover-zoom img {
    transition: transform .5s ease;
}

.img-hover-zoom:hover img {
    transform: scale(1.1);
}

.img-hover-zoom--quick-zoom img {
    transform-origin: 0 0;
    transition: transform .25s, visibility .25s ease-in;
}

.img-hover-zoom--quick-zoom:hover img {
    transform: scale(2);
}

.img-hover-zoom--point-zoom img {
    transform-origin: 65% 75%;
    transition: transform 1s, filter .5s ease-out;
}

.img-hover-zoom--point-zoom:hover img {
    transform: scale(5);
}

.img-hover-zoom--zoom-n-rotate img {
    transition: transform .5s ease-in-out;
}

.img-hover-zoom--zoom-n-rotate:hover img {
    transform: scale(2) rotate(25deg);
}

.img-hover-zoom--slowmo img {
    transform-origin: 50% 65%;
    transition: transform 5s, filter 3s ease-in-out;
    filter: brightness(150%);
}

.img-hover-zoom--slowmo:hover img {
    filter: brightness(100%);
    transform: scale(3);
}

.img-hover-zoom--brightness img {
    transition: transform 2s, filter 1.5s ease-in-out;
    transform-origin: center center;
    filter: brightness(50%);
}

.img-hover-zoom--brightness:hover img {
    filter: brightness(100%);
    transform: scale(1.3);
}

.img-hover-zoom--zoom-n-pan-h img {
    transition: transform .5s ease-in-out;
    transform: scale(1.4);
    transform-origin: 100% 0;
}

.img-hover-zoom--zoom-n-pan-h:hover img {
    transform: scale(1.5) translateX(30%);
}

.img-hover-zoom--zoom-n-pan-v img {
    transition: transform .5s ease-in-out;
    transform: scale(1.4);
    transform-origin: 0 0;
}

.img-hover-zoom--zoom-n-pan-v:hover img {
    transform: scale(1.25) translateY(-30%);
}

.img-hover-zoom--blur img {
    transition: transform 1s, filter 2s ease-in-out;
    filter: blur(2px);
    transform: scale(1.2);
}

.img-hover-zoom--blur:hover img {
    filter: blur(0);
    transform: scale(1);
}

.img-hover-zoom--colorize img {
    transition: transform .5s, filter 1.5s ease-in-out;
    filter: grayscale(100%);
}

.img-hover-zoom--colorize:hover img {
    filter: grayscale(0);
    transform: scale(1.1);
}

.inbox-more {
    position: absolute;
    top: 5em;
    left: 0;
    width: calc(100% + 2px);
    min-height: 10.8em;
    margin-left: -1px;
    padding: 0 1em;
    border: 1px solid #D4D4D5;
    border-top: none;
    border-radius: 0em 0em 0.28571429rem 0.28571429rem !important;
    background: #fff;
    transform-origin: 100% 0% 0;
    opacity: 0;
    z-index: -1;
}

.inbox-more.on {
    animation: animation-name .5s;
    opacity: inherit;
    z-index: 12;
}

@keyframes animation-name {
    0% {
        opacity: 0;
        transition: transform .5s ease 0s;
        transform: perspective(500px) rotateX(-180deg);
    }

    20% {
        opacity: 0.8;
    }

    100% {
        opacity: 1;
        transform: rotateX(0deg);
    }
}

.ui.buttons.inline-bottom {
    position: absolute;
    right: 0;
    bottom: 0;
    width: auto !important;
    margin: 0 -1px -1px 0 !important;
}

.gn-menu-main {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 60px;
    margin: 0;
    padding: 0;
    background: white;
    color: #5f6f81;
    list-style: none;
    text-transform: none;
    font-weight: 300;
    z-index: 200;
}

.gn-menu-main .gn-trigger {
    position: relative;
    width: 60px;
    height: 59px;
    line-height: 59px;
    text-align: center;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.gn-menu-main .gn-trigger ul li a {
    display: block;
    height: 100%;
    color: #fff;
    text-decoration: none;
    cursor: pointer;
    border-right: 1px solid #c6d0da;
}
/* c6d0da */

.no-touch .gn-menu-main .gn-trigger > a {
    display: block;
    background: #fff;
    border-right: 1px solid #c6d0da;
}

.no-touch .gn-menu-main .gn-trigger > a:hover {
    background: #A53135 !important;
    border-right: 1px solid #A53135;
}

.no-touch .gn-menu-main .gn-trigger.blue_menu > a:hover {
    background: #25408F !important;
    border-right: 1px solid #25408F;
}

.gn-menu-main:after {
    display: table;
    clear: both;
    content: "";
}

/* .gn-menu-wrapper {
    position: fixed;
    top: 60px;
    bottom: 0;
    left: 0px;
    overflow: hidden;
    width: 60px;
    background: white;
    -webkit-transform: translateX(0px);
    -moz-transform: translateX(0px);
    transform: translateX(0px);
    -webkit-transition: -webkit-transform 0.3s, width 0.3s;
    -moz-transition: -moz-transform 0.3s, width 0.3s;
    transition: transform 0.3s, width 0.3s;
    background: #006eb6;
} */

.gn-menu-wrapper {
    position: fixed;
    top: 60px;
    bottom: 0;
    left: 0px;
    overflow: hidden;
    width: 60px;
    background: white;
    -webkit-transform: translateX(0px);
    -moz-transform: translateX(0px);
    transform: translateX(0px);
    -webkit-transition: -webkit-transform 0.3s, width 0.3s;
    -moz-transition: -moz-transform 0.3s, width 0.3s;
    transition: transform 0.3s, width 0.3s;
    background: #A53135;
}

.dark_menu .gn-menu-wrapper {
    background: #33344A;
}

.blue_menu .gn-menu-wrapper {
    background: #25408F;
}

/* prof_menu추가 */
.prof_menu .gn-menu-wrapper {
    background: #6741d9;
}

/* admin_menu추가 */
.admin_menu .gn-menu-wrapper {
    background: #33344A;
}

.gn-scroller {
    position: absolute;
    overflow-y: scroll;
    width: 300px;
    height: 100%;
}

.gn-menu {
    border-bottom: 1px solid rgba(255, 255, 255, .1);
    text-align: left;
    font-size: 1.25em;
}

.gn-menu li:not(:first-child),
.gn-menu li li {
    box-shadow: inset 0 1px rgba(255, 255, 255, .25);
}

.gn-submenu li {
    overflow: hidden;
    height: 0;
    -webkit-transition: height 0.3s;
    -moz-transition: height 0.3s;
    transition: height 0.3s;
}

.gn-menu-main .gn-trigger .gn-submenu li a {
    font-size: .875em;
    color: rgba(255, 255, 255, .7);
}

.gn-icon::before {
    display: inline-block;
    width: 60px;
    margin: 0 !important;
    font-size: 1.25em;
    text-align: center;
    text-transform: none;
    font-weight: normal;
    font-style: normal;
    font-variant: normal;
    font-family: 'Ionicons';
    line-height: 1;
    speak: none;
    -webkit-font-smoothing: antialiased;
    vertical-align: middle;
}

.gn-icon span {
    width: 0;
    height: 0;
    display: block;
    overflow: hidden;
}

.gn-icon-menu::before {
    vertical-align: -2px;
    width: 30px;
    height: 3px;
    background: #A53135;
    box-shadow: 0 3px #fff, 0 -6px #A53135, 0 -9px #fff, 0 -12px #A53135;
    content: '';
}

.no-touch .gn-icon-menu:hover::before,
.no-touch .gn-icon-menu.gn-selected:hover::before {
    background: #fff;
    box-shadow: 0 3px #A53135, 0 -6px white, 0 -9px #A53135, 0 -12px white;
}

.gn-icon-menu.gn-selected::before {
    background: #A53135;
    box-shadow: 0 3px white, 0 -6px #A53135, 0 -9px white, 0 -12px #A53135;
}

.blue_menu .gn-icon-menu::before {
    vertical-align: -2px;
    width: 30px;
    height: 3px;
    background: #25408F;
    box-shadow: 0 3px #fff, 0 -6px #25408F, 0 -9px #fff, 0 -12px #25408F;
    content: '';
}

.blue_menu .gn-icon-menu::before {
    vertical-align: -2px;
    width: 30px;
    height: 3px;
    background: #25408F;
    box-shadow: 0 3px #fff, 0 -6px #25408F, 0 -9px #fff, 0 -12px #25408F;
    content: '';
}

.no-touch .blue_menu .gn-icon-menu:hover::before,
.no-touch .blue_menu.gn-icon-menu.gn-selected:hover::before {
    background: #fff;
    box-shadow: 0 3px #25408F, 0 -6px white, 0 -9px #25408F, 0 -12px white !important;
}

.blue_menu .gn-icon-menu.gn-selected::before {
    background: #25408F;
    box-shadow: 0 3px white, 0 -6px #25408F, 0 -9px white, 0 -12px #25408F !important;
}

.gn-menu-wrapper.gn-open-all {
    width: 280px;
}

.gn-menu-wrapper.gn-open-all .gn-menu li {
    height: auto;
    line-height: normal;
}

.gn-menu-wrapper.gn-open-all .gn-menu li a {
    display: block;
    padding: 10px 0;
}

.gn-menu-wrapper.gn-open-all .gn-menu li a.gn-icon::before {
    width: 42px;
}

.gn-menu-wrapper.gn-open-all .gn-menu li a strong {
    display: inline-block;
    font-size: .813em;
}

.gn-menu-wrapper .gn-menu li a strong {
    display: none;
    font-size: .813em;
}


.gn-menu-wrapper .gn-menu li a.on {
    background-color: #A53135;
    color: #fff;
}

.gn-menu-wrapper.gn-open-all .gn-submenu li {
    height: auto;
    background: rgba(0, 0, 0, .15);
}

.blue_menu .gn-menu-wrapper.gn-open-all .gn-submenu li {
    height: auto;
    background: #B2B3B6;
}

.blue_menu .gn-menu-wrapper.gn-open-all .gn-submenu li a {
    color: #333;
    font-weight: 500;
}

.gn-menu-wrapper.gn-open-all .gn-submenu li a {
    padding: 8px 8px 8px 42px;
    font-size: .75em;
}

.gn-menu-wrapper.gn-open-all .gn-submenu li a:hover {
    /* background: rgba(0, 0, 0, .1); */
    color: #fff;
}

/* 240318수정 */
.gn-menu-main .gn-trigger ul li a:hover,
.gn-menu-main .gn-trigger ul li a:focus,
.gn-menu-main .gn-trigger ul li a:active,
.gn-menu-wrapper.gn-open-all .gn-menu li a.gn-icon:hover,
.gn-menu-wrapper.gn-open-all .gn-menu li a.gn-icon:focus,
.gn-menu-wrapper.gn-open-all .gn-menu li a.gn-icon:active,
.gn-menu-wrapper.gn-open-all .gn-submenu li a:hover,
.gn-menu-wrapper.gn-open-all .gn-submenu li a:focus,
.gn-menu-wrapper.gn-open-all .gn-submenu li a:active {
    background-color: #A53135;
    color: #fff;
}

/* //240318수정 */

.variable-box {
    position: absolute !important;
    right: 0;
    height: 100%;
    border-left: 1px solid #d7d7d7;
    transition: transform 0.3s, width 0.3s;
    background: #fff;
}

.ui.grid > .column:not(.row).variable-box.full {
    width: 85% !important;
    z-index: 998;
}

.ui.grid > .column:not(.row).variable-box.full:before {
    content: '';
    position: absolute;
    left: -4%;
    width: 4%;
    height: 100%;
    margin-top: -1em;
    background: linear-gradient(90deg, rgba(0, 0, 0, 0) 80%, rgba(0, 0, 0, .1) 100%);
    z-index: 9;
}

.variable-box .slide-btn {
    position: absolute;
    left: -8px;
    top: calc(50% - 32px);
    display: flex;
    align-items: center;
    width: 16px;
    height: 64px;
    line-height: 64px;
    color: #000;
    text-align: center;
    border-radius: 7px;
    box-shadow: #f1f1f1 1px 1px 5px;
    background: #767676;
    cursor: pointer;
    z-index: 9;
}

.variable-box .slide-btn:before {
    display: inline-block;
    content: '';
    width: 1px;
    height: 48px;
    margin-left: 5px;
    box-shadow: inset 0 0 10px #d7d7d7;
}

.variable-box .slide-btn:after {
    display: inline-block;
    content: '';
    width: 1px;
    height: 48px;
    margin-left: 4px;
    box-shadow: inset 0 0 10px #d7d7d7;
}

.list-add, .list-remove {
    display: inline-block !important;
    cursor: pointer;
}

.process-bar {
    /* display: flex; */
    height: 2.5em !important;
    line-height: 2.5em !important;
    border-radius: 2px;
    background: #ccc;
    overflow: hidden;
}
.process-bar.sm {
    height: auto !important;
    line-height: 120% !important;
    margin-bottom: 1em;
}

.process-bar li {
    flex: 1 1 auto;
    float: left;
    font-size: .875em;
    color: #fff;
    text-align: center;
}

.process-bar li a {
    display: block;
    color: #fff;
}

.bar-grey {
    background: #595959;
}

.bar-blue {
    background: #006ba4;
}
.bar-green {
    background: #82c91e;
}
.bar-orange {
    background: #F59A23;
}

.bar-grape {
    background: #862e9c;
}

.bar-yellow9 {
    background: #e67700;
}

/* progress_bg */
.progress_bg {
    display: inline-block;
    position: relative;
    width: 100%;
    height: 100%;
    min-height: 2.5em;
    border: 1px solid #cdcdcd;
    border-radius: 2px;
}
.progress_bg p {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
}
.progress_bg p .fcBlack {
    padding: 0 1em;
}
.progress_bg p i.icon {
    display: inline-block;
    margin: 0 0.5em;
    line-height: normal !important
}
.progress_bg p span {
    display: inline-block;
    flex: 1;
    color: #333;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.progress_bg p button {
    display: inline-block;
    margin-right: 1em !important;
    min-width: 95.5px !important;
}
.progress_bg .progress_rate {
    height: 100%;
}
.progress_bg.total {
    height: 100%;
    border: none;
    border-left: 5px solid #0086ec;
    background: #eee;
}
.progress_bg.total p i {
    font-size: 1.867em;
    color: #0086ec;
}

.progress_bg.total p span {
    color: #333;
}
.progress_bg.p_reject {
    background: #868e96;
}
.progress_bg.p_reject p span,
.progress_bg.p_reject p i.icon {
    color: #fff;
}
.progress_bg.p_disable {
    background: #eee;
    cursor: default;
}
.progress_bg.p_disable:hover {
    box-shadow: none !important;
    border: 1px solid #cdcdcd !important;
}
.progress_bg.p_disable p span {
    color: #aaa;
}

#note-box, #alert-box {
    display: none;
    position: fixed;
    z-index: 6001;
    top: 0;
    left: 0;
    right: 0;
    background: #4caf50;
    color: rgba(255, 255, 255, .8);
    text-align: center;
    padding: 20px 0;
    overflow: hidden;
    -webkit-box-shadow: 0 0 5px black;
    -moz-box-shadow: 0 0 5px black;
    box-shadow: 0 0 5px black;
}

#note-box.on, #alert-box.on {
    display: block;
}

#note-box.warning, #alert-box.warning {
    background-color: #f44336;
}

.cssanimations.csstransforms #note-box.on {
    -webkit-transform: translateY(-80px);
    -webkit-animation: slideDown 2.5s 0s 1 ease forwards;
    -moz-transform: translateY(-80px);
    -moz-animation: slideDown 2.5s 0s 1 ease forwards;
}

#note-box #close,
#alert-box #close,
#note-box #close1,
#alert-box #close2 {
    position: absolute;
    right: 10px;
    top: 0px;
    cursor: pointer;
    font-size: 2em;
    color: rgba(255, 255, 255, .8);
}

.initial-img {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    vertical-align: middle;
    overflow: hidden;
}

.initial-img.lg {
    width: 100px;
    height: 100px;
    font-size: 1em;
    color: #fff;
}

.initial-img.md {
    width: 64px;
    height: 64px;
    font-size: 0.93em !important;
    color: #fff;
}

.initial-img.sm {
    width: 42px;
    height: 42px;
    font-size: .867em;
    color: #fff;
}

.initial-img img {
    width: 100%;
}

.c-w {
    color: #222 !important;
    box-shadow: 0px 0px 0px 1px #ccc inset;
    background-color: #fff !important;
}

.c-0 {
    background-color: #e91e63 !important;
}

.c-1 {
    background-color: #197ABF !important;
}

.c-2 {
    background-color: #ffd43b !important;
}

.c-3 {
    background-color: #4dabf7 !important;
}

.c-4 {
    background-color: #009688 !important;
}

.c-5 {
    background-color: #94d82d !important;
}

.c-6 {
    background-color: #F59A23 !important;
}

.c-7 {
    background-color: #ff5722 !important;
}

.c-8 {
    background-color: #795548 !important;
}

.c-9 {
    background-color: #607d8b !important;
}
.c-10 {
    background-color: #ae3ec9 !important;
}
.c-11 {
    background-color: #E83C4F !important;
}


/* .select-list */
.select-list .footable-first-visible {
    padding: 0 20px;
}
.select-list tbody tr:hover {
    background: #e2e2f6;
    transition: background-color .2s, border-color .2s;
}
.select-list tbody tr:hover td {
    border-bottom: 1px solid #e2e2f6;
}
.select-list .ui-mark {
    display: inline-block;
    text-align: center;
}
.select-list .ui-mark i {
    font-size: 16px;
}
.select-list .ui-mark input[type="checkbox"],
.select-list .ui-mark input[type="radio"] {
    display: none;
}
.select-list .ui-mark input[type="checkbox"] + label,
.select-list .ui-mark input[type="radio"] + label {
    display: inline-block;
    width: 24px;
    height: 24px;
    opacity: 0;
    cursor: pointer;
}
.select-list .ui-mark input[type="checkbox"]:checked + label,
.select-list .ui-mark input[type="radio"]:checked + label {
    opacity: 1;
    color: #fff;
}
.select-list tr:hover .ui-mark input[type="checkbox"] + label,
.select-list tr:hover .ui-mark input[type="radio"] + label {
    opacity: 1;
}
.select-list tr input[type="text"] {
    background-color: transparent !important;
}

.select-list tr .ui.basic.buttons .ui.button:hover,
.select-list tr .ui.basic.button:hover {
    background: transparent !important;
}
.select-list tr.active {
    color: #fff;
    background: #6264A7 !important;
}

.select-list tr.active td .link-dots {
    color: #fff;
    border-bottom-color: #fff;
}

.select-list tr.active td input[type="text"],
.select-list tr.active td input[type="text"]:focus {
    color: #fff !important;
    border-color: rgba(255, 255, 255, .3);
    background: transparent;
}

.select-list tr.active td input[type="text"]::placeholder {
    color: rgba(255, 255, 255, .7);
}

.select-list tr.active .link {
    color: #fff !important;
}

.select-list tr.active .link:before {
    background-color: #fff !important;
}

.select-list tr.active .ui.basic.buttons {
    border: 1px solid rgba(255, 255, 255, .3);
}

.select-list tr.active .ui.basic.buttons .ui.button,
.select-list tr.active .ui.basic.button {
    color: #fff !important;
    background-color: transparent !important;
    border-color: rgba(255, 255, 255, .3) !important;
    box-shadow: 0px 0px 0px 1px transparent inset, 0px 0em 0px 0px rgb(255 255 255 / 15%) inset
}

.select-list tr.active .ui.basic.buttons .ui.button:hover,
.select-list tr.active .ui.basic.button:hover {
    background-color: #7173af !important;
}

.select-list tr.active .ui-mark input[type="checkbox"] + label,
.select-list tr.active .ui-mark input[type="radio"] + label {
    opacity: 1;
}

.bottom-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 3em 0;
}

.bottom-btn .btn {
    min-width: 180px;
    padding: 1em 2em;
    font-size: 1.125em;
}

/* terms & privacy_policy */
.terms,
.privacy_policy,
.email_secutiry {
    padding: 1em;
    counter-reset: section;
}

.terms .terms-btit,
.privacy_policy .privacy-btit {
    position: relative;
    margin-top: 1em;
    font-size: 1.5em;
    line-height: 1.5;
}

.terms .terms-tit,
.privacy_policy .privacy-tit,
.email_secutiry .email-tit {
    position: relative;
    margin-top: 1em;
    font-size: 1.15em;
    line-height: 1.5;
}

.terms p,
.privacy_policy p,
.email_secutiry p {
    margin-top: 10px;
}

.terms .terms-list,
.privacy_policy .privacy-list {
    counter-reset: li;
}

.terms .terms-list li,
.privacy_policy .privacy-list li {
    line-height: 1.6
}

.terms .terms-list > li:before,
.privacy_policy .privacy-list > li:before {
    display: inline-block;
    content: counter(li) '.';
    counter-increment: li;
    margin-right: 5px;
}

.terms .terms-list li .terms-list-num,
.privacy_policy .privacy-list li .privacy-list-num {
    margin-left: 15px;
}

.terms .terms-list li .terms-list-num,
.privacy_policy .privacy-list li .privacy-list-num {
    counter-reset: li;
}

.terms .terms-list-num > li:before,
.privacy_policy .privacy-list-num > li:before {
    display: inline-block;
    content: counter(li);
    counter-increment: li;
    width: 15px;
    height: 15px;
    margin-right: 5px;
    border: 1px solid #555;
    border-radius: 100%;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
}

.privacy_policy .privacy-list li .privacy-list-num {
    counter-reset: none;
}

.privacy_policy .privacy-list-dot {
    margin-left: 15px;
}

.privacy_policy .privacy-list-dot li {
    line-height: 1.6
}

.privacy_policy .privacy-list-dot > li:before {
    display: inline-block;
    content: counter(li, disc);
    counter-increment: none;
    margin-right: 5px;
}

.dark-btn {}

.dark-btn:before {
    display: inline-block;
    content: '\f186';
    font-family: "outline-icons";
    font-size: 1.375em;
    line-height: 29px;
    vertical-align: middle;
    cursor: pointer;
}

/* 메인 레이어 팝업 CSS */
.popup-wrap {
    display: none;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    height: 100vh;
    z-index: 9999;
}

.popup-wrap .inner-box {
    position: relative;
    width: 920px;
    opacity: 1;
    z-index: 1;
}

.popup-wrap .inner-box .slick-track {
    margin: 0 auto;
}

.popup-wrap .inner-box ul li {
    text-align: center;
}

.popup-wrap .inner-box ul li img {
    display: inline-block;
    width: 420px;
}

.popup-wrap .inner-box .slick-prev,
.popup-wrap .inner-box .slick-next {
    width: 64px;
    height: 64px;
    color: #fff;
    border: none;
    border-radius: 100%;
    background: rgba(0, 0, 0, .7);
}

.popup-wrap .inner-box .slick-prev {
    left: -64px;
}

.popup-wrap .inner-box .slick-next {
    right: -64px;
}

.popup-wrap .inner-box .slick-prev:before,
.popup-wrap .inner-box .slick-next:before {
    -webkit-filter: grayscale(1) invert(1);
}

.popup-wrap .popup-overlay {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
    background: rgba(0, 0, 0, 0.7);
}

.popup-wrap.on {
    display: flex;
}

.popup-wrap.on ~ .popup-btn-box .popup-close {
    display: flex;
}

.popup-btn-box .popup-close {
    display: none;
}

.popup-btn-box {
    position: fixed;
    right: 1em;
    bottom: 1em;
    z-index: 9999;
}

.popup-btn-box a {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 64px;
    height: 64px;
    box-shadow: 0px 1px 3px 0px #d4d4d5, 0px 0px 0px 1px #d4d4d5;
    border-radius: 100%;
    background: #fff;
}

.popup-btn-box a i {
    font-size: 2.5em;
}

.popup-btn-box a:last-child {
    margin-top: 10px;
}

.popup-btn-box .popup-close i {
    font-size: 2.5em;
}

.popup-btn-box .popup-open i {
    font-size: 1.5em;
}

.popup-btn-box a:last-child label {
    position: absolute;
    right: 0;
    bottom: 0;
}

/* tree menu type CSS */
.treeview .hitarea {
    background: url(../img/treeview-default.gif) 0 -35px no-repeat;
    height: 20px;
    width: 20px;
    margin-left: -20px;
    float: left;
    cursor: pointer;
}

.treeview li {
    padding: 5px 0 5px 20px;
}

.treeview a.selected {
    background-color: #eee;
}

.treeview li {
    background: url(../img/treeview-default-line.gif) 0 0 no-repeat;
    cursor: pointer;
}

.treeview li.collapsable, .treeview li.expandable {
    background-position: 0 -170px;
}

.treeview .expandable-hitarea {
    background-position: 0 -5px !important;
}

.treeview li.last {
    background-position: 0 -1758px;
}

.treeview li.lastCollapsable, .treeview li.lastExpandable {
    background-image: url(../img/treeview-default.gif);
}

.treeview li.lastCollapsable {
    background-position: 0 -30px;
}

.treeview li.lastExpandable {
    background-position: 0 0;
}

.treeview div.lastCollapsable-hitarea, .treeview div.lastExpandable-hitarea {
    background-position: 0 -35px;
}

.filetree span.folder, .filetree span.file {
    padding-left: 30px;
    display: block;
}

.filetree span.folder {
    background: url(../img/folder-closed.gif) 0 50% no-repeat;
}

.filetree li.collapsable > span.folder {
    background: url(../img/folder.gif) 0 50% no-repeat;
}

.filetree span.file {
    background: url(../img/file.gif) 0 50% no-repeat;
}

.file-bar .ui.progress {
    width: 300px;
}

.file-bar .ui.progress > .label {
    text-align: right;
}

.label-roundType {
    display: inline-flex;
    align-items: center;
    margin: .25em;
    padding: 5px 5px 5px 20px;
    border: 1px solid #e1e1e1;
    border-radius: 3px;
    box-shadow: 0px 1px 3px #f1f1f1;
}

.label-roundType label {
    display: inline-block;
    width: 42px;
    height: 42px;
    margin-right: 5px;
    font-size: 1.25em;
    color: #fff;
    line-height: 42px;
    text-align: center;
    vertical-align: middle;
    border-radius: 100%;
}

.label-roundType .emt {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.label-roundType .emt small {
    color: #999;
}

.label-roundType button {
    margin-right: 10px;
}

.label-roundType .emt-left {
    color: #999;
}

.label-roundType .emt-left span {
    margin-right: 1em;
    vertical-align: middle;
}

.label-roundType .emt-right {
    margin-left: auto;
}

.label-roundType .emt-right span {
    margin-right: 10px;
}

.label-roundType .emt-right button {
    margin: 0 .75em;
}

.label-roundType .emt-right a i {
    margin-right: 10px;
}

.part-list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -.25em;
    padding-bottom: 2em;
}

.part-list .label-roundType {
    flex: 1;
    cursor: pointer;
}

.part-list .label-roundType.active {
    border-color: #666;
}

.part-list .label-roundType input[type=checkbox] {
    display: none;
}

.table-inner-list {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.table-inner-list .item label {
    display: inline-block;
    min-width: 80px;
}

ul.table-inner-week {
    display: flex;
    flex-wrap: wrap;
}

ul.table-inner-week li {
    display: flex;
    align-items: center;
    width: calc(20% - 6px);
    margin: 3px;
    padding: 5px;
    border: 1px solid #d7d7d7;
    border-radius: 3px;
    background: #f9f9f9;
}

ul.table-inner-week li label {
    flex: 1;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.toggle-icon {
    opacity: 1 !important;
}

.toggle-icon:before {
    margin-left: 0 !important;
}

#test-advance {
    position: relative;
    width: 140px;
    height: 140px;
    padding-top: 60px;
    font-size: 1.5em;
    font-weight: 500;
    color: #fff;
    border: 5px solid rgba(255, 255, 255, .2);
    border-radius: 100%;
    transition: all 0.2s;
}

#test-advance:hover {
    border-color: rgba(255, 255, 255, .6);
}

#test-advance:before {
    content: '\f1da';
    position: absolute;
    top: 20px;
    left: 50%;
    font-family: 'Icons';
    font-size: 2em;
    color: #fff;
    transform: translateX(-50%);
    z-index: 1;
}

.warning-text {
    display: flex;
    align-items: center;
    background-color: rgba(0, 0, 0, .7);
    transition: all 0.2s;
}

.warning-text .modal-title {
    font-size: 1.125em;
    font-weight: 400;
}

.warning-text .close {
    margin-left: auto !important;
    font-size: 1em !important;
}

.c-miniLabel {
    display: inline-block;
    min-width: 18px;
    min-height: 18px;
    line-height: 18px;
    font-size: 12px;
    color: #fff;
    text-align: center;
    border-radius: 100%;
    background: #999;
}

/* TOAST UI CSS - 210708 */
.calendar-wrap .calendar-header {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.calendar-wrap .calendar-header .setting-button {
    display: none;
}

.calendar-wrap .calendar-header .month-range {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
}

.calendar-wrap .calendar-header .month-range button {
    margin: 0;
}

.calendar-wrap .calendar-header .month-range .render-range {
    margin: 0 20px;
    font-size: 2em;
    font-weight: 700;
}

.calendar-wrap .calendar-header .setting-button,
.calendar-wrap .calendar-header .move-today {
    font-size: 1.25em;
}

.calendar-wrap .calendar-content {
    display: flex;
}

.lnb-schedule {
    width: 200px;
    margin-right: 20px;
}

.lnb-schedule .ui.dropdown a {
    display: block;
}

.lnb-schedule .lnb-new-schedule {
    margin-top: 10px;
}

.lnb-schedule .lnb-calendars .lnb-calendars-item {
    margin-top: 10px;
}

.calendar-wrap .calendar-content #calendar {
    flex: 1;
}

.calendar-wrap .calendar-content #calendar .tui-full-calendar-month-week-item,
.calendar-wrap .calendar-content #calendar .tui-full-calendar-weekday-schedule {
    margin: 0 !important;
}

.calendar-wrap .calendar-content #calendar .tui-full-calendar-weekday-schedule-time {
    margin: 0 2px !important;
}

.calendar-wrap .calendar-content #calendar .tui-full-calendar-weekday-schedule-title {
    text-overflow: inherit;
}

/*** subject_type01_list  manage_buttons 210820 ***/
.manage_buttons {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
.manage_buttons .ui.buttons {
    margin: 0.1em;
}
.manage_buttons a.ui.basic.button:hover {
    font-weight: 500;
}
.manage_buttons a.ui.basic.button.red:hover {
    background: transparent !important;
    box-shadow: none;
    font-weight: 500;
}
div.ui.basic.buttons a.ui.button {
    transition: all 0.2s ease;
}
div.ui.basic.buttons a.ui.button.save_btn,
div.ui.basic.buttons a.ui.button.approv_btn {
    background: #999 !important;
    color: #fff !important;
}
div.ui.basic.buttons a.ui.button.del_btn:hover {
    background: #fa5252 !important;
    color: #fff !important;
    box-shadow: none;
}
div.ui.basic.buttons a.ui.button.save_btn:hover {
    background: #2185d0 !important;
    color: #fff !important;
    box-shadow: none;
}
div.ui.basic.buttons a.ui.button.approv_btn:hover {
    background: #2185d0 !important;
    color: #fff !important;
    box-shadow: none;
}
div.ui.basic.buttons a.ui.button.wait_btn:hover {
    background: #f2711c !important;
    color: #fff !important;
    box-shadow: none;
}
div.ui.basic.buttons a.ui.button.disableLink, div.ui.basic.buttons a.ui.button.del_btn.disableLink:hover {
    cursor: not-allowed !important;
    background: #ccc !important;
    color: #333 !important;
    box-shadow: none;
}

/* 241203수정 */
@media all and (max-width: 1024px) {
    .gn-menu-wrapper.gn-open-all {
        width: 50%;
    }
    .gn-scroller {
        width: 100%;
    }
}
@media all and (max-width: 650px) {
    .gn-menu-wrapper.gn-open-all {
        width: 70%;
    }
    .gn-scroller {
        width: 100%;
    }
}
/* //241203수정 */

/*************************************
메뉴 아코디언 _ 241203수정
**************************************/
.gn-menu-wrapper .gn-menu > li .gn-submenu {
    display: none;
}

.gn-menu-wrapper .gn-menu > li.open .gn-submenu {
    display: block;
}
.gn-menu-wrapper .gn-menu > li > a {
    position: relative;
}
.gn-menu-wrapper .gn-menu > li > a:after {
    font-family: 'Ionicons';
    content: '\f3d0';
    position: absolute;
    top: 50%;
    right: 25px;
    transform: translateY(-40%);
    color: rgb(255, 255, 255, 0.7);
    -webkit-transform: translateY(-40%);
    -moz-transform: translateY(-40%);
    -ms-transform: translateY(-40%);
    -o-transform: translateY(-40%);
}

.gn-menu-wrapper .gn-menu > li.open > a:after {
    font-family: 'Ionicons';
    content: '\f3d8';
    position: absolute;
    top: 50%;
    right: 25px;
    transform: translateY(-40%);
    color: rgb(255, 255, 255, 0.7);
    -webkit-transform: translateY(-40%);
    -moz-transform: translateY(-40%);
    -ms-transform: translateY(-40%);
    -o-transform: translateY(-40%);
}
/******************************************************/

table.table tbody td p {
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

/* info_list */
/* .inline.field {
    display: flex;
    flex-wrap: wrap;
}
.inline.field .label-title {
    width: 20%;
}
.inline.field .despt {
    flex: 1;
} */
/**************************** MEDIA QUERY **********************************/
@media all and (max-width:1280px) {
    .cbp_tmtimeline {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
        margin-bottom: 180px;
        padding-right: 0;
        border-right: none;
        box-shadow: none;
    }

    .cbp_tmtimeline:before {
        display: none;
    }

    .cbp_tmtimeline > li {
        position: static;
        width: calc(12.5% - .5em);
        margin: .25em;
    }

    .cbp_tmtimeline > li .cbp_tmicon,
    .cbp_tmtimeline > li:before {
        display: none;
    }

    .cbp_tmtimeline > li .cbp_box {
        padding: 0;
    }

    .cbp_tmtimeline > li .cbp_tmtime {
        display: block;
    }

    .cbp_tmtimeline > li .cbp_tmtime span {
        display: block;
        text-align: center;
    }

    .cbp_tmtimeline > li .cbp_tmtime small {
        display: none;
    }

    .cbp_tmtimeline > li .cbp_box .ui.segment {
        position: absolute;
        left: .25em;
        bottom: -160px;
        width: calc(100% - .5em);
        height: 150px;
        overflow-y: auto;
        z-index: 1;
    }

    .cbp_tmtimeline .ui.segment li.mo {
        display: block;
        padding-bottom: 5px;
        border-bottom: 1px solid #d7d7d7;
    }

    .cbp_tmtimeline .ui.segment li.mo span {
        font-size: 1.25em;
        font-weight: 500;
    }

    .cbp_tmtimeline .ui.segment li.mo small {
        display: inline-block;
        margin-left: 10px;
        color: #6b758d;
    }

    .post_list .post_label {
        display: block;
    }

    .post_list .post_disc {
        position: absolute;
        top: 0.5em;
        right: 1.125em;
    }

    .post_list > ul > li {
        padding: 0.5em 0.8em;
    }

    .post_list li.reply .post_disc {
        display: none;
    }

    /* .post_list > ul > li > a {
        width: 100%;
    } */

    .post_gallery > ul {
        width: calc(33.33% - 20px);
    }

    .grid-column,
    .grid-column-lg,
    .grid-column-md,
    .grid-column-sm,
    .grid-column-xs {
        float: none;
        width: 100%;
    }

    .size_1, .size_2, .size_3 {
        width: calc(50% - 20px) !important;
    }

    .size_1.tblet_fluid,
    .size_2.tblet_fluid,
    .size_3.tblet_fluid,
    .size_4, .size_5, .size_6 {
        width: calc(100% - 20px) !important;
    }

    /*** cheathing_notify_board.jsp cheat_list 210811 ***/
    .post_list.cheat_list .post_label {
        display: inline-block;
    }

    .post_list.cheat_list .post_disc {
        position: static;
    }


    .post_gallery > ul {
        width: calc(33.33% - 20px);
    }

    .grid-column,
    .grid-column-lg,
    .grid-column-md,
    .grid-column-sm,
    .grid-column-xs {
        float: none;
        width: 100%;
    }

    .size_1, .size_2, .size_3 {
        width: calc(50% - 20px) !important;
    }

    .size_1.tblet_fluid,
    .size_2.tblet_fluid,
    .size_3.tblet_fluid,
    .size_4, .size_5, .size_6 {
        width: calc(100% - 20px) !important;
    }

    .ui.grid > .column .grid-main-box.size_1,
    .ui.grid > .column .grid-main-box.size_2,
    .ui.grid > .column .grid-main-box.size_3 {
        width: 50% !important;
    }

    .ui.grid > .column .grid-main-box.size_1.tblet_fluid,
    .ui.grid > .column .grid-main-box.size_2.tblet_fluid,
    .ui.grid > .column .grid-main-box.size_3.tblet_fluid,
    .ui.grid > .column .grid-main-box.size_4,
    .ui.grid > .column .grid-main-box.size_5,
    .ui.grid > .column .grid-main-box.size_6 {
        width: 100% !important;
    }

    .ui.grid > .column:not(.row) > .grid-main {
        margin-left: 0;
        margin-right: 0;
    }

    .grid-main-box > .header h2.week-list {
        display: block;
        width: calc(100% - 40px);
        padding-bottom: 5px;
    }

    .grid-main-box > .header .addBtn {
        margin-right: 0;
    }

    .variable-box {
        position: relative !important;
        border-left: none;
        border-top: 1px solid #d7d7d7;
    }

    .ui.grid > .column:not(.row).variable-box.full {
        width: 100% !important;
    }

    .ui.grid > .column:not(.row).variable-box.full:before {
        content: '';
        position: absolute;
        left: 0;
        top: -2em;
        width: 100%;
        height: 3em;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 70%, rgba(0, 0, 0, .1) 100%);
    }

    .variable-box .slide-btn {
        display: block;
        width: 64px;
        height: 16px;
        left: calc(50% - 32px);
        top: -8px;
        line-height: 0;
    }

    .variable-box .slide-btn:before {
        display: inline-block;
        content: '';
        width: 48px;
        height: 1px;
        margin: 5px 0 0 0;
    }

    .variable-box .slide-btn:after {
        display: inline-block;
        content: '';
        width: 48px;
        height: 1px;
        margin: 4px 0 0 0;
    }

    .tip-box {
        height: 0;
        padding: 0 !important;
        overflow: hidden;
    }

    .ui.grid > .column:not(.row).tip-box.show {
        height: auto;
        padding: 1em !important;
    }

    .mobile-hide {
        display: none !important;
    }

    .ui.celled.grid > .column:not(.row), .ui.celled.grid > .row > .column {
        box-shadow: none !important;
    }

    aside.content-add-box {
        display: none;
    }

    .mo-modal-height {
        min-height: 850px;
    }

    #info-item-box .button-area {
        margin: 0.3em 0;
    }
}

@media all and (max-width:1024px) {
    /****  .gn-menu-main ****/
    .gn-menu-main {
        position: absolute;
        z-index: initial;
    }

    .no-touch .gn-menu-main .gn-trigger > a {
        display: block;
        background: #fff;
    }

    .gn-menu-wrapper {
        position: fixed;
        top: 0px;
        bottom: 0;
        left: -60px;
        overflow: hidden;
        width: 60px;
        background: white;
        -webkit-transform: translateX(-60px);
        -moz-transform: translateX(-60px);
        transform: translateX(-60px);
        -webkit-transition: -webkit-transform 0.3s, width 0.3s;
        -moz-transition: -moz-transform 0.3s, width 0.3s;
        transition: transform 0.3s, width 0.3s;
        background: #006eb6;
        z-index: 1001;
    }

    .gn-trigger~.overlay {
        display: none;
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background: rgba(0, 0, 0, .7);
        z-index: 1000;
    }

    .gn-trigger~.overlay:before {
        position: absolute;
        top: 0;
        right: 20px;
        content: '\f404';
        font-family: "Ionicons";
        font-size: 4em;
        color: #fff;
    }

    .gn-menu-wrapper.gn-open-all {
        -webkit-transform: translateX(60px);
        -moz-transform: translateX(60px);
        transform: translateX(60px);
    }

    .no-touch .gn-menu-main .gn-trigger > a {
        border-right: none;
    }
}

@media all and (max-width:1024px) {
    .post_list.cheat_list > ul > li > a {
        width: calc(100% - 160px);
    }

    .post_list.cheat_list .ui.dropdown {
        margin-left: 15px;
    }

    .post_list.cheat_list .post_disc {
        position: static;
    }

    .option-content .ui.message {
        padding: 10px 15px;
        margin-left: 0em;
    }

}


@media all and (max-width:768px) {
    .flex-container .cont-none .text {
        font-size: 2em;
        font-weight: bold;
    }
    .flex-container .cont-none img {
        width: 80%;
    }

    .post_list > ul > li > a {
        display: inline-flex;
        align-items: center;
        width: calc(100% - 160px);
        /* font-size: 1.125em; */
    }

    /* .ui.dropdown,
    .ui.input,
    .ui.buttons,
    .ui.button,
    .half-box,
    .button-area a,
    .button-area button,
    .field input,
    .field input + .ui,
    .field .ui.checkbox {
        margin-top: 2px !important;
        margin-bottom: 2px !important;
    } */

    .ui.card > .button,
    .ui.cards > .card > .button,
    .ui.input > .ui.button,
    .ui.action.input > .ui.button,
    .ui.action.input > .ui.dropdown,
    .ui.buttons > * {
        margin: 0 !important;
    }

    .portlet-placeholder {
        width: 48.6%;
    }

    .option-content .head-txt {
        font-size: 16px;
    }

    .option-content .mo-show {
        display: inline-block !important;
    }

    .pageSlider {
        width: 80%;
    }

    .tbl.tablet > thead,
    .tbl.tablet > tfoot,
    .tbl.tablet > tbody,
    .tbl.tablet > thead > tr > th,
    .tbl.tablet > tbody > tr > td,
    .tbl.tablet > tbody > tr {
        display: block;
    }
    .tbl.tablet > thead > tr,
    .tbl.tablet > tfoot > tr,
    .tbl.tablet > tbody.sch_data > tr > th {
        position: absolute;
        top: -9999px;
        left: -9999px;
    }
    .tbl.tablet > tbody > tr {
        border-bottom: 1px solid #333;
    }
    .tbl.tablet > tbody > tr:last-child {
        border-bottom: none;
    }
    .tbl.tablet.td-sm > tbody > tr > td {
        padding-left: 15%;
    }
    .tbl.tablet.td-md > tbody > tr > td {
        padding-left: 25%;
    }
    .tbl.tablet.td-lg > tbody > tr > td {
        padding-left: 30%;
    }
    .tbl.tablet > tbody > tr > td {
        border: none;
        border-bottom: 1px solid #eee;
        position: relative;
        padding-left: 30%;
        white-space: normal;
        text-align: left;
    }
    .privacy_plcy .tbl.tablet > tbody > tr > td {
        border: none;
        border-bottom: 1px solid #eee;
        position: relative;
        padding-left: 40%;
        white-space: normal;
        text-align: left;
    }
    .tbl.tablet > tbody > tr > td:last-child {
        border-bottom: none;
    }
    .tbl.tablet > tbody > tr > td:before {
        position: absolute;
        top: 50%;
        left: 6px;
        padding-right: 10px;
        white-space: nowrap;
        text-align: left;
        font-weight: 500;
        transform: translateY(-50%);
    }

    .tbl.tablet > tbody > tr > td:before {
        content: attr(data-title);
    }
    .tbl tbody.sch_data > tr > th, .tbl tbody.sch_data > tr > td {
        width: auto;
    }
    /*
    .tbl > li > dl > dt {width: 160px;}
    .tbl.dt-sm > li > dl > dt {width:80px;}
    .tbl.dt-md > li > dl > dt {width:200px;}
    .tbl.dt-lg > li > dl > dt {width:280px;}
    */
    .ui.message .tbl.tablet > tbody > tr > td {
        border: none;
        border-bottom: 1px solid #cce7ec;
    }

    .ui.message .tbl.tablet > tbody > tr > td:last-child {
        border-bottom: none;
    }

    .tbl tbody td ul.device-list li {
        padding: 10px 0;
        border-bottom: 1px dotted #eee;
    }

    .tbl tbody td ul.device-list li:first-child {
        padding-top: 0;
    }

    .tbl tbody td ul.device-list li:last-child {
        padding-bottom: 0;
        border-bottom: none;
    }

    .tbl tbody td ul.device-list li span {
        width: 100%;
        line-height: normal;
        white-space: normal !important;
    }

    .tbl tbody td ul.device-list.type2 li span {
        width: 100%;
        line-height: normal;
        white-space: normal !important;
        background: url(../img/icon_dot.gif) left 8px no-repeat;
    }

    .tbl tbody td ul.device-list.type2 li > strong {
        width: 100%;
        padding-left: 0;
    }

    .tbl tbody td ul.device-list.type2 li > .ui.input {
        margin: 2px 0;
    }

    .grid-table {
        border-right: 1px solid #cfcfcf;
    }

    .grid-table th.head,
    .grid-table td.head {
        display: none;
    }

    .grid-table tr.mo {
        display: table-row;
    }

    .grid-table tr.mo td {
        padding: 5px;
        text-align: left;
    }

    .question-list > ul > li.on dl dt,
    .question-list dl dt {
        background-size: 24px 24px, 10px 6px;
    }

    .question-list dl dd .answer {
        background-size: 24px 24px
    }

    .question-list.faq-type dl dt:before {
        width: 24px;
        height: 24px;
        line-height: 24px;
    }

    .gridster > ul {
        width: auto !important;
        height: inherit !important;
    }

    .gridster .gs-w {
        position: relative !important;
        top: auto;
        left: auto;
        width: auto;
        margin: 20px 0 !important;
    }

    [data-sizey="1"], [data-sizey="2"], [data-sizey="3"] {
        height: auto !important;
    }

    .gridster .gs-content {
        height: auto;
    }

    .tablet-show {
        display: block !important;
    }

    .location-wrap .ui.menu > a.item {
        display: none !important;
    }

    .ui.right.sidebar.inquiry-inbox {
        width: 90%;
    }

    .ui.right.sidebar.wide-inbox {
        width: 90%;
    }

    .btn span {
        line-height: 38px;
    }

    .btn-icon {
        width: 48px;
        height: 48px;
    }

    .btn-icon i {
        width: 20px;
        height: 20px;
        margin: -10px 0 0 -10px;
    }

    .btn-icon:hover {
        font-size: 0;
    }

    .btn-icon:hover i {
        opacity: .3;
    }

    .btn-icon:hover.btn-primary i {
        opacity: 1;
    }

    .ui.right.sidebar.wide-inbox .close {
        width: 16px;
        height: 16px;
        background-size: cover;
    }

    .upload ul li {
        width: 50%;
    }

    .label-title {
        width: 30% !important;
    }

    .ui.info-type .card > .content > .info-table {
        margin-left: -1em !important;
        margin-right: -1em !important;
        letter-spacing: -.5px;
    }

    .ui.info-type .card > .content > .info-table > .column:last-child {
        margin-top: -2em !important;
    }

    .ui.info-type .card > .content > .info-table .label-title-lg {
        display: inline-block !important;
        width: inherit;
    }

    #info-item-box {
        margin: 0 -1em;
        padding: 1em;
    }

    #info-item-box.ui.sticky.fixed {
        padding: 0 1em;
    }

    /*    .option-content {flex-wrap: wrap;}*/
    .option-content.mo-wrap {
        display: flex !important;
    }

    .option-content.mo-wrap .search-box {
        flex: 1;
    }

    .option-content.mo-wrap .search-box.ui.action.input > input[type='text'] {
        width: 160px;
    }

    .option-content.mo-wrap .mo-show {
        margin-left: 10px;
    }

    .option-content.mo-wrap .button-area .btn-choice {
        display: none;
    }

    #info-item-box .button-area .btn-choice {
        flex: 1;
        margin-right: 0;
    }

    #info-item-box .button-area .mo-fluid {
        margin-right: 0;
    }

    ul.slider-list {
        width: 100%;
    }

    ul.thumb-extra li label {
        font-size: .75em;
    }

    .popup-wrap .inner-box {
        width: 70%;
    }

    .popup-btn-box a {
        width: 48px;
        height: 48px;
    }

    .popup-btn-box a:last-child label {
        right: -5px;
        bottom: -5px;
    }

    .line-sortable-box {
        flex-direction: column-reverse;
    }

    .line-sortable-box .account-list .line-box {
        flex-direction: column;
    }

    .line-sortable-box .account-list .line-box .question {
        margin-right: 0;
    }

    .line-sortable-box .account-list .line-box .question:after {
        display: none;
    }

    .line-sortable-box .account-list .line-box .slot {
        width: 100%;
        min-height: 46px;
        border-top: none;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }

    .line-sortable-box .inventory-list {
        margin-left: 0;
    }

    .layer-group .layerPopup {
        left: 50% !important;
        top: 50% !important;
        transform: translate(-50%, -50%) !important;
        max-width: 90% !important;
        max-height: 90vh !important;
    }

    .warning-text .modal-title {
        font-size: 1em;
    }
    /* Arrows */
    .slick-prev,
    .slick-next {
        width: 30px;
        height: 30px;

    }
    .codeList_add {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 15px;
    }
    .codeList_add .field_box {
        width: 100%;
        padding: 5px 0;
    }

    .gn-menu-main .gn-trigger a {
        border-right: none;
    }

    .privacy_plcy {
        padding: 0em !important;
        margin-top: 3em !important;
    }

    .tbl.row_t {
        display: block;
        text-align: center;
    }
    .tbl.row_t > li {
        border-top: 1px solid #cdcdcd;
    }
    .tbl.row_t > li > dl > dt {
        border-left: none;
    }
    .tbl.row_t > li > dl > dd {
        border-left: none;
    }

    .tbl.tablet.perf_table colgroup {
        display: none;
    }
    .tbl.tablet.perf_table > tbody > tr > td {
        border: none;
        border-bottom: 1px solid #eee;
        position: relative;
        padding-left: 30%;
        white-space: normal;
        text-align: center;
    }

}

@media all and (max-width:767px) {
    .size_1, .size_2, .size_3, .size_4, .size_5, .size_6 {
        width: calc(100% - 20px) !important;
    }

    .portlet-placeholder {
        width: 98.6%;
    }

    .post_gallery > ul {
        width: calc(50% - 20px);
    }

    .post_gallery > ul > li .subject {
        font-size: 16px;
    }

    .tbl > li > dl > dd span.sec {
        display: block;
    }

    .average > .ui.stackable.grid > .column {
        padding: 5px !important;
        text-align: left;
    }

    .global_tab a {
        padding: 10px 0;
        /*font-size: 13px;*/
        border-bottom: 1px solid rgba(34, 36, 38, 0.15)
    }

    .grid-main-box,
    .grid-main-box > .content {
        min-height: auto;
    }

    .listTab {
        position: relative;
        padding-top: 45px;
        border-bottom: none;
    }
    .listTab ul li {
        display: none;
        float: none;
        margin-right: 0;
        border-top: none;
        border-bottom: 1px solid #d9d9d9;
    }

    .listTab ul li.on {
        display: block;
    }

    .listTab ul li.select {
        display: block;
        position: absolute;
        top: 0;
        width: 100%;
        margin-top: 0;
        border-bottom: 1px solid #333;
    }

    .listTab ul li.select:before {
        position: absolute;
        top: .35em;
        right: .5em;
        content: '\f130';
        font-family: "Ionicons";
        font-size: 2em;
        z-index: 1;
    }
    .login-box .listTab ul li.select:before {
        position: absolute;
        top: .25em;
        right: .5em;
        content: '\f130';
        font-family: "Ionicons";
        font-size: 2em;
        z-index: 1;
    }
    .listTab ul li.select a {
        cursor: pointer;
    }
    .listTab.login_tab ul li a {
        padding: 12px 10px;
        font-size: 1.12em;
    }

    .ui.form .inline.fields.mo-flex1-row {
        display: block;
    }

    .ui.form .ui.info-type .card.fab-field {
        display: flex !important;
        padding: 1em !important;
    }

    .ui.form .ui.info-type .card.fab-field .menu {
        width: 100%;
    }

    .ratings-column {
        flex-direction: column;
    }

    .ratings-column .box:before {
        width: 100%;
        height: 1px;
        top: inherit;
        bottom: 0;
    }

    .reponsive-table {
        overflow-x: auto;
    }

    .reponsive-table table {
        width: 720px !important;
    }

    /* CI Card CSS - 210705 */
    .ui.ci-type .arrow-step:before {
        content: '\f103';
        font-family: 'Ionicons';
    }

    /* TOAST UI CSS - 210708 */
    .calendar-wrap .calendar-header .setting-button {
        display: block;
    }

    .calendar-wrap .calendar-content {
        flex-direction: column;
    }

    .calendar-wrap .calendar-header .month-range .render-range {
        font-size: 1.25em;
    }

    .lnb-schedule {
        padding: 1em;
        background: #fff;
    }

    .calendar-wrap .calendar-content #calendar .tui-full-calendar-floating-layer {
        width: 100%;
    }
    .calendar-wrap .calendar-content #calendar .tui-full-calendar-popup {
        width: 100%;
    }
    .calendar-wrap .calendar-content #calendar .tui-full-calendar-popup-container {
        width: 100%;
        min-width: auto !important;
    }
    .calendar-wrap .calendar-content #calendar .tui-full-calendar-section-title input,
    .calendar-wrap .calendar-content #calendar .tui-full-calendar-popup-section-item.tui-full-calendar-section-location input {
        width: inherit;
    }

    .calendar-wrap .calendar-content #calendar .tui-full-calendar-popup-section-item:first-child {
        margin-bottom: 5px;
    }

    .calendar-wrap .calendar-content #calendar .tui-full-calendar-weekday-schedule-title strong {
        display: none;
    }
    .card-item-center .title-box {
        display: flex;
        align-items: center;
        /* line-height: 24px; */
        overflow: hidden;
        margin-top: 1.2em;
    }
    .ui.info-type .card.fab-field {
        min-height: 180px;
    }
}
@media all and (max-width:600px) {
    .inline.field .label-title {
        width: 100% !important;
    }
    .option-content div {
        margin-bottom: 3px;
    }
}
@media all and (max-width:480px) {
    .post_list .post_label span {
        display: inline-block;
        min-width: 20px;
    }
    .post_list > ul > li > a {
        width: 100%;
    }
    .content {
        font-size: 14px;
    }
    .cbp_tmtimeline > li {
        width: calc(16.6% - .5em);
    }

    .cbp_tmtimeline > li .cbp_tmtime span {
        font-size: 1em;
    }

    .open-class > li .cbp_tmicon {
        float: left;
        left: 8px;
    }

    .open-class > li .cbp_box {
        margin-top: 80px;
    }

    .open-class > li .cbp_tmlabel .header {
        font-size: 15px;
    }

    .open-class .line-clip {
        width: 100%;
        margin-bottom: 5px;
    }
    /**   .option-content **/
    .option-content .search-box {
        width: 100%;
    }

    .option-content .listType + .search-box {
        flex: 1;
    }

    .option-content .button-area {
        float: none;
        text-align: left;
    }

    .option-content .list-num {
        display: none !important;
    }

    .option-content .totalNum {
        display: block;
        font-size: 15px;
        color: #333;
        vertical-align: middle;
    }

    .flex-box .button-area {
        text-align: right;
    }

    .tbl.tablet.td-sm > tbody > tr > td {
        padding-left: 20%;
    }

    .tbl.tablet.td-md > tbody > tr > td {
        padding-left: 30%;
    }

    .tbl.tablet.td-lg > tbody > tr > td {
        padding-left: 40%;
    }

    .tbl > thead,
    .tbl > tbody,
    .tbl > thead > tr > th,
    .tbl > tbody > tr > td,
    .tbl > tbody > tr {
        display: block !important;
    }

    .tbl.state_box > thead,
    .tbl.state_box > tbody,
    .tbl.state_box > thead > tr > th,
    .tbl.state_box > tbody > tr > td,
    .tbl.state_box > tbody > tr {
        display: revert !important;
    }

    .tbl > thead > tr {
        position: absolute;
        top: -9999px;
        left: -9999px;
    }

    .tbl > tbody > tr {
        border-bottom: 1px solid #333;
    }

    .tbl > tbody > tr:last-child {
        border-bottom: none;
    }

    .tbl > tbody > tr > th {
        display: block;
    }

    .tbl > tbody > tr > td {
        border: none;
        border-bottom: 1px solid #eee;
        position: relative;
        padding-left: 40%;
        white-space: normal;
        text-align: left;
        /* min-height: auto; */
    }

    .tbl > tbody > tr > td.title {
        padding-left: 0 !important;
    }

    .tbl > tbody > tr > td:last-child {
        border-bottom: none;
    }

    .tbl > tbody > tr > td:before {
        position: absolute;
        top: 8px;
        left: 6px;
        padding-right: 10px;
        white-space: nowrap;
        text-align: left;
        font-weight: 500;
    }

    .tbl > tbody > tr > td:before {
        content: attr(data-title);
    }

    .tbl > tbody > tr > td.head {
        display: block;
        border-right: none;
    }

    .tbl > tbody > tr > td.lg-section,
    .tbl > tbody > tr > td.md-section,
    .tbl > tbody > tr > td.sm-section {
        padding-left: 0;
        background: none;
    }

    .tbl > li > dl {
        display: block;
    }

    .tbl > li > dl > dt {
        width: 100% !important;
        display: block;
        padding: 8px 12px;
    }

    .tbl > li > dl > dt:nth-child(2),
    .tbl > li > dl > dt:nth-child(3) {
        border-top: 1px solid #dcdcdc;
        border-left: none;
    }

    .tbl > li > dl > dd {
        display: block;
        min-height: 37px;
        padding: 8px 12px;
        border: none;
        border-top: 1px solid #dcdcdc;
    }

    .tbl > li > dl > dt:nth-child(2),
    .tbl > li > dl > dt:nth-child(3),
    .tbl > li > dl > dt:nth-child(5) {
        border-left: none;
    }


    .ui.message .tbl > tbody > tr > td {
        border: none;
        border-bottom: 1px solid #cce7ec;
    }

    .ui.message .tbl > tbody > tr > td:last-child {
        border-bottom: none;
    }

    .tbl-simple > li > dl > dt {
        width: 100%;
        display: block;
        padding-bottom: 5px;
        font-weight: 500;
        text-align: left;
    }

    .tbl-simple > li > dl > dd {
        display: block;
    }

    .tbl-simple > li > dl > dd .inline.fields > label {
        display: block !important;
        width: 100% !important;
    }

    ul.review > li .view-img {
        display: none;
    }

    ul.review li dl {
        width: 100%;
    }
    .tbl.tbl_flex {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        border-top: 1px solid #e1e4e8;
        border-bottom: 1px solid #e1e4e8;
    }
    .tbl.tbl_flex li {
        width: 100%;
    }
    .tbl.tbl_flex > li:first-child {
        border-right: none;

    }
    .tbl.tbl_flex > li {
        border-top: 1px solid #333;
    }
    .tbl.tbl_flex > li:last-child {
        border-bottom: none;
    }
    .tbl.tbl_flex > li dd > p > input {
        width: 85% !important;
        vertical-align: middle !important;
    }

    /* Pagination 요소 */
    .pagination {}

    .pageForm {
        display: block;
        margin-top: 20px;
    }

    .pageInput {
        width: 50px !important;
        margin: 0;
    }

    .upload ul li {
        width: 100%;
    }


    .post_view .header .inner-box {
        flex-direction: column;
    }

    .post_view .header ul.file li {
        display: block;
        padding: 2px 0;
    }

    .post_view .header ul.file li:before {
        display: none;
    }

    .post_view .header ul.viewInfo li {
        padding: 3px 10px 3px 0;
    }

    .post_view .header ul.viewInfo li:before {
        display: none;
    }

    .post_view .header ul.viewInfo.file li {
        width: 100%;
    }

    .comment ul.comment-write,
    .comment .article > ul {
        padding-left: 0;
    }

    .comment .article > ul > li {
        width: 100%;
    }

    .comment ul.comment-write > li {
        float: none;
        width: 100%;
    }

    .comment ul.comment-write > li:last-child {
        margin-left: 0;
    }

    .comment ul.comment-write > li.imgBox,
    .comment .article > ul > li.imgBox {
        display: none;
    }

    .comment .article > ul.co_inner {
        padding-left: 10%;
        background-position: 0 10px;
        background-size: 16px;
    }

    .comment .title h2 {
        flex-basis: 100%;
    }

    .comment .title h2 .ui.button {
        width: 100%;
    }

    .comment .title .ui.button.exclusive_btn {
        flex-basis: 50%;
        margin-right: 0;
    }

    .comment .title .ui.button {
        flex: 1;
    }

    .post_blog > ul > li {
        flex-direction: column;
    }

    .post_blog > ul > li > p.view-list-img {
        float: none;
        width: 100%;
        margin-right: 0
    }

    .post_blog > ul > li > .content_txt {
        margin: 10px 0 0 0;
    }

    .post_gallery > ul > li .subject,
    .wrap_btn .fluid-btn {
        width: 100%;
        margin-bottom: 5px !important;
    }

    .global_tab {
        margin: 10px 0;
    }

    .ui.right.sidebar.inquiry-inbox {
        width: 100%;
    }

    .ui.right.sidebar.wide-inbox,
    .ui.right.sidebar.wide-inbox2 {
        width: 100%;
    }

    .mobile-show {
        display: block !important;
    }

    .paging a {
        display: none;
    }

    .paging a.current {
        display: inline-block;
    }

    .classin {
        display: none;
    }

    .ui.message .header.small ul.viewInfo li:before {
        display: none;
    }

    .ui.message .header.small ul.viewInfo li span {
        display: inline-block;
        padding: 0 !important;
    }

    .ui.form .inline.fields > label.w150 {
        width: 100px !important;
    }

    .header.small ul.viewInfo {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }

    .header.small ul.viewInfo li {
        padding: 3px 10px 3px 0;
    }

    .header.small ul.viewInfo li:before {
        display: none;
    }

    .grid-main-box > .header .addBtn {
        display: block;
        width: 100%;
        margin-top: 5px;
        text-align: center;
    }

    /* .gn-menu-wrapper.gn-open-all .gn-scroller {
        width: 130%;
    } */

    .two-column blockquote {
        float: none;
        width: 100%;
    }

    .grid-main-box > .header .right-area {
        flex-wrap: wrap;
        position: inherit;
        margin-top: 1em;
    }

    .mo-detail.ui.buttons {
        margin-left: -120px;
        background: #fff;
    }

    .mo-wmax {
        width: 100% !important;
    }

    .mo-hide {
        display: none !important;
    }

    #info-item-box.ui.sticky.fixed {
        padding: 0.3em 1em;
    }
    #info-item-box .button-area {
        display: flex;
        flex-wrap: wrap;
        text-align: right;
    }
    /*    #info-item-box .button-area {margin-top: 5px;}*/
    #info-item-box .button-area a, #info-item-box .button-area button {
        flex: 1 1 auto;
    }
    #info-item-box .mo-fluid {
        flex: 1 1 100%
    }
    #info-item-box .button-area .listType + .search-box {
        flex: 1;
        flex-basis: calc(100% - 50px);
    }

    h2.page-title {
        font-size: 1.375em;
    }

    h2.page-title span {
        display: block;
        margin-left: 0 !important;
    }

    .ui.form .field-list.mo-100 > .fields > .wide.field.mo-none {
        display: none;
    }

    .ui.form .field-list.mo-100 > .fields > .wide.field {
        width: 100% !important;
    }

    .ui.form .field-list.mo-100 > .fields > .wide.field .fields {
        display: flex;
        align-items: center;
    }

    .ui.form .field-list.mo-100 > .fields > .wide.field .fields label {
        margin: 0;
    }

    .content-add-box h1 {
        font-size: 1.5em;
    }

    .content-add-box .sub {
        font-size: 1.125em;
    }

    .button-area .btn-choice .ui.button {
        min-width: auto !important;
        padding: 0.785em .5em;
    }

    .button-area .btn-choice .ui.button span i {
        margin: 0 0 5px 0 !important;
    }

    .list-select .title-header .author {
        flex-direction: column;
        align-items: flex-end;
    }

    .time-sort {
        display: none;
    }

    .lg-size {
        width: 100% !important;
    }

    .bottom-btn .btn {
        min-width: auto;
        flex: 1;
    }

    .info-type-list {
        flex-direction: column;
    }

    .info-type-list .title-cover {
        width: 100%;
    }

    .info-type-list .right-btn {
        width: 100%;
        border-top: 1px solid #d7d7d7;
    }

    .info-type-list .right-btn i {
        display: inline-block;
        margin-right: 10px;
        vertical-align: sub;
    }

    ul.thumb-extra li {
        min-width: calc(50% - 10px);
        flex: none;
    }

    .tag-box {
        flex-direction: column;
    }

    .popup-wrap .inner-box ul li img {
        width: 100%;
    }

    ol.tbl-list {
        padding-left: 0;
    }

    .grid-content-box .more-btn + span a,
    ol.tbl-list li a + span a {
        margin-left: 10px;
    }

    ul.sort-header li {
        text-align: left;
    }

    .grid-content-box .header {
        justify-content: flex-end;
    }

    .modal-type .grid-content-box .header p, .modal-type .grid-content-box .header > div {
        flex: none;
        width: 100%;
    }

    .menu-nth-wrap .more-btn,
    .menu-nth-wrap ol.tbl-list li .title,
    .menu-nth-wrap ol.tbl-list li > ol > li .title {
        width: auto;
    }

    .menu-nth-wrap span {
        margin-left: auto;
    }

    .menu-nth-wrap p {
        flex: auto;
        margin: 10px 0;
    }

    .menu-nth-wrap .controll-box {
        margin-left: auto;
    }

    .menu-nth-wrap .controll-box > * {
        margin-left: 10px;
    }

    .ui.input.eval-input-box {
        flex-wrap: wrap;
    }

    .ui.input.eval-input-box .ui.input {
        width: 100% !important;
        margin-left: 0 !important;
    }

    .file-bar {
        flex: 1;
    }

    .file-bar .ui.progress {
        width: 100%;
        margin-top: 1em !important;
    }

    ul.table-inner-week li {
        width: calc(50% - 6px);
    }

    .warning-text {
        flex-direction: column;
    }

    /*** cheathing_notify_board.jsp cheat_list 210811 ***/
    .post_list.cheat_list .post_disc {
        font-size: 14px;
    }

    /*** 달력 날짜 입력란 ***/
    .ui.form .fields > .field:first-child {
        margin-bottom: 5px !important;
        border-left: none;
        box-shadow: none;
    }

    #note-box, #alert-box {
        padding: 10px 0;
    }

    #note-box.on b, #alert-box.on b {
        display: block;
        margin: 10px 0;
    }

    .modal-page {
        font-size: 14px;
    }

}
