@charset "utf-8";
@import url('../fonts/font-awesome-4.6.2/css/font-awesome.min.css');

/* footer */
footer {
    background-color: #231F20;
    padding: 1.5em 0 2em;
    color: #ccc;
}

footer a:hover {
    color: #fff;
}

footer .inner {
    width: 100%;
    font-size: 0.933em;
}

footer .inner .left_wrap .up_box {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

/* 231206수정 */
footer .inner .left_wrap .up_box .footer_agency {
    margin-left: auto;
    display: flex;
    align-items: center;
}

footer .inner .left_wrap .up_box .footer_agency>a.privacy_btn {
    margin-right: 1em;
    color: #fff;
}

/* //231206수정 */

footer .inner .left_wrap .up_box ul.list {
    display: flex;
}

footer .inner .left_wrap .up_box ul.list li::after {
    content: '|';
    padding: 10px;
}

footer .inner .left_wrap .up_box ul.list li:last-child::after {
    content: none;
}

footer .inner .footer_logo {
    width: 143px;
    margin-right: 1em;
}

footer .inner .footer_logo img {
    width: 100%;
}

footer .inner .copy {
    color: #fff;
}


footer .inner .copy li:last-child {
    margin-bottom: 0;
}

footer .inner .copy span {
    display: inline-block;
}

footer .inner .copy span::after {
    content: '|';
    padding: 10px;
}

/* 231206추가*/
footer .inner .copy span address {
    display: inline-block;
}

/* //231206추가*/

footer .inner a.privacy_btn {
    margin-left: auto;
}


footer .footer_agency .cont_box {
    position: relative;
    width: 200px;
    z-index: 100;
}

footer .footer_agency .cont_box .tit a {
    display: block;
    padding: 11px 0 12px 23px;
    font-size: 1em;
    color: #fff;
    background: rgba(255, 255, 255, .2) url(../img/agency_more.png) 177px center no-repeat;
}

footer .footer_agency .cont_box .tit a:hover {
    color: #fff;
}


footer .footer_agency .cont_box .cont {
    display: none;
    position: absolute;
    bottom: 43px;
    left: 0;
    width: 100%;
    background-color: #fff;
    z-index: 1;
}

footer .footer_agency .cont_box .cont ul {
    border: 1px solid #743042;
}

footer .footer_agency .cont_box .cont a {
    display: block;
    padding: 7px 0 8px 20px;
    line-height: 15px;
    color: #6b6b6b;
}

footer .footer_agency .cont_box .cont a:hover,
footer .footer_agency .cont_box .cont a.on {
    color: #743042;
}

@media all and (max-width: 1024px) {
    footer .inner .footer_logo {
        margin-bottom: 1em;
    }

    footer .inner .left_wrap .up_box .footer_agency {
        margin-left: auto;
        margin-top: 1em;
    }
}

@media all and (max-width: 600px) {
    footer .inner a.privacy_btn {
        margin-left: 0;
    }
}
