
/* ql-editor start */
.ql-editor {
    min-height: 300px;
}
.ql-toolbar.ql-snow {
background: #f5f5f5;
}
.ql-editor strong {
    font-weight: 700;
}
.ql-toolbar.ql-snow .ql-formats {
    margin-right: 10px !important;
}
.note-btn {
    background-image: none !important;
    border: 1px solid #dae0e5 !important;
    white-space: nowrap;
    outline: 0;
    background-color: #fff !important;
}
