@charset "utf-8";

/* CSS Document */
textarea::placeholder,
textarea::-webkit-input-placeholder,
input::placeholder,
input::-webkit-input-placeholder {
    font-size: 1em;
    color: #9e9e9e !important;
}

.ui.message {
    font-weight: 500;
}

#key_access {
    position: fixed;
    top: 0;
    width: 100%;
    height: 57px;
    text-align: center;
    overflow: hidden;
    z-index: -1;
}

#key_access ul {
    position: relative;
}

#key_access ul li {
    position: absolute;
    width: 100%;
    height: 57px;
    left: 0;
    top: -9999px;
    background: #6d9bc0
}

#key_access ul li.select {
    top: 0;
}

#key_access a {
    display: block;
    width: 100%;
    height: 57px;
    line-height: 57px;
    color: #fff
}

#loading_page {
    position: fixed;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: #fff;
    z-index: 10000;
}

#loading_page p {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -30px 0 0 -30px;
    font-size: 48px;
    z-index: auto;
}

#wrap {
    min-height: 100vh;
    display: flex;
    display: -ms-flexbox;
    flex-direction: column;
    -ms-flex-direction: column;
}

body.stop-scrolling {
    height: 100%;
    overflow: hidden;
}
body.stop-scrolling #container::before {
    content: '';
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .3);
    z-index: 5;
}
header {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    border-bottom: 1px solid #d7d7d7;
}

header:after {
    content: '';
    display: block;
    clear: both;
}

header h1 {}
header h1 a {
    display: flex;
    align-items: center;
    margin-left: 20px;
    width: 200px;
}

/** Mainlogo pc **/
header h1 a img.main_logo {
    width: 100%;
}

header ul.location {
    float: left;
    margin-left: 40px;
}

header ul.location li {
    display: inline-block;
    padding-left: 10px;
}

header ul.location li a {
    color: #999;
}

header ul.location li a:hover {
    color: #333;
}

header ul.location li:first-child a:before {
    display: none;
}

header ul.location li a:before {
    content: "";
    display: inline-block;
    vertical-align: middle;
    width: 5px;
    height: 13px;
    margin: 0 10px 0 0;
    background: url(../img/location_arrow.png);
}

header ul.location li:last-child a {
    color: #333;
}

header ul.util {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-left: auto;
}


header ul.util > li {
    margin-right: 20px;
    color: #333;
}

header ul.util > li i:before {
    margin: 0 !important;
}

header ul.util > li.util_drop .dropdown {
    font-size: 0.86em !important;
}
header ul.util > li.util_drop .dropdown + .ui.small.button {
    font-size: .938em;
    padding: 0.7em 0.8em 0.7em;
}

.my-class {
    margin-left: 5px;
}

.my-class .ui.dropdown {
    display: flex;
    align-items: center;
    min-width: 20em;
    padding: 0 1em;
}

.my-class .ui.dropdown .menu > .item {
    display: flex;
    align-items: center;
    padding: .5em 1em !important;
}

.my-class .ui.dropdown .menu > .item label {
    margin: 0;
}

.my-class .ui.dropdown .menu > .item span {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.my-class .ui.dropdown > .text {
    display: flex;
    align-items: center;
}

.my-class .ui.dropdown > .text span {
    max-width: 170px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

header ul.util > li > div.user-img .menu {
    left: auto;
    right: 0;
    margin-top: 5px;
}

header ul.util > li > div.user-img .menu .profile {
    display: flex !important;
    align-items: center;
}

header ul.util > li > div.user-img .menu .profile ul {
    margin-left: 10px;
}

header ul.util > li > div.user-img .menu .profile ul li {
    padding: 2px 0;
    font-size: .875em;
    font-weight: 500;
}

header ul.util > li > a {
    display: inline-block;
    font-size: 1.938em;
    vertical-align: middle;
}

header ul.util > li.alrim .count {
    position: absolute;
    top: 0;
    left: 50%;
    display: inline-block;
    width: 16px;
    height: 16px;
    line-height: 16px;
    font-size: .75em;
    color: #fff;
    text-align: center;
    border-radius: 10em;
    background: #DB2828;
    transform: translateX(50%);
}

header #gnb {
    position: fixed;
    top: 0;
    left: 0;
    width: 70px;
    height: 100%;
    background: #222;
    z-index: 100;
}

header #gnb #showLeft {
    height: 100%;
    display: block;
    cursor: pointer;
}

header #gnb #showLeft span {
    display: inline-block;
    width: 22px;
    height: 16px;
    text-indent: -9999px;
    margin: 32px 0 0 24px;
    background: url(../img/gnb_ico.png) no-repeat;
}

header #gnb #showLeft.active span {
    display: inline-block;
    width: 17px;
    height: 17px;
    margin: 32px 0 0 27px;
    background: url(../img/gnb_ico.png) -27px 0 no-repeat
}

.cbp-spmenu-vertical {
    width: 280px;
    height: 100%;
    top: 0;
    overflow-y: auto;
}

.cbp-spmenu-left {
    left: -280px;
}

.cbp-spmenu-left.cbp-spmenu-open {
    left: 70px;
    display: block;
}

.cbp-spmenu-push {
    overflow-x: hidden;
    position: relative;
    left: 0;
}

.cbp-spmenu,
.cbp-spmenu-push {
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

nav {
    position: fixed;
    background: #333;
    z-index: 1;
}

nav .ui.dropdown {
    display: none !important;
    width: 200px;
    margin: 20px auto;
}

nav .ui.dropdown.black,
nav .ui.dropdown.black > .text,
nav .ui.dropdown.black > .default.text {
    color: #000;
}

nav .ui.selection.dropdown.black {
    background: #666;
    border-color: #333;
}

.ui.dropdown.black > .menu,
.ui.dropdown.black > .menu .menu {
    border-color: #333;
}

.ui.dropdown.black > .menu > .item {
    color: #666;
}

.ui.multiple.selection.black.dropdown > .label {
    border-color: #333;
}

nav ul.navList > li {
    position: relative;
    text-align: left;
    border-bottom: 1px solid #555;
}

nav ul.navList > li > a {
    display: block;
    font-size: 1.125em;
    text-indent: 20px;
    line-height: 54px;
    color: #d7d7d7;
    background: url(../img/m_gnb_down.png) calc(100% - 20px) 50% no-repeat;
    background-size: 16px;
}

nav ul.navList > li a span {
    position: inherit;
    padding: 0;
}

nav ul.navList > li:first-child {
    border-top: 1px solid #555;
}

nav ul.navList > li.on > a {
    color: #fff;
    background: url(../img/m_gnb_up.png) calc(100% - 20px) 50% no-repeat;
    background-size: 16px;
}

nav ul.navList > li.on .twoDep {
    display: block;
}

nav ul.navList > li.subNone > a {
    background: none;
}

nav ul.navList .twoDep {
    display: none;
}

nav ul.navList .twoDep ul {
    padding: 5px 0;
    background: #555;
}

nav ul.navList .twoDep ul li a {
    display: block;
    line-height: 42px;
    text-indent: 20px;
    color: #ccc;
}

nav ul.navList .twoDep ul li span:before {
    margin-right: 5px;
}

nav ul.navList .twoDep ul li a:hover {
    color: #fff;
}


#container {
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    margin-top: 60px;
    padding-left: 280px;
}
#container.fold {
    padding-left: 60px;
}

#container > .content {
    padding: 0 2em;
    transition: all 0.2s ease;
    flex-basis: auto;
}

#container > .content .ui.dimmer {
    z-index: 997;
}

#container > .content:after {
    display: block;
    clear: both;
    content: ''
}
#container > .content ul.profile li {
    font-size: 1em;
    color: #666;
    line-height: 160%;
    text-align: center;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
#container > .content ul.profile li p {
    width: 120px;
    height: 120px;
    margin: 0 auto;
    border-radius: 100%;
    overflow: hidden;
}

#container > .content ul.profile li p img {
    display: block;
    width: 100%;
}

#container > .content ul.profile li:nth-child(2) {
    margin-top: 10px;
}

#container > .content #context {
    flex: 1;
    margin-bottom: 2em;
    min-width: 0;
}

#container > .content #context .listTab {
    margin-top: 2em;
}


/* sticky 사용을 위한 position: relative; 제거 */
/* #container.prof-wrap {
    padding-bottom: 30px;
    background: #5c616f;
}

#container.prof-wrap > .content {
    background: #fff;
}

#container.prof-wrap > .content,
#container.sub-wrap > .content {
    padding-left: 2em;
}

#container.sub-wrap > .classInfo {
    padding-left: 0;
}

#container.sub-wrap .wrap_btn {
    left: 0;
    width: 100%;
}

#container.course-wrap > .content {
    padding-left: calc(2em + 280px);
}

.classInfo {
    position: relative;
    padding: 20px 0 25px 60px;
    background: url('../img/line_bg.gif') bottom repeat-x;
}

.classInfo h1 {
    display: inline-block;
    margin: 0 20px;
    font-size: 2.125em;
    font-weight: 600;
    color: #000;
    text-align: center;
    vertical-align: middle;
}

.classInfo h1 span {
    display: inline-block;
    margin: 0 20px;
    font-size: .5em;
    font-weight: normal;
} */

/*.classInfo .infoView {display: none !important;}*/
/* .classInfo .classSection {
    display: block;
    margin-left: 20px;
}

.classInfo .classSection ul {
    display: flex;
    flex-wrap: wrap;
}

.classInfo .classSection > ul > li {
    display: inline-block;
    padding: 10px 5px;
}

.classInfo .classSection > ul > li:first-child:before {
    display: none;
}

.classInfo .classSection > ul > li:before {
    content: "";
    display: inline-block;
    vertical-align: middle;
    width: 1px;
    height: 14px;
    margin: 0 10px 0 0;
    background: #d7d7d7;
}

.classInfo .info-toggle {
    position: absolute;
    left: calc(50% - 42px);
    bottom: -12px;
    width: 80px;
    height: 12px;
    border: 1px solid #d7d7d7;
    border-top: none;
    border-radius: 4px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    box-shadow: 0 1px 2px 0 rgba(34, 36, 38, .15);
    background: url(../img/line_bg.gif) repeat;
}

.classInfo .info-toggle:focus {
    outline: none;
}

.classInfo .info-toggle i {
    font-size: 2em;
    text-align: center;
    line-height: 0;
    color: #acacac;
}

.classInfo .info-toggle i:before {
    line-height: .4;
}

.classInfo .classRight {
    position: absolute;
    top: 20px;
    right: 20px;
}

.classInfo .classRight ul {
    display: inline-block;
    margin-left: .75em;
    vertical-align: middle;
}

.classInfo .classRight ul li:first-child {
    padding-bottom: 10px;
}

.classInfo.fold {
    padding: 0;
}

.classInfo.fold h1, .classInfo.fold .classSection {
    display: none;
} */

/*
.view-img {display:inline-block; margin: 0 auto; position:relative; vertical-align: middle; border-radius: 100%; overflow: hidden;}
.view-img img {position:absolute; top:-10%; left:0; width:100%;}
*/
.view-img {
    display: block;
    position: relative;
    padding-top: 100%;
    overflow: hidden;
}

.wide-img {
    display: block;
    position: relative;
    padding-top: 60%;
    overflow: hidden;
}

.post-img {
    display: block;
    position: relative;
    padding-top: 140%;
    overflow: hidden;
}

.view-img img, .wide-img img, .post-img img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.circle-img {
    display: inline-block;
    margin: 0 auto;
    position: relative;
    vertical-align: middle;
    border-radius: 100%;
    overflow: hidden;
}

.circle-img img {
    position: absolute;
    top: -10%;
    left: 0;
    width: 100%;
}

h2.page-title {
    color: #333;
}

h2.page-title span {
    margin-left: 10px;
    font-size: .75em;
    color: #999;
}

h3.page-title {
    margin-bottom: 10px;
    font-size: 1.25em;
    color: #333;
}

.test-time {
    float: right;
    vertical-align: top !important;
}

header, .mypage #container {
    margin-left: 0;
}

ul.result-table {
    overflow: hidden;
    margin-bottom: 20px;
}

ul.result-table li {
    position: relative;
    float: left;
    width: 10%;
    text-align: center;
    border: 1px solid #fff;
    box-sizing: border-box;
    background: #f1f1f1;
}

ul.result-table li a {
    display: block;
    padding: 1vh 0;
    color: #333;
}

ul.result-table li span {
    position: absolute;
    top: 5px;
    left: 5px;
    font-family: 'Century Gothic';
    font-size: 14px;
    color: rgba(255, 255, 255, .7);
}

ul.result-table li.attend {
    background: #2185D0;
}

ul.result-table li.absent {
    background: #DB2828;
}

ul.result-table li.late {
    background: #767676;
}

ul.result-table li.attend a,
ul.result-table li.absent a,
ul.result-table li.late a {
    color: #fff;
}

.header-conversion {}

.header-conversion .title {
    position: relative;
    padding-right: 40px !important;
}

.header-conversion .title .dropdown.icon {
    position: absolute;
    top: calc(50% - 6px);
    right: 10px;
    transform: rotate(180deg);
}

.header-conversion .title-header {
    display: flex;
    width: 70%;
    flex-direction: column;
    justify-content: center;
    flex: 1 1 auto;
    cursor: pointer;
}

.header-conversion .title-header section {
    font-size: 1.35em;
    font-weight: 500;
    color: #333;
}

.header-conversion .title-header section + ul.viewInfo {
    margin-top: 5px;
}

.header-conversion .title-header section span {
    display: inline-block;
    margin: 0 10px;
    font-size: .875em;
}

.header-conversion .title-header .author {
    margin: 5px 10px 0 0;
    color: #999;
}

.header-conversion .title-header .author span {
    margin-right: 10px;
}

ul.list-class li {
    padding: 10px 0;
    border-bottom: 1px solid #e9e9e9;
}

ul.list-class li .course-header {
    margin-bottom: 4px;
}

ul.list-class li .course-header .title {
    font-size: 1.125em;
    color: #333;
    vertical-align: middle;
}

ul.list-class li .course-header .title a {
    color: #333;
}

ul.list-class li .course-header .prof-name {
    padding-left: 10px;
    font-size: 1em;
    color: #7bb4d5;
    vertical-align: sub;
}

ul.list-class li .course-header .prof-name:before {
    content: "";
    display: inline-block;
    width: 1px;
    height: 12px;
    margin: 0 10px 0 0;
    background: #97a6b7;
}

ul.list-class li .author span {
    display: inline-block;
    font-size: .938em;
    margin-right: 20px;
}

ul.list-class li .author span:last-child {
    margin-right: 20px;
}

ul.num-chk li {
    display: inline-block;
}

ul.num-chk li a {
    display: inline-block;
    width: 42px;
    height: 42px;
    line-height: 42px;
    margin: .25em;
    font-size: .875em;
    text-align: center;
    color: #999;
    border-radius: 100%;
    border: 1px solid #d7d7d7;
}

ul.num-chk li.active a {
    color: #fff;
    border: 1px solid #444;
    background: #444;
}

ul.num-chk li.correct a {
    color: #4b5f7f;
    border: 1px solid #4b5f7f;
    background: #f2f4f7;
}

ul.num-chk li.correct.active a {
    color: #fff;
    border: 1px solid #fff;
    background: #4b5f7f;
}

ul.num-chk li.wrong a {
    color: #d32937;
    border: 1px solid #d32937;
    background: #ffeaec;
}

ul.num-chk li.wrong.active a {
    color: #fff;
    border: 1px solid #d32937;
    background: #d32937;
}

.article.show.prof-feedback ul {
    padding-top: 5px;
}

.movbx {
    position: relative;
    width: 100%;
    padding: 56.6% 0 6px;
}

.movbx iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.c-video-layers__container.on {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;
}

.layer-box {
    display: none;
    position: absolute !important;
    top: 50%;
    left: 50%;
    width: 320px;
    transform: translate(-50%, -50%);
    z-index: 2;
}

.ui.vertical.menu.scrollbox {
    max-height: 1200px;
}

#colorText .item {
    display: flex;
}

#colorText .item p.content {
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

#list-text div.item {
    display: flex;
    align-items: center;
    padding: 0.5em;
}

#list-text div.item p.content {
    flex: 1;
    padding-left: 0.5em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

/* Footer */
footer.main-class {
    padding: 0 0 0 280px;
}
footer .inner-wrap {
    display: flex;
    flex-wrap: wrap;
    padding: 1.33em 2em;
    transition: all 0.3s ease;
}

footer .inner-wrap .footer_logo {
    width: 200px;
}
footer .inner-wrap .footer_logo img {
    width: 100%;
}

/* Footer fr_cont */
footer .inner-wrap .fr_cont {
    flex: 1;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
}
footer .inner-wrap ul.copy, footer .inner-wrap .exc_copy {
    margin-left: 30px;
}
footer .inner-wrap ul.copy li {
    padding: 2px 0;
    font-size: .875em;
    color: #acacac;
}
footer .inner-wrap ul.copy li address {
    display: inline-block;
    font-style: normal;
}
footer .inner-wrap ul.copy li .tel_info {
    display: inline-block;
}
footer .inner-wrap ul.copy li .info_tab {
    display: inline-block;
    margin: 0 5px;
}

/* TOP SCROLL 요소 */
#toTop {
    display: none;
    position: fixed;
    right: 10px;
    bottom: 10px;
    width: 40px;
    height: 40px;
    font-size: 0;
    text-indent: -1000px;
    text-align: center;
    color: #fff;
    background: url('../img/toTop.png') center center no-repeat #777;
    opacity: 0.8;
    border-radius: 20px;
    z-index: 1001;
}

#toTop:hover, #toTop:focus, #toTop.hover {
    background-color: #CD8F47;
}

#pageSliderDescription {
    position: absolute;
    left: -999em;
    display: inline-block;
    width: 0;
    height: 0;
}

/* 검색 및 준비중인 페이지 CSS */
.page_none {
    width: 740px;
    margin: 200px auto;
    border: 5px solid #e3e4e9;
    /*background: #f3f3f3 url(../img/page_none.png) 10% 50% no-repeat;*/
    background-size: 20%;
    overflow: hidden;
}

.page_none ul {
    float: right;
    width: 500px;
    padding-bottom: 20px;
}

.page_none ul li {
    padding: 5px 8px;
    background: url(../img/icon_dot.gif) left 14px no-repeat;
}

.page_none ul li:first-child {
    padding: 20px 0;
    font-size: 24px;
    font-weight: 500;
    background: none;
}

.page_none ul li span {
    color: #ff540e;
}

.page_none a {
    display: block;
    width: 100px;
    margin: 0 auto 20px;
    padding: 5px 10px;
    font-size: 15px;
    text-align: center;
    color: #fff;
    background: #7684a0;
}

ul.flex-tab {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 2em;
}

ul.flex-tab li {
    flex: 1;
    margin: .25em;
    padding: .75em;
    border: 1px solid #d7d7d7;
    border-radius: 3px;
    background: #f9f9f9;
    text-align: center;
    overflow: hidden;
}

ul.flex-tab li.on {
    border: 1px solid #555;
    background-color: #555;
}

ul.flex-tab li.on a {
    color: #fff;
}

ul.flex-tab li a {
    display: block;
    font-size: 1.063em;
    word-break: keep-all;
    /* white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden; */
}

ul.flex-tab li.blue-bar {
    border-bottom: 3px solid #2185D0;
}

ul.flex-tab li.red-bar {
    border-bottom: 3px solid #DB2828;
}

ul.flex-tab li.grey-bar {
    border-bottom: 3px solid #767676;
}

.test-icon {
    width: 16px;
    height: 16px;
}

.h_subTitle {
    font-size: 15px;
    font-weight: normal;
    color: #adb5bd;
}
.flex-item {
    display: flex !important;
    align-items: center !important;
}
.flex-left-auto {
    margin-left: auto !important;
}

/* 비교과 과정 LNB */
aside.lnb {
    position: fixed;
    top: 60px;
    bottom: 0;
    width: 280px;
    height: auto;
    display: flex;
    flex-direction: column;
    padding-top: 1em;
    border-right: 1px solid #d7d7d7;
    box-shadow: 3px 0 4px -4px #999;
    background-color: #fff;
    z-index: 9;
}

aside.lnb .mCSB_outside + .mCSB_scrollTools {
    right: 0;
}

aside.lnb.hide {
    border-right: none;
    box-shadow: none;
}

aside.lnb .lnb-menu {
    flex: 1;
}

aside.lnb .mCSB_container.mCS_no_scrollbar_y.mCS_y_hidden {
    display: flex;
    flex-direction: column;
    height: 100%;
}

aside.lnb .lnb-menu .ui.divider {
    margin: .5em 0;
}

aside.lnb .lnb-menu a {
    display: block;
    padding: 5px 0;
}

aside.lnb .lnb-menu a:hover {
    background: #f1f1f1;
}

aside.lnb .lnb-menu a i {
    display: inline-block;
    width: 48px;
    height: auto;
    margin: 0;
    line-height: 1.4;
    font-size: 1.5em;
    text-align: center;
    vertical-align: sub;
}

aside.lnb .inner-wrap {
    padding: 1em;
    font-size: .875em;
}

aside.lnb .inner-wrap:after {
    display: block;
    clear: both;
    content: ''
}

aside.lnb .inner-wrap ul.sub-util {}

aside.lnb .inner-wrap ul.sub-util li {
    display: inline-block;
    padding: 0 5px;
}

aside.lnb .inner-wrap ul.sub-util li:first-child {
    padding-left: 0;
}

aside.lnb .inner-wrap ul.sub-util li a {}

aside.lnb .inner-wrap ul.sub-util li a:hover {
    color: #333;
}

aside.lnb .inner-wrap ul.sub-util li:first-child a:before {
    display: none;
}

aside.lnb .inner-wrap ul.sub-util li a:before {
    content: "";
    display: inline-block;
    vertical-align: middle;
    width: 1px;
    height: 11px;
    margin: 0 15px 0 0;
    background: #97a6b7;
}

aside.lnb .inner-wrap ul.copy {}

aside.lnb .inner-wrap ul.copy li {
    padding: 10px 0;
    color: #999;
}

aside.lnb .inner-wrap ul.copy li address {
    font-style: normal;
}

aside.lnb .inner-wrap ul.copy li address .customer {
    margin-top: 10px;
}

aside.lnb .inner-wrap ul.copy li address .customer span {
    display: block;
}

/* 강의실 LNB */
.class-menu-btn {
    display: none;
}

#class-lnb {
    position: fixed;
    top: 60px;
    left: 0;
    width: 60px;
    height: calc(100% - 60px);
    background-color: #19161c;
    z-index: 998;
    font-size: 16px;
}

#class-lnb .straight {
    position: relative;
    height: 100px;
    background: #471c73;
}

#class-lnb .straight .rsvt_open {
    display: block;
    position: relative;
    top: 0;
    width: 60px;
    padding-top: 57px;
    text-align: left;
}

#class-lnb .straight .rsvt_open:before {
    display: block;
    position: absolute;
    top: 20px;
    left: 20px;
    content: '\f127';
    font-family: "Ionicons";
    font-size: 2em;
    color: #fff;
}

#class-lnb .straight .rsvt_open strong {
    display: block;
    font-size: .875em;
    color: #fff;
    opacity: .4;
    text-align: center;
}

#class-lnb .straight .rsvt_open span {
    display: none;
    word-break: break-all;
}

#class-lnb ul li {
    border-bottom: 1px solid #444;
}

#class-lnb ul li a {
    display: flex;
    align-items: center;
    padding: 8px 18px 8px 18px;
    color: #fff;
}

#class-lnb ul li a i {
    font-size: 2em;
    opacity: 0.6;
}

#class-lnb ul li a i:before {
    margin: 0;
}

#class-lnb ul li a span {
    height: 0;
    margin-left: 10px;
    visibility: hidden;
    opacity: 0;
    transform: scaleX(0);
    transition: opacity 0.1s linear;
}

#class-lnb ul li a:hover,
#class-lnb ul li a:focus {
    background-color: #2f0b62;
}

#class-lnb ul li a:hover:before,
#class-lnb ul li a:focus:before {
    opacity: 1;
}

#class-lnb ul li a:hover:after,
#class-lnb ul li a:focus:after {
    /* display: block;
    position: absolute;
    top: 5px;
    left: 5px;
    width: calc(100% - 10px);
    height: calc(100% - 10px);
    border: 1px solid #442472;
    box-sizing: border-box */
}

#class-lnb ul li ul {
    border-top: 1px solid #262626;
}

#class-lnb ul li ul li {
    border-bottom: 1px solid #262626;
}

#class-lnb ul li ul li:last-child {
    border-bottom: none;
}

#class-lnb ul li ul li a {
    font-size: .875em;
    opacity: .7;
}

#class-lnb ul li ul {
    overflow: hidden;
    height: 0;
    -webkit-transition: height 0.3s;
    -moz-transition: height 0.3s;
    transition: height 0.3s;
}

#class-lnb.active {
    left: 0;
    width: 280px;
}

#class-lnb.active ul li a span {
    height: auto;
}

#class-lnb.active ul li a:before {
    left: 20px;
}

#class-lnb.active ul li a:hover:before {
    transition: opacity 10s linear;
}

#class-lnb.active ul li ul {
    height: auto;
}

#class-lnb.active ul li ul li a:hover {
    opacity: 1;
}

#class-lnb.active span {
    visibility: visible;
    opacity: 1;
    transform: scaleX(1);
}

#class-lnb.active .straight {
    padding: 0 20px;
    box-sizing: border-box;
    background-color: #661daf;
}

#class-lnb.active .straight:before {
    display: none;
}

#class-lnb.active .straight .rsvt_open {
    width: 100%;
}

#class-lnb.active .straight .rsvt_open:before {
    display: inline-block;
    position: static;
    vertical-align: middle;
}

#class-lnb.active .straight .rsvt_open {
    top: 20px;
    padding-top: 0;
}

#class-lnb.active .straight .rsvt_open strong {
    display: inline-block;
    padding-left: 10px;
    font-size: 1em;
    opacity: 1;
    vertical-align: middle;
}

#class-lnb.active .straight span {
    display: block;
    margin-top: 5px;
    color: #fff;
    font-size: .875em;
    opacity: 0.3;
}

#class-lnb ul li {
    position: relative;
}
#class-lnb ul li.open {
    /* background-color: #3c4252; */
}
#class-lnb ul li.open:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 4px;
    height: 100%;
    background: #039be5;
}
#class-lnb ul li ul li.open {
    background-color: #353a48;
}
#class-lnb ul li a {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    padding: 10px;
    color: #fff;
}
#class-lnb ul li a i {
    display: inline-block;
    flex-grow: 1;
}

#class-lnb.active ul li.sub-menu > a:after {
    content: '\f3d0';
    position: absolute;
    right: 30px;
    top: 50%;
    transform: translateY(-50%);
    font-family: "Ionicons";
    color: #fff;
}
#class-lnb.active ul li.sub-menu.open > a:after {
    content: '\f3d8';
    position: absolute;
    right: 30px;
    top: 50%;
    transform: translateY(-50%);
    font-family: "Ionicons";
    color: #fff;
}

#class-lnb ul li a i {
    display: inline-block;
    width: 15%;
    font-size: 1.4em;
    text-align: center;
}
#class-lnb ul li a i.icon {
    font-size: 1.3em;
}
#class-lnb ul li a span {
    display: none;
}
#class-lnb.active ul li a span {
    display: inline-block;
    width: 85%;
    margin-left: 12px;
    font-size: 1em;
}

#class-lnb ul li:hover,
#class-lnb ul li:focus {
    /* background-color: #353a48; */
}
#class-lnb ul li ul {
    display: none;
}
#class-lnb ul li.open ul {
    display: block;
}
#class-lnb ul li ul li a {
    padding-left: 56px;
}
#class-lnb ul li i:before {
    margin: 0;
}
#class-lnb ul li ul li a {
    color: rgba(255, 255, 255, .6);
}
#class-lnb ul li ul li a:hover {
    color: #fff;
}
#class-lnb.fold {
    width: 50px;
}
#class-lnb.fold ul li.sub-menu > a:after {
    display: none;
}
#class-lnb.fold ul li a:before {
    left: 20px;
}
#class-lnb.fold ul li a:hover:before {
    transition: opacity 10s linear;
}
#class-lnb.fold ul li a span {
    font-size: 0;
    visibility: hidden;
    opacity: 0;
    transform: scaleX(0);
    transition: opacity 0.1s linear;
}
#class-lnb.fold ul li ul {
    display: none;
    position: absolute;
    left: 50px;
    top: 0;
    width: 280px;
    overflow: inherit;
    background: #353a48;
    z-index: 9;
}
#class-lnb.fold ul li:hover ul {
    display: block;
}
#class-lnb.fold ul li ul li a {
    padding-left: 20px;
}

/************* EZ -Grader *************/
.header-title {
    position: relative;
}

.header-title .center-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-40%, -50%);
}

.header-title .center-text h2 {
    display: inline-block;
    margin-right: 10px;
    font-size: 1.5em;
    color: #fff;
}
.header-title .center-text h2 a {
    color: #fff;
}
.header-title .center-text h2 i {
    display: none;
}
.header-title .center-text .explan {
    display: inline-block;
    margin: 5px 0;
    color: rgba(255, 255, 255, .7);
}
.header-title .flex-section {
    display: flex;
    align-items: center;
}
.header-title .flex-section .group-right {
    margin-left: auto;
    min-height: 40px;
}
.header-title .flex-section .group-right .ui.button {
    width: 100px;
    color: #fff;
    background: rgba(0, 0, 0, .3);
}

.header-title .board-tab {
    position: absolute;
    bottom: 0;
}
.header-title .board-tab ul:after {
    content: "";
    display: block;
    height: 0;
    clear: both;
}
.header-title .board-tab ul li {
    float: left;
    min-width: 110px;
    font-size: 1.125em;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    background: #d7d7d7;
    z-index: 1;
}
.header-title .board-tab ul li a {
    display: block;
    min-height: 48px;
    padding: 12px;
    text-align: center;
    color: #666;
}
.header-title .board-tab ul li.select {
    position: relative;
    box-shadow: 5px 0px 5px -5px #ccc;
    background: #fff;
}
.header-title .board-tab ul li.select a {
    font-weight: 500;
    color: #000;
}
.header-title.EG-type {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 1em;
    padding: 1em 1.7em;
    background: #3f51b5;
    padding-left: 85px;
}
/* #container.push-left .header-title.EG-type {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 1em;
    padding: 1em 1.7em;
    background: #3f51b5;
} */
.header-title.EG-type h2 {
    color: #fff;
}
.header-title.EG-type h2 a {
    color: #fff;
}
.header-title.EG-type h2 i {
    display: none;
}
.header-title.EG-type .center-text .explan span {
    font-size: 1.5em;
    font-weight: 500;
}
.header-title.EG-type .flex-section {
    margin-left: auto;
}
.EG-grader {
    margin-top: 60px;
    padding: 0;
}
.modal-page.EG-grader {
    margin-top: 0;
    padding: 0;
}
.EG-grader #wrap {
    background: #fff;
}
.EG-grader .header-title.EG-type ~ #context {
    padding: 0 2em;
}
.EG-layout {
    display: flex;
    position: relative;
}
.EG-layout .stu-list-box {
    width: 280px;
    padding: 0 5px 0 1px;
    margin-right: 10px;
}
.EG-layout .stu-list-box .ui.cards {
    padding-top: 1px;
}
.EG-layout .stu-list-box .card {
    width: 100%;
    margin-bottom: 0;
    overflow: hidden;
}
.EG-layout .stu-list-box .card > .content {
    padding: .5em !important;
}
/* .EG-layout .stu-list-box .card > .content:first-child {padding: .5em 1em !important;} */
.EG-layout .stu-list-box .card i {
    font-size: 1.5em;
}
.EG-layout .stu-list-box .meta {
    color: #b7b7b7 !important;
    text-align: right;
}
.EG-layout .stu-list-box .user {
    text-align: right;
}
.EG-layout .stu-list-box .user span {
    margin-left: 10px;
}
.EG-layout .stu-list-box .content.file-box {
    display: block !important;
    font-size: .875em !important;
}
.EG-layout .stu-list-box .file-box .ui.message {
    width: 100%;
    margin: 0 0 5px 0;
    padding: 5px 10px;
    text-align: left;
}
.EG-layout .stu-list-box .file-box .ui.message:last-child {
    margin-bottom: 0;
}
.EG-layout .stu-list-box .file-box .ui.message span {
    display: block;
    word-break: break-all;
}
.EG-layout .stu-list-box .file-box .ui.message span:first-child {
    display: flex;
}
.EG-layout .stu-list-box .file-box .ui.message span button {
    text-align: left;
}
.EG-layout .stu-list-box .file-box .ui.message span button:first-child {
    flex: 1;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.EG-layout .stu-list-box .file-box .ui.message span i {
    position: relative;
    top: 0;
    font-size: 1em;
    transform: inherit;
}
.EG-layout .stu-list-box .file-box .ui.message .date {
    text-align: right;
}
.EG-layout .stu-list-box .card.select {
    background: #3f51b5;
    color: #fff;
    transition: ease all .3s;
}
.EG-layout .stu-list-box .card.select .meta {
    color: rgba(255, 255, 255, .7) !important;
}
.EG-layout .stu-list-box .card.select .user {
    color: #fff;
}
.EG-layout .stu-list-box .card.select .file-box .ui.message {
    box-shadow: none;
    background: rgba(255, 255, 255, .4);
}
.EG-layout .stu-list-box .card.submit:before {
    content: '';
    position: absolute;
    left: 0;
    width: 3px;
    height: 100%;
    background: #2185d0;
}
/*** EZ-Grader pdf-viewer ***/
.pdf-viewer {
    flex: 1;
    min-height: 820px;
    margin: 0 2em;
    border: 1px solid #d7d7d7;
    background: #f1f1f1;
    overflow: hidden;
}
.chat-viewer {
    flex: 1;
    width: 100%;
    margin: 0 2em;
    padding: 0 10px;
}
.chat-viewer .paragraph {
    display: flex;
    padding-bottom: 1em;
    overflow: hidden;
}
.chat-viewer .paragraph .initial-img {
    vertical-align: top;
}
.chat-viewer .paragraph .balloon-box {
    position: relative;
    display: inline-block;
    min-width: 180px;
    max-width: calc(100% - 42px);
    padding-right: 50px;
}
.chat-viewer .paragraph .balloon-box .path-btn {
    position: absolute;
    right: 0;
}
.chat-viewer .paragraph .balloon-box .ui.label {
    width: calc(100% - .666em);
    margin-right: 0;
    color: #222;
    line-height: 1.6em;
    background: #f1f1f1;
    cursor: pointer;
}
.chat-viewer .paragraph .balloon-box .ui.label:before {
    top: 22px;
}
.chat-viewer .paragraph .balloon-box .info {
    display: flex;
    margin: 5px 0 0 .666em;
}
.chat-viewer .paragraph .balloon-box .info .ui.header {
    margin-bottom: 0;
    color: #acacac;
}
.chat-viewer .paragraph .balloon-box .info small {
    margin-left: auto;
    padding-left: 20px;
    color: #acacac;
}
.chat-viewer .paragraph.myself .balloon-box {
    margin-left: auto;
    padding: 0 0 0 50px;
    text-align: right;
}
.chat-viewer .paragraph.myself .balloon-box .ui.label {
    width: 100%;
    background: #fbbd08;
}
.chat-viewer .paragraph.myself .balloon-box .path-btn {
    left: 0;
}
.multiple-form {
    padding: 1em 0;
    border-top: 1px solid #999;
    background: #fff;
}
.multiple-form .context-field {
    display: flex;
}
.multiple-form .context-field .inner {
    flex: 1;
}
.multiple-form .context-field .inner input[type="text"] {
    height: 100%;
}
.multiple-form .context-field .submit-btn {
    width: 80px;
    margin-left: 5px;
    color: #fff;
    text-align: center;
    background: #3c4252;
}
/*.EG-layout .pdf-viewer {flex: 1; min-height: 820px; margin: 0 2em; border: 1px solid #d7d7d7; background: #f1f1f1; overflow: hidden;}*/
.EG-layout .info-section {
    width: 23%;
    margin-left: auto;
}
.EG-layout .info-section .tbl.tablet.td-sm > tbody > tr > td {
    padding-left: 20%;
}
.EG-layout .info-section .tbl.tablet.td-md > tbody > tr > td {
    padding-left: 30%;
}
.EG-layout .info-section .tbl.tablet.td-lg > tbody > tr > td {
    padding-left: 40%;
}
.EG-layout .info-section .tbl > thead,
.EG-layout .info-section .tbl > tbody,
.EG-layout .info-section .tbl > thead > tr > th,
.EG-layout .info-section .tbl > tbody > tr > td,
.EG-layout .info-section .tbl > tbody > tr {
    display: block;
}
.EG-layout .info-section .tbl > thead > tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
}
.EG-layout .info-section .tbl > tbody > tr {
    border-bottom: 1px solid #333;
}
.EG-layout .info-section .tbl > tbody > tr:last-child {
    border-bottom: none;
}
.EG-layout .info-section .tbl > tbody > tr > th {
    display: block;
}
.EG-layout .info-section .tbl > tbody > tr > td {
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 40%;
    white-space: normal;
    text-align: left;
    min-height: auto;
}
.EG-layout .info-section .tbl > tbody > tr > td.title {
    padding-left: 0 !important;
}
.EG-layout .info-section .tbl > tbody > tr > td:last-child {
    border-bottom: none;
}
.EG-layout .info-section .tbl.test_tbl tbody tr td:last-child {
    border-bottom: 1px solid #eee;
}
.EG-layout .info-section .tbl > tbody > tr > td:before {
    position: absolute;
    top: 8px;
    left: 6px;
    padding-right: 10px;
    white-space: nowrap;
    text-align: left;
    font-weight: 500;
}
.EG-layout .info-section .tbl > tbody > tr > td:before {
    content: attr(data-title);
}
.EG-layout .info-section .tbl > tbody > tr > td.head {
    display: block;
    border-right: none;
}
.EG-layout .info-section .tbl > tbody > tr > td.lg-section,
.EG-layout .info-section .tbl > tbody > tr > td.md-section,
.EG-layout .info-section .tbl > tbody > tr > td.sm-section {
    padding-left: 0;
    background: none;
}
.EG-layout .info-section .ratings-column {
    flex-direction: column;
}
.EG-layout .info-section .ratings-column .box {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
.EG-layout .info-section .ratings-column .box:before {
    width: 100%;
    height: 1px;
    top: inherit;
    bottom: 0;
}
.EG-layout .info-section .ratings-column .box .title {
    flex: 1;
    margin-left: 10px;
}
.EG-layout .info-section .ratings-column .box .ui.buttons {
    margin-right: 0;
}
.EG-layout .info-section .ratings-column .box .description {
    flex-basis: 100%;
}
.EG-layout .info-section .ui.input.eval-input-box {
    width: 100%;
}
.EG-layout .info-section .ui.input.eval-input-box .ui.input {
    width: 60px !important;
}
.EG-layout .info-section .grid-table {
    border-right: 1px solid #cfcfcf;
}
.EG-layout .info-section .grid-table th.head,
.EG-layout .info-section .grid-table td.head {
    display: none;
}
.EG-layout .info-section .grid-table tr.mo {
    display: table-row;
}
.EG-layout .info-section .grid-table tr.mo td {
    padding: 5px;
    text-align: left;
}
.EG-layout .stu-list-box .slide-left-btn {
    position: fixed;
    top: 50%;
    left: calc(17% + 7px);
    width: 24px;
    height: 120px;
    border: 1px solid #d7d7d7;
    background: #f1f1f1;
    transform: translateY(-50%);
    z-index: 9;
}
/* #container.push-left .EG-layout .stu-list-box .slide-left-btn {
    left: calc(26% + 17px);
    width: 24px;
    height: 120px;
    border: 1px solid #d7d7d7;
    background: #f1f1f1;
    transform: translateY(-50%);
    z-index: 9;
} */
.EG-layout .stu-list-box.active .slide-left-btn {
    left: calc(3% + 4px);
}
/* #container.push-left .EG-layout .stu-list-box.active .slide-left-btn {
    left: calc(12% + 14px);
} */
.EG-layout .info-section .slide-right-btn {
    position: fixed;
    top: 50%;
    right: calc(21.3% + 42px);
    width: 24px;
    height: 120px;
    border: 1px solid #d7d7d7;
    background: #f1f1f1;
    transform: translateY(-50%);
    z-index: 9;
}
/* #container.push-left .EG-layout .info-section .slide-right-btn {
    right: calc(21.3%);
} */
.EG-layout .info-section.active .slide-right-btn {
    right: calc(1% - 15px);
}
/* #container.push-left .EG-layout .info-section.active .slide-right-btn {
    right: calc(1% - 15px);
} */
/******* .modal-page .EG-layout slide-btn *********/
.modal-page .EG-layout .stu-list-box .slide-left-btn {
    position: fixed;
    top: 50%;
    left: calc(15% - 8px);
    width: 24px;
    height: 120px;
    border: 1px solid #d7d7d7;
    background: #f1f1f1;
    transform: translateY(-50%);
    z-index: 9;
}
.modal-page .EG-layout .stu-list-box.active .slide-left-btn {
    left: 5px;
}
.modal-page .EG-layout .info-section .slide-right-btn {
    position: fixed;
    top: 50%;
    right: calc(24% - 2px);
    width: 24px;
    height: 120px;
    border: 1px solid #d7d7d7;
    background: #f1f1f1;
    transform: translateY(-50%);
    z-index: 9;
}
.modal-page .EG-layout .info-section.active .slide-right-btn {
    right: calc(1% - 17px);
}
.mo-aside-btn {
    display: none;
}

/*.wrap_btn {width: calc(100% - 60px); left: 60px;}*/
/* ul.course-tag {
    display: flex;
    flex-direction: column;
} */
ul.course-tag li {
    display: flex;
    align-items: center;
    margin: 5px 0;
}
.animation-target {
    -webkit-animation-name: hvr-wobble-vertical;
    animation-name: hvr-wobble-vertical;
    -webkit-animation-duration: 3s;
    animation-duration: 3s;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1;
}
@keyframes hvr-wobble-vertical {
    8% {
        -webkit-transform: translateY(8px);
        transform: translateY(8px);
    }

    16% {
        -webkit-transform: translateY(-6px);
        transform: translateY(-6px);
    }

    24% {
        -webkit-transform: translateY(8px);
        transform: translateY(8px);
    }

    32% {
        -webkit-transform: translateY(-6px);
        transform: translateY(-6px);
    }

    40% {
        -webkit-transform: translateY(8px);
        transform: translateY(8px);
    }

    48% {
        -webkit-transform: translateY(-6px);
        transform: translateY(-6px);
    }

    56% {
        -webkit-transform: translateY(4px);
        transform: translateY(4px);
    }

    64% {
        -webkit-transform: translateY(-2px);
        transform: translateY(-2px);
    }

    72% {
        -webkit-transform: translateY(1px);
        transform: translateY(1px);
    }

    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}


/* errorPage_ img사이즈조절 */
.under_dvelp .flex-container .cont-none img {
    display: inline-block;
    width: 100%;
    margin-bottom: 10px;
}

/* stu_eval_part 카드 보더 */
.ui.grid-list.border_yes .ui.card {
    border: 1px solid rgba(34, 36, 38, 0.1);
    box-shadow: none;
    margin: 10px auto;
    padding-bottom: 15px;

}

.ui.grid-list .ui.card .bottom-button {
    display: flex;
    justify-content: center;
}

.ui.grid-list .ui.card .bottom-button button {
    width: 90%;
}

/* rubrics_register.jsp 평가 추가  */
.element.ui.grid.haptest {
    margin: 0;
    margin-bottom: 15px;
    padding: 0;
    border-radius: 3px;
    border: 1px solid #d9d9d9;
    box-sizing: border-box;
    /* box-shadow: #f1f1f1 1px 1px 5px; */
}

.element.ui.grid.haptest .haptest_item {
    padding: 15px;
}

.element.ui.grid.haptest .haptest_item div.size_6 {

    box-shadow: none;
}

.element.ui.grid.haptest .haptest_item:first-child {
    padding-right: 0;
}

/*rubrics_register.jsp내 수정*/
#rubric section {
    display: flex;
    justify-content: space-between;
}

/* eg_view.jsp 0630 */
#rubric_card a div.content {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

#rubric_card a div.content .text_box {
    width: 80%;
}

#rubric_card a div.content .icon_box {
    width: 20%;
    display: flex;
    justify-content: space-between;
}

#rubric_card a div.content .icon_box i {
    display: inline-block;
}

/* tbl_container */
.tbl_container {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(2, 1fr);
    text-align: center;
    border-radius: 3px;
    padding: 5px;
}
.tbl_container .tbl_item .title {
    display: block;
    height: 35px;
    border-top: 1px solid #ced4da;
    border-bottom: 1px solid #ced4da;
    border-left: 1px solid #ced4da;
    background: #f1f3f5;
    align-items: center;
    line-height: 30px;
}
.tbl_container .tbl_item .text {
    height: 40px;
}

.tbl_container .tbl_item:nth-child(5n) .title,
.tbl_container .tbl_item:last-child .title {
    border-right: 1px solid #ced4da;
}
.tbl_container .tbl_item .text {
    padding: 10px 5px;
    border-left: 1px solid #ced4da;
    border-bottom: 1px solid #ced4da;
}
.tbl_container .tbl_item .text {
    padding: 3px;
    width: 100%;
    border-left: 1px solid #ced4da;
    border-bottom: 1px solid #ced4da;
}
.tbl_container .tbl_item .text .input {
    width: 100%;
}
.tbl_container .tbl_item:nth-child(5n) .text,
.tbl_container .tbl_item:last-child .text {
    border-right: 1px solid #ced4da;
}
/* //tbl_container */

.grid-main.grid_block {
    display: inline-block;
}

/* subject_plan 주별 강의 내용 수정 */
.tbl > tbody tr.view_box td {
    padding: 0.5em;
}

.tbl > tbody tr.view_box td:first-child {
    padding: 0.5em 1em;
}

.tbl > tbody tr.view_box td textarea {
    padding: 0;
    border: 0;
    resize: none;
}

/* mail_none.jsp */
.flex-container .mail_icon {
    width: 50px;
    height: 37px;

    /*background: url('../img/email_icon.png') no-repeat center;*/
    background-size: contain;
    object-fit: contain;
    margin: 10px auto;
}

/**** modal_ca_choice.jsp 요소추가  *****/
.modal-type .grid-content-box .header.tog {
    display: flex;
}

.modal-type .grid-content-box .header.tog a {
    display: inline-block;
    padding: 0 10px;
}

.tog_check label {
    display: inline-block;
    padding-right: 10%;
}

.modal-type .grid-content-box .header.tog > div {
    flex: none;
    width: 30%;
    line-height: 30px;
}

/**** leb_plan_info.jsp 요소추가  *****/
.leb_plan_box .inline.field {
    display: flex;
    align-items: center;
}

/*** index_offline hover 스타일 추가 ***/
.m-lecture .ui.card:hover {
    z-index: 5;
    /* -webkit-transform: translateY(-3px);
          transform: translateY(-3px); */
}

.m-lecture .ui.card:hover {
    border: none;
    padding: 2px;
}

.m-lecture .ui.card:before {
    content: "";
    position: absolute;
    left: 0px;
    top: -1px;
    width: 0;
    height: 0;
    border-radius: 0.4rem;
    border-top: 3px solid transparent;
    border-right: 3px solid transparent;
    z-index: 8;
}

.m-lecture .ui.card:after {
    content: "";
    visibility: visible;
    position: absolute;
    right: 0px;
    bottom: -1px;
    width: 0;
    height: 0;
    border-radius: 0.4rem;
    border-bottom: 3px solid transparent;
    border-left: 3px solid transparent;
    z-index: 8;
}

.m-lecture .ui.card:hover:before,
.m-lecture .ui.card:focus:before {
    border-color: #5f3dc4;
    width: 100%;
    height: 100%;
    transition: width .3s ease-out, height .2s .3s ease-out;
}

.m-lecture .ui.card:hover:after,
.m-lecture .ui.card:focus:after {
    border-color: #5f3dc4;
    width: 100%;
    height: 100%;
    transition: width .3s ease-out, height .2s .3s ease-out;
}

.card_group {
    position: relative;
    border: 2px solid #5f3dc4 !important;
    transition: border 0.2s linear;
    transition-delay: 0.6s;
}

.card_group:hover {
    border: none !important;
}

/**** test_grid.jsp card형 text틀어짐 수정  *****/
.fields .inline.field {
    display: flex !important;
}

.fields .inline.field label {
    margin-right: 10px;
}

.fields .inline.field i {
    display: inline-block;
    flex: 1;
    font-style: normal;
    word-break: break-all;
}

/*** prof_tutor_info 교수님/튜터 소개 ***/
.field.info_box .fields {
    display: flex;
    justify-content: center;
}
.field.info_box .name_box .resp_name {
    font-size: 2em;
    font-weight: 500;
    padding: 0.5em 0;
}
.field.info_box .name_box .resp_name > span {
    display: inline-block;
    padding: 0 0.3em;
    font-size: 0.8em;
}
.tbl.info_box2 .bookThesis_list {
    display: flex;
    flex-direction: column;
}
.tbl.info_box2 .bookThesis_list ul {
    margin-bottom: 0.5em;
}
.tbl.info_box2 .bookThesis_list ul li {
    display: flex;
}
.tbl.info_box2 .bookThesis_list ul li span.list_num {
    width: 15px;
    display: inline-block;
}

.tbl.info_box2 .bookThesis_list ul li p {
    flex: 1;
    font-style: normal;
}

/*** 바로보기 컨텐츠 field-list ***/
.field-list .inline.field .ui.input {
    flex-wrap: wrap;
}

/** 시험/퀴즈 관리 **/
.content_box {
    padding-top: 80px;
}

/*** 강의등록 ***/
.editor_noTool .note-editor .note-toolbar {
    display: none;
}

/******** stu_headBank.jsp 지식뱅크  *********/
.headBank .keyword_box {
    width: 100%;
    margin-top: 2em;
}
.headBank .keyword_box p {
    font-size: 1.1em;
    font-weight: bold;
}
.headBank .option-content {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
}
.headBank .option-content .search-box {
    margin-right: 10px;
}
.headBank .option-content .tag-box {
    flex: 1;
}
.headBank ul.flex-tab {
    margin-top: 0;
}
.headBank .card-item-center {
    padding: 0 !important;
}
.headBank .bank_tit {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0.7em 0;
}
.headBank .bank_tit b {
    flex: 1 1 auto;
    font-size: 1.1em;
    padding: 0 10px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.headBank .bank_tit i.icon {
    display: inline-block;
    cursor: pointer;
}
.headBank .bottom_content {
    margin: 2em 0 1em 0;
    width: 100%;
    display: flex;
    justify-content: center;
}
.hb_playList .bank_tit {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 5px 0;
}
.hb_playList .bank_tit b {
    width: 200px;
    flex: 1 1 auto;
    padding: 0 5px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
/******info_textbox******/
.info_textbox {
    font-size: 1.3em;
    line-height: 1.7;
}
.info_textbox > li {
    margin-bottom: 20px;
}

/******* 220225  att_info ********/
.att_info {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding-top: 20px;
}
.att_info .info_box {
    margin-top: 15px;
}
.att_info .att_title {
    font-weight: 500;
    font-size: 17px;
}
.att_info p {
    padding-left: 10px;
}
.att_info p:first-child {
    padding-left: 0;
}
.att_info p > i {
    display: inline-block;
    font-size: 16px;
    font-weight: bold;
    margin-right: 5px;
}

/* 220504추가 */
.new_user .raised.card a {
    display: flex;
    flex-direction: column;
    width: 100% !important;
}
.new_user .raised.card .header {
    display: flex !important;
}
.new_user .raised.card .header span {
    display: inline-block;
}
.new_user .raised.card .header .ui.label {
    margin-left: auto;
}

/* 220620 추가 */
.lect_list {
    display: flex;
    flex-wrap: wrap;
}
.lect_list li.list_item {
    width: calc(50% - 10px);
    display: flex !important;
    margin: 5px;
    padding: 15px;
    border: 1px solid #cdcdcd;
    border-radius: 5px;
    transition: all 0.2s ease-in-out;
}

.lect_list.full_width li.list_item {
    width: calc(100% - 20px);
}

.lect_list li.list_item:hover,
.lect_list li.list_item:focus {
    background: #e3f2fd;
    border: 1px solid #90caf9;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.lect_list li.list_item a {
    width: 100%;
}
.lect_list li a div.tit_cont {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 10px;
}
.lect_list li a div.tit_cont02 {
    flex: 1;
}
.lect_list li a div.tit_cont02 span {
    display: block;
}
.lect_list li a div.tit_cont02 span:first-child {
    margin-bottom: 0.5em;
}
.lect_list li a div.tit_cont .lect_tit,
.lect_list li a div.tit_cont02 .lect_tit {
    display: inline-block;
    font-size: 1.2em;
    font-weight: 500;
}

.lect_list li a div.tit_cont .lect_alert .ui.message {
    padding: .5em 1em !important;
}


/***** stepper ******/
ul.stepper {
    display: flex;
    position: relative;
    margin: 1em 0 2em;
}
ul.stepper:before {
    top: 25px;
    left: 50%;
    position: absolute;
    content: '';
    width: 75%;
    height: 2px;
    background-color: #ebebeb;
    transform: translateX(-50%);
}
ul.stepper.step_3:before {
    top: 25px;
    left: 50%;
    position: absolute;
    content: '';
    width: 65%;
    height: 2px;
    background-color: #ebebeb;
    transform: translateX(-50%);
}

ul.stepper li {
    flex: 1 1 auto;
    text-align: center;
}
ul.stepper li .circle {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 50px;
    line-height: 50px;
    color: #999;
    border-radius: 100%;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, .3);
    background: #fff;
    z-index: 1;
    font-size: 1.2em;
}
ul.stepper li p {
    margin-top: .4em;
    font-size: 1.25em;
    color: #999;
}
ul.stepper li.active .circle {
    color: #fff;
    background: #007bff;
}
ul.stepper li.active p {
    font-weight: bold;
    color: #333;
}

/* tbl_dd_list */
.tbl-simple .tbl_dd_list li {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}
.tbl-simple .tbl_dd_list li div {
    margin-right: 5px;
}
.tbl-simple .tbl_dd_list li div:last-child {
    margin-right: 0;
}

/* .ui.form.week_list  */
.ui.form.week_list .fields {
    margin: 0em -0.5em .33em;
}

.totalNum.small {
    font-size: 1em;
    margin-left: 20px;
}
.totalNum.small span:after {
    content: "";
    display: inline-block;
    vertical-align: middle;
    width: 1px;
    height: 14px;
    margin: 0 4px 0 10px;
    background: #d7d7d7;
}

div.ui.basic.buttons a.ui.basic.button.on_btn {
    background: #606263 !important;
    color: #fff !important;
    box-shadow: none;
}
div.ui.basic.buttons a.ui.basic.button.on_btn:hover {
    background: #039be5 !important;
    color: #fff !important;
    box-shadow: none;
}

.manage_buttons {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}
.footable-details.table .manage_buttons {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
}
.manage_buttons .manage_btn {
    display: inline-block;
    padding: 5px 15px;
    border: 1px solid #eee;
    background: #fff;
    transition: all 0.2s ease;
}
.manage_buttons .manage_btn:hover {
    background: #dee2e6;
    border: 1px solid #dee2e6;
}
.manage_buttons .manage_btn.del_btn:hover {
    background: #f03e3e;
    border: 1px solid #f03e3e;
    color: #fff;
}
.manage_buttons .manage_btn:first-child {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
}
.manage_buttons .manage_btn:last-child {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
}

.lect_card {
    position: relative;
}
.lect_card .univ_logo {
    position: absolute;
    top: 15px;
    right: 15px;
    display: inline-block;
    height: 33px;
}
.lect_card .univ_logo img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.hub_lms_btn {
    display: flex;
    justify-content: center;
    margin: 2.5em 0;
}
/* credit_ex */
ul.credit_ex li {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 10px;
}
ul.credit_ex li:last-child {
    margin-bottom: 0;
}
ul.credit_ex li div.info_in {
    margin-right: 5px;
}
ul.credit_ex li div.info_in, ul.credit_ex li div.info_text {
    margin-bottom: 2px;
}
ul.credit_ex li div.info_text {
    flex: 1;
}
ul.credit_ex button {
    margin-left: auto;
}
ul.credit_ex button i {
    font-size: 1.7em;
}
.h_label {
    display: block;
    color: #555;
}

/* 계정보기 */
/* .sum-box .acct_box .inline.fields .field {
    padding-right: 5px !important;
}
.sum-box .acct_box .inline.fields .field input {
    max-width: 200px;
}
.sum-box .acct_box .inline.fields .field:last-child {
    padding-right: 0 !important;
} */
.sum-box {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    min-height: 40px;
}
.sum-box button {
    display: inline-block;
    white-space: nowrap;
    margin-right: 0.5em !important;
}
.sum-box .acct_box {
    display: none;
}
.sum-box .acct_box.on {
    display: flex;
    align-items: center;
    margin: 3px 0;
}
.sum-box .acct_box .ui.input {
    max-width: 190px;
    max-height: 33px;
    font-size: 0.93em;
}

/* major_box */
.major_box {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 5px;
    margin-bottom: 5px;
}
/* official_letter */
.official_letter tbody tr td i[class*="arrow"] {
    display: inline-block;
    font-size: 1.2em;
    margin-right: 5px;
}


.official_letter tbody tr td .manage_buttons > a {
    min-width: 75px;
}

/* #highcharts */

/* #highcharts_wrap {
    height: 420px;
}

.highcharts-figure,
.highcharts-data-table table {
    min-width: 360px;
    max-width: 820px;
    margin: 1em auto;
}

.highcharts-data-table table {
    font-family: Verdana, sans-serif;
    border-collapse: collapse;
    border: 1px solid #ebebeb;
    margin: 10px auto;
    text-align: center;
    width: 100%;
    max-width: 500px;
}

.highcharts-data-table caption {
    padding: 1em 0;
    font-size: 1.2em;
    color: #555;
}

.highcharts-data-table th {
    font-weight: 600;
    padding: 0.5em;
}

.highcharts-data-table td,
.highcharts-data-table th,
.highcharts-data-table caption {
    padding: 0.5em;
}

.highcharts-data-table thead tr,
.highcharts-data-table tr:nth-child(even) {
    background: #f8f8f8;
}

.highcharts-data-table tr:hover {
    background: #f1f7ff;
} */


#cotect_graph {
    min-height: 300px;
}

.highcharts-figure,
.highcharts-data-table table {
    /* min-width: 320px; */
    max-width: 100%;
    margin: 1em auto;
}

.highcharts-data-table table {
    font-family: Verdana, sans-serif;
    border-collapse: collapse;
    border: 1px solid #ebebeb;
    margin: 10px auto;
    text-align: center;
    width: 100%;
    /* max-width: 500px; */
}

.highcharts-data-table caption {
    padding: 1em 0;
    font-size: 1.2em;
    color: #555;
}

.highcharts-data-table th {
    font-weight: 600;
    padding: 0.5em;
}

.highcharts-data-table td,
.highcharts-data-table th,
.highcharts-data-table caption {
    padding: 0.5em;
}

.highcharts-data-table thead tr,
.highcharts-data-table tr:nth-child(even) {
    background: #f8f8f8;
}

.highcharts-data-table tr:hover {
    background: #f1f7ff;
}

#line_chart {
    height: 500px;
}

.highcharts-figure02 {
    /* min-width: 320px; */
    width: 100%;
    margin: 1em auto;
}

/* kw_article */
.kw_article {
    margin-bottom: 1em;
}
.kw_article .kw_wrap {
    display: flex;
    align-items: center;
    margin: 0.667em 0;
}
.kw_article .kw_wrap button.kw_tit {
    display: inline-block;
    margin-right: 1em;
    width: 15%;
    min-width: 150px !important;
}
.kw_article .kw_wrap ul.kw_list {
    flex: 1;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 10px;
    overflow-x: auto;
}
.kw_article .kw_wrap ul.kw_list li a.labeled {
    width: 100%;
}
.kw_article .kw_wrap ul.kw_list li a.labeled.input .label {
    min-width: 150px;
    transition: all 0.15s ease;
}

.kw_article .kw_wrap ul.kw_list li a.labeled.input:hover div.ui.basic.label {
    background-color: #2185D0;
    color: #fff;
}
.kw_article .kw_wrap ul.kw_list li a.labeled.input:active div.ui.basic.label {
    background-color: #868e96;
    color: #fff;
}

/******************************* MEDIA QUERY *********************************/
@media all and (max-width:1440px) {
    /** 시험/퀴즈 관리 **/
    .content_box {
        padding-top: 50px;
    }
}
@media all and (min-width:1281px) {
    .EG-layout .stu-list-box.active {
        margin-left: calc(-280px - 2em);
    }
    .modal-page .EG-layout .stu-list-box.active {
        margin-left: calc(-280px - 2.5em);
    }
    .EG-layout .info-section.active {
        margin-right: calc(-23% - 2em);
    }
}

@media all and (max-width:1280px) {
    header ul.location {
        display: none;
    }

    .bar-label dd {
        font-size: 12px;
    }

    .test-paper .header-fixed .total-Item {
        width: 100%;
    }

    .classInfo {
        text-align: center;
    }

    .classInfo .classSection {
        margin: 10px;
        padding: 10px 0;
        box-shadow: 0 0 0 1px #C9BA9B inset, 0 0 0 0 transparent;
        border-radius: .28571429rem;
        background: #FFFAF3;
    }

    .classInfo .classSection > ul:after {
        display: block;
        clear: both;
        content: ''
    }

    .classInfo .classSection > ul > li {
        padding: 5px 10px;
        text-align: left;
    }

    .classInfo .classSection > ul > li:before {
        display: none;
    }

    .classInfo .classSection > ul > li {
        text-align: left;
    }

    .classInfo .classRight {
        position: relative;
        top: 0;
        right: 0;
        margin-top: 10px;
        padding-top: 10px;
        text-align: center;
        border-top: 1px solid #C9BA9B;
    }

    .classInfo .classRight ul li:first-child {
        padding-top: 0;
        text-align: left;
    }

    .classInfo .view-img {
        width: 60px;
        height: 60px;
    }

    /*    #container > .content {flex-direction: column;}*/
    .ui.vertical.menu.scrollbox {
        max-height: 260px;
    }

    .header-title {
        padding-bottom: 3.4em;
    }

    .header-title .center-text {
        position: relative;
        top: 0;
        left: 0;
        transform: none;
    }

    .header-title.EG-type {
        margin-bottom: 1em;
    }

    .header-title.EG-type h2 {
        flex-basis: 100%;
    }

    .EG-layout {
        overflow: hidden;
    }

    .EG-layout .stu-list-box {
        position: absolute !important;
        left: 0;
        width: 320px;
        padding-right: 1em;
        border-right: 1px solid #d7d7d7;
        box-shadow: 3px 0 4px -4px #ccc;
        transform: translate3d(-110%, 0, 0);
        transition: ease all .3s;
        background: #fff;
        z-index: 99;
    }

    .EG-layout .info-section {
        position: absolute;
        right: 0;
        width: 420px;
        padding-left: 1em;
        border-left: 1px solid #d7d7d7;
        padding-right: 1px;
        box-shadow: -3px 0 4px -4px #ccc;
        transform: translate3d(110%, 0, 0);
        transition: ease all .3s;
        background: #fff;
        overflow: hidden;
        overflow-y: auto;
        z-index: 99;
    }

    .EG-layout .stu-list-box.active,
    .EG-layout .info-section.active {
        transform: translate3d(0, 0, 0);
    }

    .EG-layout .stu-list-box .user-btn,
    .EG-layout .info-section button.info-btn {
        display: none;
    }

    .pdf-viewer {
        margin: 0;
    }

    .chat-viewer {
        margin: 0;
        padding: 0;
    }

    .mo-aside-btn {
        display: flex;
        align-items: center;
        margin-bottom: 1em;
    }

    .mo-aside-btn a {
        display: flex;
        justify-content: center;
        flex: 1;
        padding: .75em;
        font-size: 1.25em;
        color: #fff;
        background: #222;
    }

    .mo-aside-btn a:last-child {
        border-left: 1px solid #fff;
    }

    .slide-left-btn, .slide-right-btn {
        display: none;
    }

    ul.flex-tab li {
        flex-basis: 16.66%;
        max-width: calc(16.66% - .5em);
    }

    /* rubrics_register.jsp 요소추가  */
    .element.ui.grid.haptest .haptest_item:first-child {
        padding-right: 15px;
    }

    /** Mainlogo 1280 **/
    /* header h1 img.main_logo_pc {
        display: none;
        height: 100%;
        vertical-align: top;
    }

    header h1 img.main_logo_mobile {
        display: none;
        height: 100%;
        vertical-align: top;
    } */


    /** 시험/퀴즈 관리 message_box **/
    .content_box {
        padding-top: 15px;
        font-size: 0.9em;
    }

}

@media all and (max-width:1024px) {
    .gn-trigger ~ .overlay {
        display: none;
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background: rgba(0, 0, 0, .7);
        z-index: 1000;
    }
    .gn-trigger ~ .overlay:before {
        position: absolute;
        top: 0;
        right: 20px;
        content: '\f404';
        font-family: "Ionicons";
        font-size: 4em;
        color: #fff;
    }
    header ul.util > li {
        margin-right: 1em;
    }
    header ul.util > li.zoom-control, header ul.util > li.dark-btn, header ul.util > li.util_drop {
        display: none;
    }

    header [data-tooltip]:before,
    header [data-tooltip]:after {
        display: none;
    }
    header h1 {
        margin-left: 0;
        text-align: center;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    header h1 a {
        width: 180px;
        margin-left: 0px;
    }
    header ul.util li {
        flex: 1;
        text-align: center !important;
    }

    header.no_login ul.util li.sign_in {
        position: absolute;
        top: 0.5em;
        right: 1em;
        width: auto;
        margin-right: 1px;
    }
    header ul.util > li.alrim .count {
        top: 0;
        right: 20%;
    }

    #class-lnb {
        /* top: 0; */
        height: 100%;
        z-index: 1000;
    }



    /* #container.course-wrap > .content,
    #container.sub-wrap > .content,
    #container.prof-wrap > .content {
        padding-left: 1em;
    } */

    .wrap_btn {
        padding: 1em;
    }

    ul.flex-tab {
        margin-top: 5em;
    }

    ul.flex-tab li {
        flex-basis: 25%;
        max-width: calc(25% - .5em);
    }

    ul.num-chk li a {
        width: 32px;
        height: 32px;
        margin: .18em;
        line-height: 32px;
    }

    ul.class-lnb {
        margin-left: 0;
        /*border: none; box-shadow: none;*/
    }


    .chat-viewer {
        margin: 0;
    }

    aside.lnb.hide {
        z-index: 999;
    }

    aside.lnb {
        top: 0;
        z-index: 1001;
    }

    /*    aside.lnb .course-menu-btn {display: none;}*/
    .course-menu-btn {
        display: flex;
        align-items: center;
        position: fixed;
        left: 0;
        top: calc(50% - 45px);
        width: 24px;
        height: 90px;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        background: #19161c;
        opacity: .7;
        z-index: 9;
    }

    .course-menu-btn:before {
        content: '\f107';
        margin: 0 auto;
        font-family: "Ionicons";
        font-size: 1em;
        color: #fff;
        text-indent: 0;
    }

    .lnb-overlay {
        display: none;
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background: rgba(0, 0, 0, .7);
        z-index: 1001;
    }

    .lnb-overlay:before {
        position: absolute;
        top: 0;
        right: 20px;
        content: '\f404';
        font-family: "Ionicons";
        font-size: 4em;
        color: #fff;
    }

    .EG-grader {
        margin-top: 0;
    }

    .EG-layout .info-section .ui.input.eval-input-box .ui.input {
        margin: auto !important;
        margin-left: 5px !important;
    }

    /* subject_plan 평가방법 비중 */
    .tbl_container {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(2, 1fr);
    }

    .tbl_container .tbl_item .title {
        height: 35px;
        border-top: 1px solid #ced4da;
        border-bottom: 1px solid #ced4da;
        border-left: 1px solid #ced4da;
        align-items: center;
        line-height: 30px;
    }
    .tbl_container .tbl_item .text {
        height: 40px;
    }
    .tbl_container .tbl_item:nth-child(4n) .title,
    .tbl_container .tbl_item:last-child .title {
        border-right: 1px solid #ced4da;
    }
    .tbl_container .tbl_item:nth-child(4n) .text,
    .tbl_container .tbl_item:last-child .text {
        border-right: 1px solid #ced4da;
    }
    .tbl_container .tbl_item .text.input {
        margin: 0 !important;
    }

    /* #container.push-left {
        padding-left: 0px !important;
    } */
    #container {
        padding-left: 0;
    }
    #container.fold {
        padding-left: 0;
    }
    #container > .content {
        padding: 0 2em;
    }
    .classInfo {
        position: relative;
        padding: 170px 0 25px 0px !important;
        /*background: url('../img/line_bg.gif') bottom repeat-x;*/
    }

    #class-lnb {
        left: -170px;
        width: 170px;
    }

    #class-lnb.active {
        z-index: 1001;
    }

    #class-lnb.active .class-menu-btn {
        left: 280px;
    }

    #class-lnb .straight {
        padding: 0 20px;
        box-sizing: border-box;
        background-color: #661daf;
    }

    #class-lnb .straight:before {
        display: none;
    }

    #class-lnb .straight .rsvt_open {
        width: 130px;
    }

    #class-lnb .straight .rsvt_open:before {
        display: inline-block;
        position: static;
        vertical-align: middle;
    }

    #class-lnb .straight .rsvt_open {
        top: 75px;
        padding-top: 0;
    }

    #class-lnb .straight .rsvt_open strong {
        display: inline-block;
        padding-left: 10px;
        font-size: 1em;
        opacity: 1;
        vertical-align: middle;
    }

    #class-lnb .straight span {
        display: block !important;
        margin-top: 5px;
        color: #fff;
        font-size: .875em;
        opacity: 0.3;
    }

    #class-lnb ul li a span {
        display: block;
        visibility: inherit;
        opacity: 1;
        transform: scaleX(1);
    }

    #class-lnb ul li ul {
        height: auto !important;
    }

    /** message_box **/
    .content_box {
        font-size: 0.8em;
    }
    .info_textbox {
        font-size: 1em;
    }
    .info_textbox > li {
        margin-bottom: 15px;
    }
    /* stepper tablet */
    ul.stepper {
        display: flex;
        position: relative;
        margin: 5.5em 0 1.5em;
    }

    .grid-main-box .lect_list li.list_item {
        width: 100%;
    }
    footer, footer.fold {
        padding-left: 0;
    }
    footer .inner-wrap {
        padding: 1.33em 2em;
    }

    .lect_card .univ_logo {
        width: 120px;
    }

}

@media all and (max-width:768px) {
    #container > .content {
        padding: 0 1em;
    }
    footer .inner-wrap {
        padding: 1.33em 1em;
    }
    .under_dvelp .flex-container .cont-none .text {
        font-size: 1.5em;
        font-weight: bold;
    }
}

@media all and (max-width:767px) {
    /* .class-menu-btn {
        display: flex;
        align-items: center;
        position: fixed;
        left: 0;
        top: calc(50% - 45px);
        width: 24px;
        height: 90px;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        background: #19161c;
        opacity: .7;
    }

    .class-menu-btn:before {
        content: '\f107';
        margin: 0 auto;
        font-family: "Ionicons";
        font-size: 1em;
        color: #fff;
        text-indent: 0;
    } */

    header ul.util > li.my-class .ui.dropdown > .text span {
        max-width: 200px;
    }

    .gn-menu-main {
        width: 100%;
    }

    .wrap_btn {
        width: 100%;
        left: 0;
    }

    .header-title {
        padding-bottom: 0;
    }

    .header-title .center-text h2 {
        margin: 0;
        padding-bottom: 1em;
    }

    .header-title .center-text .explan {
        display: none;
    }

    .header-title .center-text h2 i {
        display: inline-block;
    }

    .header-title.EG-type h2 i {
        display: inline-block;
    }

    .header-title .flex-section {
        display: none;
    }

    .header-title .board-tab {
        position: relative;
    }

    .header-title .board-tab ul {
        display: flex;
    }

    .header-title .board-tab ul li {
        flex: 1 1 auto;
    }

    .header-title .board-tab ul li a {
        min-height: inherit;
    }

    /* .header-conversion .title {
        flex-direction: column;
    } */

    /* .ui.form .fields */
    .ui.form .fields {
        flex-wrap: wrap;
    }
    .ui.form .equal.fields .field {
        margin-bottom: 0.33em !important;
    }
    .ui.form .equal.fields .field:last-child {
        margin-bottom: 0em !important;
    }
    footer .inner-wrap {
        flex-direction: column;
    }
    footer .inner-wrap ul.copy {
        margin-left: 0px;
        margin-top: 20px;
    }
    footer .inner-wrap .exc_copy {
        margin-left: 0px;
    }

    .lect_card .univ_logo {
        position: absolute;
        top: 10px;
        right: 15px;
        width: 100px;
    }
    /* major_box */
    .major_box {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 5px;
    }

    /* kw_article */
    .kw_article .kw_wrap {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .kw_article .kw_wrap button.kw_tit {
        display: inline-block;
        margin-right: 0;
        width: 100%;
        min-width: 150px !important;
        margin-bottom: 0.333em;
    }
    .kw_article .kw_wrap ul.kw_list {
        flex: 1 1 auto;
        width: 100%;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
    }
}


@media all and (max-width: 600px) {
    /* kw_article */
    .kw_article .kw_wrap ul.kw_list {
        grid-template-columns: repeat(2, 1fr);
    }

    .kw_article .kw_wrap ul.kw_list li a.labeled.input .label {
        min-width: 100px;
    }

}


@media all and (max-width:480px) {

    /**** modal_ca_choice.jsp 요소추가  *****/
    .modal-type .grid-content-box .header.tog p,
    .modal-type .grid-content-box .header.tog > div {
        flex: 1;
        line-height: 30px;
    }

    .tog_check {
        margin-left: 5%;
    }

    .tog_check label {
        display: inline-block;
        padding-right: 5%;
    }

    header h1 img.main_logo_pc {
        display: none;
    }
    header h1 img.main_logo_mobile {
        display: block;
        padding: 5px 0;
    }

    .field.info_box .fields.name_box {
        display: flex;
        justify-content: center;
        text-align: center;
    }

    header ul.util > li div.todo_list {
        width: 100%;
        right: 0px;
    }

    .headBank .option-content .search-box {
        margin-right: 0px;
        margin-bottom: 0.5em;
    }
    .header-conversion .title-header {
        width: 100%;
    }
    .totalNum.small {
        margin-left: 0;
        margin-top: 5px;
    }

    /* subject_plan 평가방법 비중 */
    .tbl_container {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(2, 1fr);
    }

    .tbl_container .tbl_item .title {
        height: 35px;
        border-top: 1px solid #ced4da;
        border-bottom: 1px solid #ced4da;
        border-left: 1px solid #ced4da;
        align-items: center;
        line-height: 30px;
    }
    .tbl_container .tbl_item .text {
        height: 40px;
    }
    .tbl_container .tbl_item:nth-child(3n) .title,
    .tbl_container .tbl_item:last-child .title {
        border-right: 1px solid #ced4da;
    }
    .tbl_container .tbl_item:nth-child(3n) .text,
    .tbl_container .tbl_item:last-child .text {
        border-right: 1px solid #ced4da;
    }
    .tbl_container .tbl_item .text.input {
        margin: 0 !important;
    }

}
